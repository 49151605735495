import React, { Component } from "react";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import FlexibleCard from "../../Components/FlexibleCard";
import styles from "../More/PrivacyTermsOfUse.module.css";

export class TermsOfUsePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: {},
    };
  }

  componentDidMount() {
    this.loadPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appLanguage !== this.props.appLanguage) {
      this.loadPageData();
    }
  }

  loadPageData() {
    const { appLanguage } = this.props;

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];

    // Find the content entry for this page
    const pageEntry = entries.find(
      (obj) => obj.sys.id === "4ie0fztOTvtFcezFS1ZIwJ"
    );

    let pageContent = {};
    if (pageEntry?.fields) {
      const largeCardTitle =
        pageEntry.fields.largeCardTitle1?.[appLanguage] ||
        pageEntry.fields.largeCardTitle1?.["en"] ||
        "Sobre Vitala";

      const largeCardDesc =
        pageEntry.fields.largeCardDescription1?.[appLanguage] ||
        pageEntry.fields.largeCardDescription1?.["en"] ||
        "";

      pageContent = {
        title: largeCardTitle,
        description: largeCardDesc,
      };
    } else {
      pageContent = {
        title: "Sobre Vitala",
        description: "",
      };
    }

    this.setState({ pageContent });
  }

  render() {
    const { pageContent } = this.state;

    return (
      <div className={styles.container}>
        {/* Top Navbar with Title */}
        <TopNavBarNew pageTitle={pageContent.title} />

        <div className={styles.extraSpace}></div>

        {/* White Card */}
{/*         <div className={styles.card}>
          <p className={styles.cardText}>{pageContent.description}</p>
        </div> */}

                <FlexibleCard
                  description={pageContent.description}
                  width="351px"
                  borderRadius="10px"
                />

        <div className={styles.bottomSpace}></div>
        {/* <BottomNavBarNew appLanguage={appLanguage}/> */}
      </div>
    );
  }
}

export default TermsOfUsePage;