import React from 'react';
import styles from './RealStoriesCard.module.css';

export default function RealStoriesCard({
  title,
  description,
  image,
  buttonLink,
  isLink = true,
  icon,
  backgroundColor = '#FFFFFF',
  width = '160px',
  height = '174px',
  borderRadius = '10px',
}) {
  return (
    <div
      className={styles.realStoryCard}
      style={{
        backgroundColor: backgroundColor,
        width: width,
        height: height,
        borderRadius: borderRadius,
      }}
      onClick={isLink && buttonLink ? () => window.location.href = buttonLink : undefined}
    >
      <div className={styles.cardContent}>
        {/* Text Section */}
        <div className={styles.textContainer}>
          <h3 className={styles.cardTitle}>{title}</h3>
          <p className={styles.cardDescription}>{description}</p>
        </div>

        {/* Image Section */}
        {image && (
          <div className={styles.imageContainer}>
            <img src={image} alt="Person Illustration" className={styles.cardImage} />
          </div>
        )}

        {/* Icon Section */}
        {icon && (
          <div className={styles.iconContainer}>
            <img src={icon} alt="Arrow Icon" className={styles.cardIcon} />
          </div>
        )}
      </div>
    </div>
  );
}
