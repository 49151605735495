import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import styles from "./Onboarding2New.module.css";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import MyCalendar from "../../Components/MyCalendar";

export default function Onboarding2NewEco({ appLanguage }) {
  const [stepNumber, setStepNumber] = useState("");
  const [title, setTitle] = useState("");
  const [title2, setTitle2] = useState("");
  const [buttonText, setButtonText] = useState("Ingresar");
  const [calendarInstructions, /* setCalendarInstructions */] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();

  useEffect(() => {
    ReactGA.send("pageview", "/onboarding2-eco");
    window.scrollTo(0, 0);
    document.title = "Onboarding 2 Eco";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const ecoFlowPage = entries.find(
      (entry) => entry.sys.id === "56tTpUI0qE4zNec7UHDeJl"
    );

    if (ecoFlowPage) {
      const fields = ecoFlowPage.fields;
      setStepNumber(fields.stepNumber?.[appLanguage] || "");
      setTitle(fields.title?.[appLanguage] || "");
      setTitle2(fields.title2?.[appLanguage] || "");
      setButtonText(fields.buttonText?.[appLanguage] || "Ingresar");
    }

    const oldDateString = localStorage.getItem("ecoSelectedDate");
    if (oldDateString) {
      const oldDate = new Date(oldDateString);
      if (!isNaN(oldDate)) setSelectedDate(oldDate);
    }
  }, [appLanguage]);

  const handleDateSelected = (day) => setSelectedDate(day);

  const handleContinue = () => {
    if (!selectedDate) {
      alert("Por favor selecciona una fecha primero.");
      return;
    }

    localStorage.setItem("ecoSelectedDate", selectedDate.toISOString());
    const today = new Date();
    const dueDate = new Date(selectedDate);

    const diffInMs = dueDate - today;
    const daysUntilDueDate = Math.floor(diffInMs / 86400000);
    const gestationalAgeInDays = 280 - daysUntilDueDate; // Total pregnancy days (40 weeks = 280 days)
    const gestationalWeeks = Math.floor(gestationalAgeInDays / 7);

    localStorage.setItem("pregnancyWeeks", gestationalWeeks);

    history.push(gestationalWeeks <= 12 ? "/eligibility-under-12" : "/eligibility-over-12");
  };

  return (
    <>
    <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    
    <div className={styles.background}>
      {/* Top Section */}
      <div className={styles.topSection}>
        <div className={styles.stepNumber}>{stepNumber}</div>
        <div className={styles.title}>{title}</div>
      </div>

      {/* White Card */}
      <div className={styles.whiteCard}>
        <div className={styles.title2}>{title2}</div>
        <div className={styles.calendarInstructions}>
          {calendarInstructions}
        </div>

        {/* Calendar Component */}
        <MyCalendar onDateSelected={handleDateSelected} />
        <div className={styles.smallSpace}></div>
        {/* Custom Button */}
        <div className={styles.buttonContainer}>
          <button className={styles.customButton} onClick={handleContinue}>
            {buttonText}
          </button>
        </div>
      </div>
      <div className={styles.extraSpaceTop}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}