import React, { useEffect, useState } from "react";
import { processEntries, processAssets } from "../../utils/contentProcessor";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from "./Onboarding2NewFUMTips.module.css";

export default function Onboarding2NewFUMTips({ appLanguage, history }) {
  const [pageState, setPageState] = useState({
    title: "",
    title2: "",
    extraText: "",
    description: "",
    image1: "",
    button1: "",
    button2: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "FUM Tips";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets"));

    const entryId = "01q66gH3u82Rnojax42ALj";

    const processedEntries = processEntries(entries, appLanguage, {
      popupPages: {
        entryId,
        fieldsMapping: {
          title: "title",
          title2: "title2",
          extraText: "extraText",
          description: "description",
          button1: "button1",
          button2: "button2",
        },
      },
    });

    const processedAssets = processAssets(assets, appLanguage, {
      image1: "3KztPVcLQnzoqXk6MdvpBK",
    });

    setPageState({
      title: processedEntries?.popupPages?.title || "",
      title2: processedEntries?.popupPages?.title2 || "",
      extraText: processedEntries?.popupPages?.extraText || "",
      description: processedEntries?.popupPages?.description || "",
      button1: processedEntries?.popupPages?.button1 || "",
      button2: processedEntries?.popupPages?.button2 || "",
      image1: processedAssets?.image1 || "",
    });
  }, [appLanguage]);

  const handleButton1Click = () => {
    history.push("/eligibility-calendar-lmp");
  };

  const handleButton2Click = () => {
    history.push("/eligibility-calendar-edd");
  };

  return (
    <>
    <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    <div className={styles.pageContainer}>
      <div className={styles.topSection}>
        <h1 className={styles.title}>{pageState.title}</h1>
        <p className={styles.stepNumber}>{pageState.extraText}</p>

      </div>
      <div className={styles.whiteCard}>
      <div className={styles.bottomSpace}></div>
        <h2 className={styles.title2}>{pageState.title2}</h2>
        {pageState.image1 && (
          <img src={pageState.image1} alt="Helpful Tips" className={styles.image} />
        )}
        <p className={styles.description}>{pageState.description}</p>
        <div className={styles.buttonContainer}>
          <button className={styles.button1} onClick={handleButton1Click}>
            {pageState.button1}
          </button>
          <button className={styles.button2} onClick={handleButton2Click}>
            {pageState.button2}
          </button>
        </div>
      </div>
      <div className={styles.extraSpaceTop}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}