import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import styles from './SplashPage.module.css';


// Import assets
import animationData from '../../Images/FlowerAnimation.json';
import WhiteLogo from '../../Images/SplashLogoWhite.svg';
import BlueLogo from '../../Images/SplashLogoBlue.svg';
import BlueWoman from '../../Images/SplashWomanBlue.svg';
import WhiteWoman from '../../Images/SplashWomanWhite.svg';


export default function SplashPage({ appLanguage }) {
 const history = useHistory();


 const defaultOptions = {
   loop: false,
   autoplay: true,
   animationData: animationData,
   rendererSettings: {
     preserveAspectRatio: 'xMidYMid slice',
   },
 };


 useEffect(() => {
   window.scrollTo(0, 0);
   document.title = 'Intro';

   if (localStorage.getItem("hasSeenIntro")) {
     setTimeout(() => history.replace("/home"), 6000);
   } else {
     setTimeout(() => history.push('/onboarding-language'), 6000);
   }
 }, [history]);


 return (
   <div className={styles.splashContainer}>
     <div className={styles.splashScreen} style={{ backgroundColor: '#FFFFFF' }}>
       <img src={BlueLogo} alt="Blue Logo" className={styles.logo} />
       <div className={styles.flowerAnimation}>
         <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
       </div>
       <img src={WhiteWoman} alt="White Background Woman" className={styles.imageWhiteWoman} />
     </div>
   </div>
 );
}
