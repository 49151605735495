import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Onboarding3New.module.css"; // You’re reusing the same .module.css as 3New
import questionmarkIcon from "../../Images/questionmark.svg";
import PopUpEligibility from "../../Components/PopUpEligibility";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function Onboarding4New({ appLanguage }) {
  const [pageState, setPageState] = useState({
    stepNumber: "",
    title: "",
    title2: "",
    options: [],
    buttonText: "",
  });
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({
    icon: "",
    title: "",
    paragraph: "",
    buttonText: "OK",
  });

  const localStorageKey = "userSelectedOptions";
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Eligibility Quiz";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const abortionFlowEntry = entries?.find(
      (entry) => entry.sys.id === "6BKY7OtDpYSDH18J4L3dGE" // Your Onboarding4 entry ID
    );

    const fields = abortionFlowEntry?.fields || {};

    setPageState({
      stepNumber: fields.stepNumber?.[appLanguage] || "",
      title: fields.title?.[appLanguage] || "",
      title2: fields.title2?.[appLanguage] || "",
      options: [
        fields.optionText1?.[appLanguage] || "",
        fields.optionText2?.[appLanguage] || "",
        fields.optionText3?.[appLanguage] || "",
        fields.optionText4?.[appLanguage] || "",
        fields.optionText5?.[appLanguage] || "",
      ],
      buttonText: fields.buttonText?.[appLanguage] || "",
    });

    // Restore previously selected Onboarding4New choices if user comes back
    const savedOptions = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const currentPageSelections = savedOptions.filter(
      (choice) => choice.page === "Onboarding4New"
    );

    if (currentPageSelections.length > 0) {
      setSelectedChoices(
        currentPageSelections.map((choice) =>
          parseInt(choice.option.replace("option", "")) - 1
        )
      );
    } else {
      setSelectedChoices([]);
    }
  }, [appLanguage]);

  const handleChoiceClick = (index) => {
    const lastOptionIndex = pageState.options.length - 1;

    if (index === lastOptionIndex) {
      setSelectedChoices([index]);
    } else {
      if (selectedChoices.includes(lastOptionIndex)) {
        setSelectedChoices([index]);
      } else {
        setSelectedChoices((prev) =>
          prev.includes(index)
            ? prev.filter((choice) => choice !== index)
            : [...prev, index]
        );
      }
    }
  };

  const handleContinue = () => {
    const savedData = JSON.parse(localStorage.getItem(localStorageKey)) || [];

    const currentPageData = selectedChoices.map((choice) => ({
      page: "Onboarding4New",
      option: `option${choice + 1}`,
      optionText: pageState.options[choice],
      isLastOption: choice === pageState.options.length - 1,
    }));

    // Remove old Onboarding4New data, then add new
    const updatedData = [
      ...savedData.filter((data) => data.page !== "Onboarding4New"),
      ...currentPageData,
    ];

    localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
    history.push("/eligibility-question-three");
  };

  const handleIconClick = (entryId) => {
    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const popupEntry = entries.find((entry) => entry.sys.id === entryId);

    if (popupEntry) {
      const fields = popupEntry.fields;
      setPopupContent({
        icon: fields.icon?.[appLanguage] || "",
        title: fields.title?.[appLanguage] || "",
        paragraph: fields.paragraph?.[appLanguage] || "",
        buttonText: "OK",
      });
      setShowPopup(true);
    }
  };

  return (
    <>
        <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    <div className={styles.pageContainer}>
      <div className={styles.topSection}>
        <h1 className={styles.title}>{pageState.title}</h1>
        <p className={styles.stepNumber}>{pageState.stepNumber}</p>
      </div>
      <div className={styles.whiteCard}>
        <h2 className={styles.title2}>{pageState.title2}</h2>
        {pageState.options.map((option, index) => (
          <div key={index} className={styles.optionRow}>
            <div
              className={`${styles.choice} ${
                selectedChoices.includes(index) ? styles.choiceSelected : ""
              }`}
              onClick={() => handleChoiceClick(index)}
            >
              <span>{option}</span>
            </div>
            {(index === 1 || index === 2 || index === 3) && (
              <img
                src={questionmarkIcon}
                alt="info"
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    index === 1
                      ? "5dF4dD16x0BW6J4TF6Bvr9" // ✅ First question mark entry ID
                      : index === 2
                      ? "7hDwjZxUQh9YUd3lfQ84bJ" // ✅ Second question mark entry ID
                      : "ix7qRXxxk3O0JtjcBZvD0" // ✅ Third question mark entry ID
                  );
                }}
              />
            )}
          </div>
        ))}
        <button
          className={`${styles.continueButton} ${
            selectedChoices.length > 0 ? styles.continueButtonActive : ""
          }`}
          onClick={selectedChoices.length > 0 ? handleContinue : null}
        >
          {pageState.buttonText}
        </button>
      </div>

      {/* Popup */}
      <PopUpEligibility
        show={showPopup}
        icon={popupContent.icon}
        title={popupContent.title}
        paragraph={popupContent.paragraph}
        buttonText={popupContent.buttonText}
        onClose={() => setShowPopup(false)}
      />
                  <div className={styles.bottomSpace}></div>
                  <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}