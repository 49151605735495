import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import FeedbackCard from '../../Components/FeedbackCard';
import BannerCardWithLinkEsVE from "../../Components/BannerCardWithLinkEsVE";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from '../AbortionWPills/PillAbortion.module.css';

export default function TubalLitigation({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    pageTitle: '',
    extraText: '',
    extraText2: '',
    extraText3: '',

    // Individual Large Cards
    largeCard1: {},
    largeCard2: {},
    largeCard3: {},
    largeCard4: {},
    largeCard5: {},
    largeCard6: {},

    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',

    // Banner Card
    bannerCardDescription1: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
    image2: '',
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Tubal Litigation';
    
    //path: /contra-permanent-tuballigation

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = '7vHM9gcYTB6i6rLSJqqXgY';
    const largeCardEntryId = '3hwjU1RsNj8J54kKL836bf';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          pageTitle: 'pageTitle',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          bannerCardDescription1: 'bannerCardDescription1',
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
          largeCardDescription4: 'largeCardDescription4',
          largeCardTitle5: 'largeCardTitle5',
          largeCardDescription5: 'largeCardDescription5',
          largeCardTitle6: 'largeCardTitle6',
          largeCardDescription6: 'largeCardDescription6',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '4PRStO660MxXni9z6sY8j8',
      arrowIconWhite: '5t0SZSeDrtBlIZ3o0Apjcu',
      arrowIconDark: 'h150pgUHcW5LIJwnFodWZ',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: 'lznbQy0N84fF26XbQ1aQm',
      image2: '7bnj2eMbJsXUgrx4dQePn0',
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      extraText: processedEntries['abortoConPastillas']?.extraText || '',
      extraText2: processedEntries['abortoConPastillas']?.extraText2 || '',
      extraText3: processedEntries['abortoConPastillas']?.extraText3 || '',

      
      // Banner Card
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',


      // Small Cards Section
      smallCardTitle1: processedEntries['abortoConPastillas']?.smallCardTitle1 || '',
      smallCardDescription1: processedEntries['abortoConPastillas']?.smallCardDescription1 || '',
      smallCardTitle2: processedEntries['abortoConPastillas']?.smallCardTitle2 || '',
      smallCardDescription2: processedEntries['abortoConPastillas']?.smallCardDescription2 || '',
      smallCardTitle3: processedEntries['abortoConPastillas']?.smallCardTitle3 || '',
      smallCardDescription3: processedEntries['abortoConPastillas']?.smallCardDescription3 || '',
      smallCardTitle4: processedEntries['abortoConPastillas']?.smallCardTitle4 || '',
      smallCardDescription4: processedEntries['abortoConPastillas']?.smallCardDescription4 || '',
      smallCardTitle5: processedEntries['abortoConPastillas']?.smallCardTitle5 || '',
      smallCardDescription5: processedEntries['abortoConPastillas']?.smallCardDescription5 || '',

      // Individual Large Cards
      largeCard1: {
        ovalText: processedEntries.abortoConPastillas?.pageTitle || '',
        blueTitle: processedEntries.largeCardCollection?.largeCardTitle1 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription1 || '',
        images: [{ url: processedAssets.image1 || '' }],
      },
      largeCard2: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
      },
      largeCard3: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle3 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription3 || '',
      },
      largeCard4: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle4 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription4 || '',
      },
      largeCard5: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle5 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription5 || '',
      },
      largeCard6: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle6 || '',
        images: [
            {
              url: processedAssets['image2'] || '',
            },
          ],},

      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
    });
  }, [appLanguage]);

  return (
    
      <div className={styles.libraryAbortionMenuContainer}>
        <TopNavBarNew backgroundColor="#E3F4FF" breadcrumbColor="#333" />
        {/* Header Section */}
        <div className={styles.headerContainerContraception}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#E3F4FF"
            borderRadius="0px"
          />
        </div>
        <div className={styles.bottomSpace}></div>
        <div className={styles.homePageContainer}>
        {/* Render Large Cards */}
        <FlexibleCard
                  blueTitle={pageState.largeCard1.blueTitle}
                  blackTitle={pageState.largeCard1.blackTitle}
                  description={pageState.largeCard1.description}
                  images={pageState.largeCard1.images}
                  imagePosition="below-blue-title"
                  imageWidth="157px"
                  showOval={true}
                  ovalText={pageState.largeCard1.ovalText}
                  width="351px"
                  borderRadius="10px"
                />

        {/* Render Individual Large Cards */}
        <FlexibleCard
          blackTitle={pageState.largeCard2.blackTitle}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard3.blackTitle}
          description={pageState.largeCard3.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard4.blackTitle}
          description={pageState.largeCard4.description}
          width="351px"
          borderRadius="10px"
        />

{/* Condom Link Card */}
<BannerCardWithLinkEsVE
  linkPath="/contra-barrier-condom" // ✅ Internal link (same for both languages)
  descriptionEn="There is no protection against STIs with this method. Remember to use a condom to protect yourself."
  descriptionEs="No hay protección contra las ITS con este método. Recuerda usar un condón para protegerte."
/>

        <FlexibleCard
        blackTitle={pageState.largeCard5.blackTitle}
        description={pageState.largeCard5.description}
        width="351px"
        borderRadius="10px"
        />

        <FlexibleCard
                          blackTitle={pageState.largeCard6.blackTitle}
                          images={pageState.largeCard6.images}
                          imagePosition="below-black-title"
                          width="351px"
                          borderRadius="10px"
                        />

        {/* Extra Text Section */}
        <div className={styles.extraTitle}>{pageState.extraText}</div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText2} appLanguage={appLanguage} />

        <div className={styles.leftAlignWrapper}>

        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.extraText3}</div>

                {/* Render Small Cards Section */}
                {pageState.smallCardTitle1 && (
          <div className={styles.carouselContainer}>
            <CarouselSmallCard
                title={pageState.smallCardTitle1}
                extraText={pageState.smallCardDescription1}
                icon={pageState.arrowIconDark}
                backgroundColor="#E3F4FF"
                borderColor="#89D0FE"
                link="/contra-permanent-vasectomy"
            />
          </div>
        )}
      </div>
      </div>
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
