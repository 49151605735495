import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import BannerCard from '../../Components/BannerCard';
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import TopNavBarNew from "../../Components/TopNavBarNew";
import styles from './LibraryMenu.module.css';

export default function LibraryContraceptionMenu({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    title6: '',
    title7: '',
    extraText: '',
    extraText2: '',
    extraText3: '',
    extraText4: '',
    extraText5: '',
    extraText6: '',
    extraText7: '',
    extraText8: '',
    card1Title: '',
    card1Description: '',
    smallCard1Title: '',
    smallCard2Title: '',
    smallCard3Title: '',
    smallCard4Title: '',
    smallCard5Title: '',
    smallCard6Title: '',
    smallCard7Title: '',
    smallCard8Title: '',
    smallCard9Title: '',
    smallCard10Title: '',
    smallCard11Title: '',
    smallCard12Title: '',
    smallCard13Title: '',
    smallCard14Title: '',
    smallCard15Title: '',
    carouselOneCards: [],
    carouselTwoCards: [],
    carouselThreeCards: [],
    carouselFourCards: [],
    carouselFiveCards: [],
    carouselSixCards: [],
    headerImage: '',
    arrowIconDark: '',
    arrowIconWhite: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Contraception Menu';

    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    const assetsMapping = {
      headerImage: "4PRStO660MxXni9z6sY8j8",
      arrowIconDark: "h150pgUHcW5LIJwnFodWZ",
      arrowIconWhite: "4Lm4CzfxMtxN786cvJvoSG",
    };

    const entryId = "PHaKRMrQol6Fg4y5FfCFr";

    const processedEntries = processEntries(entries, appLanguage, {
      'introPage7': {
        entryId: entryId,
        fieldsMapping: {
          title: 'title',
          title2: 'title2',
          title3: 'title3',
          title4: 'title4',
          title5: 'title5',
          title6: 'title6',
          title7: 'title7',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          extraText4: 'extraText4',
          extraText5: 'extraText5',
          extraText6: 'extraText6',
          extraText7: 'extraText7',
          extraText8: 'extraText8',
          card1Title: 'card1Title',
          card1Description: 'card1Description',
          smallCard1Title: 'smallCard1Title',
          smallCard2Title: 'smallCard2Title',
          smallCard3Title: 'smallCard3Title',
          smallCard4Title: 'smallCard4Title',
          smallCard5Title: 'smallCard5Title',
          smallCard6Title: 'smallCard6Title',
          smallCard7Title: 'smallCard7Title',
          smallCard8Title: 'smallCard8Title',
          smallCard9Title: 'smallCard9Title',
          smallCard10Title: 'smallCard10Title',
          smallCard11Title: 'smallCard11Title',
          smallCard12Title: 'smallCard12Title',
          smallCard13Title: 'smallCard13Title',
          smallCard14Title: 'smallCard14Title',
          smallCard15Title: 'smallCard15Title',
        }
      },
    });

    const processedAssets = processAssets(assets, appLanguage, assetsMapping);

    setPageState({
      title: processedEntries['introPage7']?.title || '',
      title2: processedEntries['introPage7']?.title2 || '',
      title3: processedEntries['introPage7']?.title3 || '',
      title4: processedEntries['introPage7']?.title4 || '',
      title5: processedEntries['introPage7']?.title5 || '',
      title6: processedEntries['introPage7']?.title6 || '',
      title7: processedEntries['introPage7']?.title7 || '',
      extraText: processedEntries['introPage7']?.extraText || '',
      extraText2: processedEntries['introPage7']?.extraText2 || '',
      extraText3: processedEntries['introPage7']?.extraText3 || '',
      extraText4: processedEntries['introPage7']?.extraText4 || '',
      extraText5: processedEntries['introPage7']?.extraText5 || '',
      extraText6: processedEntries['introPage7']?.extraText6 || '',
      extraText7: processedEntries['introPage7']?.extraText7 || '',
      extraText8: processedEntries['introPage7']?.extraText8 || '',
      card1Title: processedEntries['introPage7']?.card1Title || '',
      card1Description: processedEntries['introPage7']?.card1Description || '',
      smallCard1Title: processedEntries['introPage7']?.smallCard1Title || '',
      smallCard2Title: processedEntries['introPage7']?.smallCard2Title || '',
      smallCard3Title: processedEntries['introPage7']?.smallCard3Title || '',
      smallCard4Title: processedEntries['introPage7']?.smallCard4Title || '',
      smallCard5Title: processedEntries['introPage7']?.smallCard5Title || '',
      smallCard6Title: processedEntries['introPage7']?.smallCard6Title || '',
      smallCard7Title: processedEntries['introPage7']?.smallCard7Title || '',
      smallCard8Title: processedEntries['introPage7']?.smallCard8Title || '',
      smallCard9Title: processedEntries['introPage7']?.smallCard9Title || '',
      smallCard10Title: processedEntries['introPage7']?.smallCard10Title || '',
      smallCard11Title: processedEntries['introPage7']?.smallCard11Title || '',
      smallCard12Title: processedEntries['introPage7']?.smallCard12Title || '',
      smallCard13Title: processedEntries['introPage7']?.smallCard13Title || '',
      smallCard14Title: processedEntries['introPage7']?.smallCard14Title || '',
      smallCard15Title: processedEntries['introPage7']?.smallCard15Title || '',
/*       carouselOneCards: [], */
/*       carouselTwoCards: [], */
      headerImage: processedAssets?.headerImage || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
    
 // Prepare carousel data arrays

 // Pastilla anticonceptiva de emergencia
 carouselOneCards: [
  {
    title: processedEntries['introPage7']?.smallCard1Title || '',
    extraText: processedEntries['introPage7']?.extraText3 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-emergency-emergencypill",
  },
  // Dispositivo intrauterino (DIU) no hormonal-cobre
  {
    title: processedEntries['introPage7']?.smallCard2Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-emergency-long-copperiud",
  },
],
carouselTwoCards: [
  // Dispositivo intrauterino hormonal (DIU)
  {
    title: processedEntries['introPage7']?.smallCard3Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-long-iud",
  },
  // Dispositivo intrauterino (DIU) no hormonal-cobre
  {
    title: processedEntries['introPage7']?.smallCard2Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-emergency-long-copperiud",
  },

  // Implante subdérmico
  {
    title: processedEntries['introPage7']?.smallCard4Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-long-implant",
  },
],
carouselThreeCards: [
  // Pastilla anticonceptiva
  {
    title: processedEntries['introPage7']?.smallCard5Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-short-birthcontrolpills",
  },
  // Inyección anticonceptiva
  {
    title: processedEntries['introPage7']?.smallCard6Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-short-injection",
  },
  // Anillo vaginal
  {
    title: processedEntries['introPage7']?.smallCard7Title || '',
    extraText: processedEntries['introPage7']?.extraText3 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-short-ring",
  },
  // Parche anticonceptivo
  {
    title: processedEntries['introPage7']?.smallCard8Title || '',
    extraText: processedEntries['introPage7']?.extraText3 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-short-patch",
  },
],
carouselFourCards: [
  // Condones o preservativos
  {
    title: processedEntries['introPage7']?.smallCard9Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-barrier-condom",
  },
  // Diafragma
  {
    title: processedEntries['introPage7']?.smallCard10Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-barrier-diaphragm",
  },
  // Capuchón cervical
  {
    title: processedEntries['introPage7']?.smallCard11Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-barrier-cervicalcap",
  },
],
carouselFiveCards: [  
  // Métodos basados en el conocimiento de fertilidad
  {
    title: processedEntries['introPage7']?.smallCard12Title || '',
    extraText: processedEntries['introPage7']?.extraText3 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-natural-fertility",
  },
  // Coito interrumpido
  {
    title: processedEntries['introPage7']?.smallCard13Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-natural-coitus",
  },
],
carouselSixCards: [
  // Ligadura de trompas
  {
    title: processedEntries['introPage7']?.smallCard14Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-permanent-tuballigation",
  },
  // Vasectomía
  {
    title: processedEntries['introPage7']?.smallCard15Title || '',
    extraText: processedEntries['introPage7']?.extraText2 || '',
    icon: processedAssets?.arrowIconDark,
    link: "/contra-permanent-vasectomy",
  },
],

});
  }, [appLanguage]);

  return (
    <div className={styles.homePageContainer}>
      
      <div className={styles.libraryAbortionMenuContainer}>
      
        {/* Header Section */}
        <div className={styles.headerContainer}>
        <TopNavBarNew backgroundColor="#E3F4FF" breadcrumbColor="#333" />
        <HeaderCardNew
            title={pageState.title}
            image={pageState.headerImage}
            backgroundColor="#E3F4FF" 
            borderRadius="0px" 
          />
        </div>

        <div className={styles.contentWrapper}>
        <div className={styles.bannerCardWrapper}>
        <BannerCard 
          title={pageState.card1Title} 
          description={pageState.card1Description}
          image={pageState.arrowIconWhite}
          gradient="linear-gradient(99deg, #4645ED 19.43%, #2A31D3 100.23%)"
          link="/contra-test-questionone"
          imageWidth="26px"
          imageHeight="26px"
          imagePosition="right"
          imageOffsetY="10px"
          imageOffsetX="10px"
          titleMaxWidth="256px"
          descriptionMaxWidth="286px"
          cardWidth="351px"
          cardHeight="95px" // Space between title and description
          titleFontSize="14px"
          titleFontWeight="700"
          titleColor="#FFFFFF"
          descriptionColor="#FFFFFF"
        />
        </div>

      {/* First Carousel Section */}

        <div className={styles.mainSubtitle}>{pageState.title2}</div>
        <div className={styles.lowerSubtitle}>{pageState.extraText}</div>
  
      <div className={styles.carouselContainer}>
        {pageState.carouselOneCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.extraText}
            icon={card.icon}
            link={card.link}
            spacingBetweenTitleAndExtraText="10px"
            backgroundColor='#E3F4FF'
            borderColor='#CAE9FF'
          />
        ))}
      </div>

      {/* Second Carousel Section */}

        <div className={styles.mainSubtitle}>{pageState.title3}</div>
        <div className={styles.lowerSubtitle}>{pageState.extraText4}</div>

      <div className={styles.carouselContainer}>
        {pageState.carouselTwoCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.extraText}
            icon={card.icon}
            link={card.link}
            spacingBetweenTitleAndExtraText="10px"
            backgroundColor='#E3F4FF'
            borderColor='#CAE9FF'
          />
        ))}
      </div>

        {/* Third Carousel Section */}
      <div className={styles.mainSubtitle}>{pageState.title4}</div>
      <div className={styles.lowerSubtitle}>{pageState.extraText5}</div>

      <div className={styles.carouselContainer}>
        {pageState.carouselThreeCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.extraText}
            icon={card.icon}
            link={card.link}
            spacingBetweenTitleAndExtraText="10px"
            backgroundColor='#E3F4FF'
            borderColor='#CAE9FF'
          />
        ))}
      </div>


        {/* Fourth Carousel Section */}
        <div className={styles.mainSubtitle}>{pageState.title5}</div>
      <div className={styles.lowerSubtitle}>{pageState.extraText6}</div>

      <div className={styles.carouselContainer}>
        {pageState.carouselFourCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.extraText}
            icon={card.icon}
            link={card.link}
            spacingBetweenTitleAndExtraText="10px"
            backgroundColor='#E3F4FF'
            borderColor='#CAE9FF'
          />
        ))}
      </div>

        {/* Fifth Carousel Section */}
        <div className={styles.mainSubtitle}>{pageState.title6}</div>
      <div className={styles.lowerSubtitle}>{pageState.extraText7}</div>

      <div className={styles.carouselContainer}>
        {pageState.carouselFiveCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.extraText}
            icon={card.icon}
            link={card.link}
            spacingBetweenTitleAndExtraText="10px"
            backgroundColor='#E3F4FF'
            borderColor='#CAE9FF'
          />
        ))}
      </div>

              {/* Sixth Carousel Section */}
              <div className={styles.mainSubtitle}>{pageState.title7}</div>
            <div className={styles.lowerSubtitle}>{pageState.extraText8}</div>


      <div className={styles.carouselContainer}>
        {pageState.carouselSixCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.extraText}
            icon={card.icon}
            link={card.link}
            spacingBetweenTitleAndExtraText="10px"
            backgroundColor='#E3F4FF'
            borderColor='#CAE9FF'
          />
        ))}
      </div>

    </div>
  </div>

  <div className={styles.bottomSpace}></div>
  <BottomNavBarNew appLanguage={appLanguage}/>
  </div>
);
}
