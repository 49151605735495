import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import BasicAccordionRounded from '../../Components/BasicAccordionRounded';
import BannerCard from '../../Components/BannerCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './FAQs.module.css';

export default function FAQs({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    subTitle1: '',
    subTitle2: '',
    subTitle3: '',
    subTitle4: '',
    narrowCardTitle1: '',
    narrowCardDescription1: '',
    narrowCardTitle2: '',
    narrowCardDescription2: '',
    externalBannerLink: '',

    // Assets
    image1: '',
    image2: '',
    image3: '',
    icon: '',

    // Accordion Data
    accordions: [],
    accordions2: [],
  });

  const [expanded, setExpanded] = useState(false); // State to manage which accordion is expanded


  // TO DO: check if this function is needed
/*   const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }; */

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'FAQs Abortion and Contraception';

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const largeCardEntryId = '6YzghVskgpzfp6OLbOaZ6W';
    const largeCardEntryId2 = '6noivmGgl8UAKNnfu7C4Up';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          subTitle1: 'subTitle1',
          subTitle2: 'subTitle2',
          subTitle3: 'subTitle3',
          subTitle4: 'subTitle4',
          narrowCardTitle1: 'narrowCardTitle1',
          narrowCardDescription1: 'narrowCardDescription1',
          narrowCardTitle2: 'narrowCardTitle2',
          narrowCardDescription2: 'narrowCardDescription2',
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
          largeCardDescription4: 'largeCardDescription4',
          largeCardTitle5: 'largeCardTitle5',
          largeCardDescription5: 'largeCardDescription5',
          largeCardTitle6: 'largeCardTitle6',
          largeCardDescription6: 'largeCardDescription6',
          largeCardTitle7: 'largeCardTitle7',
          largeCardDescription7: 'largeCardDescription7',
          largeCardTitle8: 'largeCardTitle8',
          largeCardDescription8: 'largeCardDescription8',
          largeCardTitle9: 'largeCardTitle9',
          largeCardDescription9: 'largeCardDescription9',
          externalBannerLink: 'externalBannerLink',
        },
      },
    });

    // Process the entries from Contentful for all content types
    const processedEntries2 = processEntries(entries, appLanguage, {
      'largeCardCollection': {
        entryId: largeCardEntryId2,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      image1: '7vz8kDXxEOgXXTniAeZKoN',
      image2: 'y3Rd7KceR170l23wC9ZX9',
      image3: 'OhBvnRbWdrnPMdn7y11FU',
      icon: '39RtDZzFYwLgFYIZzzSN1k',
    });

    // Create an array of accordion data
    const accordions = Array.from({ length: 9 }, (_, index) => ({
      title: processedEntries['largeCardCollection']?.[`largeCardTitle${index + 1}`] || '',
      description: processedEntries['largeCardCollection']?.[`largeCardDescription${index + 1}`] || '',
    }));

        // Create an array of accordion data
        const accordions2 = Array.from({ length: 2 }, (_, index) => ({
          title: processedEntries2['largeCardCollection']?.[`largeCardTitle${index + 1}`] || '',
          description: processedEntries2['largeCardCollection']?.[`largeCardDescription${index + 1}`] || '',
        }));

    // Set state for the various sections of the page
    setPageState({
      // General Content
      headerTitle: processedEntries['largeCardCollection']?.headerTitle || '',
      subTitle1: processedEntries['largeCardCollection']?.subTitle1 || '',
      subTitle2: processedEntries['largeCardCollection']?.subTitle2 || '',
      subTitle3: processedEntries['largeCardCollection']?.subTitle3 || '',
      subTitle4: processedEntries['largeCardCollection']?.subTitle4 || '',
      narrowCardTitle1: processedEntries['largeCardCollection']?.narrowCardTitle1 || '',
      narrowCardDescription1: processedEntries['largeCardCollection']?.narrowCardDescription1 || '',
      narrowCardTitle2: processedEntries['largeCardCollection']?.narrowCardTitle2 || '',
      narrowCardDescription2: processedEntries['largeCardCollection']?.narrowCardDescription2 || '',

      externalBannerLink: processedEntries['largeMenu']?.externalBannerLink || 'https://hola.ayacontigo.org/en/foro',

      // Accordions
      accordions,
      accordions2,

      // Assets
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
      image3: processedAssets?.image3 || '',
      icon: processedAssets?.icon || '',
    });
  }, [appLanguage]);

  return (
    <div className={styles.homePageContainerFAQ}>

      <div className={styles.libraryAbortionMenuContainer}>
      <TopNavBarNew backgroundColor="#4350E6" breadcrumbColor="#fff" />
        {/* Header Section */}
        <HeaderCardNew
          title={pageState.headerTitle}
          image={pageState.image1}
          backgroundColor="#4350E6"
          titleColor="#fff"
          borderRadius="0px"
        />

        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.subTitle1}</div>
        </div>

        {/* Accordions */}
        <div className={styles.accordionWrapper}>
          {pageState.accordions.map((accordion, index) => (
            <BasicAccordionRounded
              key={index}
              heading={accordion.title}
              content={accordion.description}
              expanded={expanded === index} // Manage expanded state for each accordion
              onChange={(event, isExpanded) => setExpanded(isExpanded ? index : false)}
            />
          ))}
        </div>


        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.subTitle2}</div>
        </div>


        {/* Accordions */}
        <div className={styles.accordionWrapper}>
          {pageState.accordions2.map((accordion, index) => (
            <BasicAccordionRounded
              key={index}
              heading=
         
              {accordion.title}
              content=
              {accordion.description}
              
              expanded={expanded === index} // Manage expanded state for each accordion
              onChange={(event, isExpanded) => setExpanded(isExpanded ? index : false)}
            />
          ))}
        </div>

        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.subTitle3}</div>
        </div>

        <BannerCard 
                title={pageState.narrowCardTitle1} 
                description={pageState.narrowCardDescription1}
                image={pageState.image2}
                gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
                link="/chat-main"
                imageWidth="81px"
                imageHeight="80px"
                imagePosition="bottom-left"
                imageOffsetY=""
                imageOffsetX=""
                titleMaxWidth="254px"
                descriptionMaxWidth="251px"
                cardWidth="349px"
                cardHeight="88px"
                titleFontSize="14px"
                titleFontWeight="700"
                titleColor="#FFFFFF"
                titleLineHeight="15px"
                titleWidth="249px"
                

                descriptionFontSize="13px"
                descriptionFontWeight="400"
                descriptionColor="#FFFFFF"
                descriptionLineHeight="13px"
                descriptionWidth="246px"
                
        />

        <div className={styles.moreMargin}></div>


        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.subTitle4}</div>
        </div>

            <BannerCard 
            title={pageState.narrowCardTitle2} 
            description={pageState.narrowCardDescription2}
            image={pageState.image3}
            gradient="linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%)"
            link={pageState.externalBannerLink} 
            imagePosition='right'
            imageWidth="165px"
            imageHeight="125px"
            imageOffsetY="5px"
            imageOffsetX=""
            titleMaxWidth="256px"
            descriptionMaxWidth="300px"
            cardWidth="349px"
            cardHeight="125px"
            />
      </div>

      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
