import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import BannerCard from '../../Components/BannerCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from '../AbortionWPills/PillAbortion.module.css';

export default function SelfcareAttention({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
  
    // Individual Large Cards
    largeCard1: {},

    // Banner Card
    bannerCardDescription1: '',
    bannerCardTitle1: '',

    // Assets
    headerImage: '',
    heartIcon: '',
    image1: '',
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Selfcare Attention';

    // path: /selfcare-attention

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = 'ZfhzpoPll6t4vC7XvWyPL';
    const largeCardEntryId = '3djKNXzSSa0lCbZVAmoYup';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1', 
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '2QGLgde5r3vqnFKv9cINQM',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: 'y3Rd7KceR170l23wC9ZX9',
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',

      // Banner Card
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
      bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',

      // Individual Large Cards
      largeCard1: {
        blueTitle: processedEntries['largeCardCollection']?.largeCardTitle1 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription1 || '',
      },

      // Assets
      headerImage: processedAssets?.headerImage || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
    });
  }, [appLanguage]);

  return (
<div className={styles.libraryAbortionMenuContainer}>
        <TopNavBarNew backgroundColor="#FFDFE7" />
        {/* Header Section */}
        <div className={styles.headerContainerContraception}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#FFDFE7"
            borderRadius="0px"
          />
        </div>
        <div className={styles.bottomSpace}></div>
        <div className={styles.homePageContainer}>
        {/* Render Individual Large Cards */}
        <FlexibleCard
          blueTitle={pageState.largeCard1.blueTitle}
          titleColor="#4350E6"
          description={pageState.largeCard1.description}
          width="351px"
          borderRadius="10px"
        />

        <BannerCard 
        title={pageState.bannerCardTitle1} 
        description={pageState.bannerCardDescription1}
        image={pageState.image1}
        gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
        link="/chat-main"
        imageWidth="81px"
        imageHeight="80px"
        imagePosition="top-right"
        imageOffsetY=""
        imageOffsetX=""
        titleMaxWidth="256px"
        descriptionMaxWidth="286px"
        cardWidth="349px"
        cardHeight="88px"
        titleFont="Inter"
          titleFontSize="14px"
          titleFontWeight="700"
          titlePaddingTop="22px"
          descriptionFontSize="13px"
          descriptionFontWeight="400"
          titleFontFamily="GT Walsheim Pro"
          descriptionFontFamily="Inter"
        />

      </div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
