import React from 'react';
import ReactGA from 'react-ga4'
import  TopNavBarNew  from '../../Components/TopNavBarNew'
import ContraTestResultCard from './ContraTestResultCard'
import * as ContentfulMarkdown from 'react-markdown'
import  BottomNavBarNew  from '../../Components/BottomNavBarNew'
import BannerCard from '../../Components/BannerCard';
/* import { CardDescription } from 'semantic-ui-react'; */
import styles from './ContraTestResult.module.css';



export class ContraTestResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: {},
      appLanguage: props.appLanguage || localStorage.getItem("language") || "en", // ✅ Fixes appLanguage initialization
      headerImg: "",
      headerImgAlt: "",
      pageTitle: "",
      subtitle: "",
      cardTitle: "",
      cardDescription: "",
      ctaCopy: "",
      menuCardContent: [],
    };
  }

  componentDidMount() {
    ReactGA.send('pageview', '/contra-test-result');
    window.scrollTo(0, 0)

    document.title = "Contraception Test Result"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const checkPageAccess = localStorage.getItem(
      "contraceptionTestComplete"
    );
    if (checkPageAccess === null) {
      this.props.history.push("/contra-test-result");
    }

    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);

    const getMenuContentEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "contraceptionMenu"
    );

    const getMenuEntries = getMenuContentEntries.filter(
      (obj) => obj.sys.id === "2fobWRDn1CzaxDy6p5HNYj"
    );

    var menuEntries = [];
    var menuCardContentArray = [];
    var menuPageTitle = "";
    var subtitle = "";
    var cardTitle = "";
    var cardDescription = "";
    var ctaCopy = "";
    var cardObj = {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
      11: {},
      12: {},
      13: {},
    };

    if (getMenuEntries && getMenuEntries.length > 0) {
      menuEntries.push(getMenuEntries[0].fields);

      if (menuEntries.length > 0) {
        if (menuEntries[0].title) {
          menuPageTitle = menuEntries[0].title[this.props.appLanguage];
        }

        if (menuEntries[0].subtitle) {
            subtitle = 
            menuEntries[0].subtitle[this.props.appLanguage];
        }

        if (menuEntries[0].cardTitle) {
          cardTitle =
            menuEntries[0].cardTitle[this.props.appLanguage];
        }

        if (menuEntries[0].cardDescription) {
          cardDescription =
            menuEntries[0].cardDescription[this.props.appLanguage];
        }

        if (menuEntries[0].ctaCopy) {
          ctaCopy =
            menuEntries[0].ctaCopy[this.props.appLanguage];
        }

        if (menuEntries[0].option1) {
          cardObj["1"]["title"] =
            menuEntries[0].option1[this.props.appLanguage];
          cardObj["1"]["goToPageLink"] = "/contra-short-birthcontrolpills";
        }

        if (menuEntries[0].option2) {
          cardObj["2"]["title"] =
            menuEntries[0].option2[this.props.appLanguage];
          cardObj["2"]["goToPageLink"] = "/contra-emergency-long-copperiud";
        }

        if (menuEntries[0].option3) {
          cardObj["3"]["title"] =
            menuEntries[0].option3[this.props.appLanguage];
          cardObj["3"]["goToPageLink"] = "/contra-long-iud";
        }

        if (menuEntries[0].option4) {
          cardObj["4"]["title"] =
            menuEntries[0].option4[this.props.appLanguage];
          cardObj["4"]["goToPageLink"] = "/contra-long-implant";
        }

        if (menuEntries[0].option5) {
          cardObj["5"]["title"] =
            menuEntries[0].option5[this.props.appLanguage];
          cardObj["5"]["goToPageLink"] = "/contra-short-injection";
        }

        if (menuEntries[0].option6) {
          cardObj["6"]["title"] =
            menuEntries[0].option6[this.props.appLanguage];
          cardObj["6"]["goToPageLink"] = "/contra-barrier-condom";
        }

        if (menuEntries[0].option7) {
          cardObj["7"]["title"] =
            menuEntries[0].option7[this.props.appLanguage];
          cardObj["7"]["goToPageLink"] = "/contra-emergency-emergencypill";
        }

        if (menuEntries[0].option8) {
          cardObj["8"]["title"] =
            menuEntries[0].option8[this.props.appLanguage];
          cardObj["8"]["goToPageLink"] = "/contra-permanent-tuballigation";
        }

        if (menuEntries[0].option9) {
          cardObj["9"]["title"] =
            menuEntries[0].option9[this.props.appLanguage];
          cardObj["9"]["goToPageLink"] = "/contra-permanent-vasectomy";
        }

        if (menuEntries[0].option10) {
          cardObj["10"]["title"] =
            menuEntries[0].option10[this.props.appLanguage];
          cardObj["10"]["goToPageLink"] = "/contra-natural-fertility";
        }

        if (menuEntries[0].option11) {
          cardObj["11"]["title"] =
            menuEntries[0].option11[this.props.appLanguage];
          cardObj["11"]["goToPageLink"] = "/contra-natural-coitus";
        }
        if (menuEntries[0].option12) {
          cardObj['12']['title'] = menuEntries[0].option12[this.props.appLanguage]
          cardObj['12']['goToPageLink'] = '/contra-short-ring'
        }

        if (menuEntries[0].option13) {
          cardObj['13']['title'] = menuEntries[0].option13[this.props.appLanguage]
          cardObj['13']['goToPageLink'] = '/contra-short-patch'
        }
      } 
    } 

    const q1Answer = localStorage.getItem("contraTestQ1Answer");
    const q2Answer = localStorage.getItem("contraTestQ2Answer");
    const q3Answer = localStorage.getItem("contraTestQ3Answer");
    const q4Answer = localStorage.getItem("contraTestQ4Answer");
    const q5Answer = localStorage.getItem("contraTestQ5Answer");
    const q6Answer = localStorage.getItem("contraTestQ6Answer");
    const q7Answer = localStorage.getItem("contraTestQ7Answer");
    const q8Answer = localStorage.getItem("contraTestQ8Answer");
    const q9Answer = localStorage.getItem("contraTestQ9Answer");

    var optionArray = [1, 2, 4, 5, 3, 6, 7, 8, 9, 10, 11, 12, 13];

    if (
      q1Answer &&
      q2Answer === "yes" &&
      q4Answer === "yes" &&
      q5Answer &&
      q6Answer === "yes" &&
      q7Answer === "no" &&
      q8Answer === "no" &&
      q9Answer
    ) {
      optionArray = [2, 4, 5, 1, 6, 7, 8, 3, 9, 10, 11, 12, 13];
      menuCardContentArray.push(cardObj["2"], cardObj["4"], cardObj["5"], cardObj["1"], cardObj["6"]); // cardObj['1'], cardObj['6'], cardObj['3'], cardObj['7'], cardObj['8'], cardObj['9'], cardObj['10'], cardObj['11'])
    } else if (
      q1Answer === "no" &&
      q2Answer === "yes" &&
      q4Answer === "no" &&
      q5Answer === "no" &&
      q6Answer === "no" &&
      q7Answer === "no" &&
      q8Answer === "no" &&
      q9Answer === "no"
    ) {
      menuCardContentArray.push(cardObj["2"], cardObj["4"], cardObj["8"], cardObj["3"], cardObj["9"] );
    } else if (
      q1Answer === "no" &&
      q2Answer === "no" &&
      q4Answer === "no" &&
      q5Answer === "yes" &&
      q6Answer === "no" &&
      q7Answer === "no" &&
      q8Answer === "no" &&
      q9Answer === "no"
    ) {
      menuCardContentArray.push(cardObj["2"], cardObj["4"], cardObj["7"], cardObj["8"], cardObj["9"]);
    } else {
      if (q2Answer === "no") {
        const hasOption8 = optionArray.filter((option) => option === 8);
        const hasOption9 = optionArray.filter((option) => option === 9);
        if (hasOption8.length === 1) {
          optionArray.splice(optionArray.indexOf(8), 1);
        }

        if (hasOption9.length === 1) {
          optionArray.splice(optionArray.indexOf(9), 1);
        }
      }

      if (q3Answer === "no") { 
        const hasOption1 = optionArray.filter((option) => option === 1);
        const hasOption3 = optionArray.filter((option) => option === 3);
        const hasOption4 = optionArray.filter((option) => option === 4);
        const hasOption5 = optionArray.filter((option) => option === 5);
        const hasOption12 = optionArray.filter((option) => option === 12);
        const hasOption13 = optionArray.filter((option) => option === 13);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
        
        if (hasOption3.length === 1) {
          optionArray.splice(optionArray.indexOf(3), 1);
        }

        if (hasOption4.length === 1) {
          optionArray.splice(optionArray.indexOf(4), 1);
        }

        if (hasOption5.length === 1) {
          optionArray.splice(optionArray.indexOf(5), 1);
        }

        if (hasOption12.length === 1) {
          optionArray.splice(optionArray.indexOf(12), 1);
        }

        if (hasOption13.length === 1) {
          optionArray.splice(optionArray.indexOf(13), 1);
        }

        if (q3Answer === "yes" || q3Answer === "not sure") {
          optionArray = [1, 3, 4, 5, 12, 13];
        } 
        else if (q3Answer === "yes-two") {
          optionArray = [1, 3, 4]
        }
         // Add the new code snippet here
        if (q3Answer === "yes-two") {
          optionArray = [1, 3, 4];  // Default order when "yes-two" is selected for q3
        }

        

          if (q4Answer === "no") {
            const hasOption1 = optionArray.filter((option) => option === 1);
            if (hasOption1.length === 1) {
              optionArray.splice(optionArray.indexOf(1), 1);
        }}
      }
    
      if (q4Answer === "no") {
        const hasOption1 = optionArray.filter((option) => option === 1);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
      }

      if (q6Answer === "no") {
        const hasOption5 = optionArray.filter((option) => option === 5);
        if (hasOption5.length === 1) {
          optionArray.splice(optionArray.indexOf(5), 1);
        }
      }

      if (q7Answer === "yes") {
        const hasOption1 = optionArray.filter((option) => option === 1);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
      }

      if (q8Answer === "yes") {
        const hasOption1 = optionArray.filter((option) => option === 1);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
      }

      console.log("Before filtering:", optionArray);
      
      if (q3Answer === "no") {
        const optionsToRemove = [1, 3, 4, 5, 12, 13];
        optionArray = optionArray.filter(option => !optionsToRemove.includes(option));
      }
      console.log("q3Answer:", q3Answer); 
      console.log("After filtering:", optionArray);

      // show only the first 5 options from the array

      for (var i = 0; i < 5; i++) {
        menuCardContentArray.push(cardObj[optionArray[i]]);
      }

      console.log("menuCardContentArray:", menuCardContentArray);

    } // else

    // Get page Assets
    const getAssets = localStorage.getItem("ayaAppAssets");
    const assets = JSON.parse(getAssets);

    const getHeaderImg = assets.filter(
      (obj) => obj.sys.id === "LAadWh2wkflPuIGsXBaAB"
    );

    var pageHeaderImg = "";
    var pageHeaderImgAlt = "";

    if (
      getHeaderImg &&
      getHeaderImg.length > 0 &&
      getHeaderImg[0].fields.file[this.props.appLanguage].url
    ) {
      pageHeaderImg =
        "https:" + getHeaderImg[0].fields.file[this.props.appLanguage].url;
      pageHeaderImgAlt =
        getHeaderImg[0].fields.description[this.props.appLanguage];
    }

    const getImage2 = assets.filter(
        (obj) => obj.sys.id === "y3Rd7KceR170l23wC9ZX9"
      );

      var image2 = "";

      if (
        getImage2 &&
        getImage2.length > 0 &&
        getImage2[0].fields.file[this.props.appLanguage].url
      ) {
        image2 =
          "https:" + getImage2[0].fields.file[this.props.appLanguage].url;
      }


    this.setState({
      pageTitle: menuPageTitle,
      subtitle: subtitle,
      headerImg: pageHeaderImg,
      headerImgAlt: pageHeaderImgAlt,
      ctaCopy: ctaCopy,
      cardTitle: cardTitle,
      image2: image2,
      cardDescription: cardDescription,
      menuCardContent: menuCardContentArray,
    });
  } // componentDidMount()

  goBack = () => {
    this.props.history.goBack()
}


render() {
    const {
      pageTitle,
      subtitle,
      ctaCopy,
      headerImg,
      // eslint-disable-next-line
      image2,
      // eslint-disable-next-line
      cardTitle,
      // eslint-disable-next-line
      carddDescription,
      headerImgAlt,
      menuCardContent
    } = this.state;

    const menuCardContentItems = menuCardContent.map((info, i) => (
      <div key={i}>
        <ContraTestResultCard
          id={i}
          content={info}
        />
      </div>
    ));

    return (
        <div className={styles.pageContainer}>
          <TopNavBarNew />
  
          <div id='scroll' className={styles.pageContainer}>
            <div className={styles.headerArea}>
              {/* Title and Subtitle (with Contentful Markdown) */}
              <div className={styles.moreTopSpace}></div>
              <ContentfulMarkdown children={pageTitle} className={styles.title} />
              <ContentfulMarkdown children={subtitle} className={styles.subTitle} />
  
              {/* Header Image */}
              <img
                src={headerImg}
                className={styles.headerImage}
                alt={headerImgAlt}
              />
            </div>
  
            {/* Cards */}
            <div className="flexColumn">
              {menuCardContentItems}
            </div>
  
            {/* Banner Card */}
            <div className={styles.bannerCardContainer}>
            <BannerCard
                title={this.state.cardTitle} 
                description={this.state.cardDescription}
                image={this.state.image2} 
                gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
                link="/chat-main"
                imageWidth="81px"
                imageHeight="80px"
                imagePosition="bottom-left"
                imageOffsetY=""
                imageOffsetX=""
                titleMaxWidth="254px"
                descriptionMaxWidth="251px"
                cardHeight="88px"
                />
            </div>
  
            {/* CTA Container (the button) */}
            <div className={styles.ctaContainer}>
              <button
                className={styles.ctaButton}
                onClick={() => this.props.history.push('/contra-library')}
              >
                <span className={styles.ctaButtonText}>
                  {/* if you want to use ctaCopy from state: */}
                  {ctaCopy || 'Click Aquí'}
                </span>
              </button>
            </div>
            <div className={styles.moreBottomSpace}></div>
          </div>
          <BottomNavBarNew appLanguage={this.state.appLanguage} />
        </div>
      );
    }
  }