import React from 'react';
import ReactGA from 'react-ga4';
import { ContraTestAnswersWithYesNo } from './ContraTestAnswersWithYesNo'; // Import Yes/No component
/* import styles from './ContraTestQuestions.module.css'; */

export class ContraTestQ2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 2, // 
      contentObj: {},
    };
  }

  componentDidMount() {
    ReactGA.send("/contra-test-questiontwo");
    window.scrollTo(0, 0);
    document.title = "Contraception Test Q2";

    // Retrieve data from localStorage
    const getEntries = JSON.parse(localStorage.getItem('ayaAppEntries')) || [];
    const getContentEntries = getEntries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
    const getPageEntries = getContentEntries.find(obj => obj.sys.id === '6H4Uex1keKwzAnp3ajpPfo'); // ✅ Q2 entry ID

    if (getPageEntries) {
      const fields = getPageEntries.fields;
      this.setState({
        contentObj: {
          pageTitle: fields.pageTitle?.[this.props.appLanguage] || '',
          stepNumber: fields.stepNumber?.[this.props.appLanguage] || '',
          question: fields.title?.[this.props.appLanguage] || '',
          yesOptionText: fields.choice1?.[this.props.appLanguage] || '',
          noOptionText: fields.choice2?.[this.props.appLanguage] || '',
          btn1Text: fields.ctaCopy1?.[this.props.appLanguage] || '',
        }
      });
    }
  }

  continue = (answer) => {
    window.localStorage.setItem('contraTestQ2Answer', answer);
    this.props.history.push('/contra-test-questionthree'); // ✅ Move to Q3
  };

  goBackFunc = () => {
    this.props.history.goBack();
  };

  render() {
    const { contentObj } = this.state;

    return (
      <div >

        <ContraTestAnswersWithYesNo
          appLanguage={this.props.appLanguage}
          history={this.props.history}
          content={contentObj}
          questionWidth={'width78Percent'}
          continue={this.continue}
          goBack={this.goBackFunc}
        />
      </div>
    );
  }
}