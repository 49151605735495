import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import styles from "./Onboarding2New.module.css";
import { LargeButton } from "../../Components/LargeButton";
import MyCalendar from "../../Components/MyCalendar";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function Onboarding2New({ appLanguage }) {
  const [stepNumber, setStepNumber] = useState("");
  const [title, setTitle] = useState("");
  const [title2, setTitle2] = useState("");
  const [buttonText, setButtonText] = useState("Ingresar");
  const [calendarInstructions, setCalendarInstructions] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();

  useEffect(() => {
    ReactGA.send("pageview", "/onboarding2-new");
    window.scrollTo(0, 0);
    document.title = "Onboarding 2 New";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const abortionFlowPage = entries.find(
      (obj) => obj.sys.id === "6bmZQLx8UR9LrJa6k5iwu8"
    );

    if (abortionFlowPage) {
      const fields = abortionFlowPage.fields;
      setStepNumber(fields.stepNumber?.[appLanguage] || "");
      setTitle(fields.title?.[appLanguage] || "");
      setTitle2(fields.title2?.[appLanguage] || "");
      setButtonText(fields.buttonText?.[appLanguage] || "Ingresar");
    }

    const calendarPickerPage = entries.find(
      (obj) => obj.sys.id === "KF6Z2745ExbBRKgJwmvJK"
    );
    if (calendarPickerPage) {
      const fields = calendarPickerPage.fields;
      setCalendarInstructions(fields.calendarInstructions?.[appLanguage] || "");
    }

    const oldDateString = localStorage.getItem("lmpSelectedDate");
    if (oldDateString) {
      const oldDate = new Date(oldDateString);
      if (!isNaN(oldDate)) setSelectedDate(oldDate);
    }
  }, [appLanguage]);

  const handleDateSelected = (day) => setSelectedDate(day);

  const handleContinue = () => {
    if (!selectedDate) {
      alert("Por favor selecciona una fecha primero.");
      return;
    }

    localStorage.setItem("lmpSelectedDate", selectedDate.toISOString());
    const today = new Date();
    const diffInMs = today - selectedDate;
    const diffInDays = Math.floor(diffInMs / 86400000);
    const weeksPregnant = Math.floor(diffInDays / 7);

    localStorage.setItem("pregnancyWeeks", weeksPregnant);
    history.push(weeksPregnant <= 12 ? "/eligibility-under-12" : "/eligibility-over-12");
  };

  return (
            <>
    <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    <div className={styles.background}>
      
      <div className={styles.topSection}>
        <div className={styles.stepNumber}>{stepNumber}</div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.whiteCard}>
        <div className={styles.title2}>{title2}</div>
        <div className={styles.calendarInstructions}>
          {calendarInstructions}
        </div>
        <MyCalendar onDateSelected={handleDateSelected} />
        <div className={styles.buttonContainer}>
          <LargeButton
            largeButtonFunc={handleContinue}
            text={buttonText}
            textColor="colorWhite"
            backgroundColor="backgroundColorDarkPurple"
          />
        </div>
      </div>
      <div className={styles.extraSpaceTop}></div>
<BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}