import React from 'react';
import PropTypes from 'prop-types';
import styles from './IconTextElement.module.css';

const IconTextElement = ({ iconUrl, text, className }) => {
  return (
    <div className={`${styles.iconTextElement} ${className}`}>
      <img src={iconUrl} alt="" className={styles.icon} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

IconTextElement.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default IconTextElement;
