import React from 'react';
import TopNavBarNew from '../../Components/TopNavBarNew';
import BottomNavBarNew from '../../Components/BottomNavBarNew';
import styles from './ContraTestQuestions2.module.css'; // ✅ Apply Onboarding styles

export class ContraTestAnswersWithYesYesNoMaybe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            questionYesSelected: false,
            question2YesSelected: false,
            questionNoSelected: false,
            questionMaybeSelected: false,
            appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en", // Ensure appLanguage is always available
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.appLanguage !== this.props.appLanguage) {
            this.setState({
                appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en"
            });
        }
    }

    toggleRadio = (currentSelectedStateName, ...otherStateNames) => {
        // Reset all other options
        const otherStatesReset = {};
        otherStateNames.forEach(name => {
            otherStatesReset[name] = false;
        });

        this.setState({
            [currentSelectedStateName]: true,
            ...otherStatesReset
        });
    };

    continue = () => {
        if (this.state.questionYesSelected) {
            this.props.continue('yes');
        } else if (this.state.questionNoSelected) {
            this.props.continue('no');
        } else if (this.state.question2YesSelected) {
            this.props.continue('question2Yes');
        } else if (this.state.questionMaybeSelected) {
            this.props.continue('maybe');
        }
    };

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { content } = this.props;
        const { appLanguage } = this.state;

        return (
            <>
                <TopNavBarNew backgroundColor="#4450E5" breadcrumbColor="#fff"/>
                <div className={styles.pageContainer}>
                    
                    <div className={styles.topSection}>
                    <p className={styles.pageTitle}>{content.pageTitle}</p>
                        <p className={styles.stepNumber}>{content.stepNumber}</p>
                    </div>

                    <div className={styles.whiteCard}>
                        <h1 className={styles.title}>{content.question}</h1>
                        <form className={styles.optionContainer}>
                            
                            {/* ✅ Yes Option 1 */}
                            <div
                                className={`${styles.optionRow} ${this.state.questionYesSelected ? styles.choiceSelected : ''}`}
                                onClick={() => this.toggleRadio('questionYesSelected', 'questionNoSelected', 'question2YesSelected', 'questionMaybeSelected')}
                            >
                                <span>{content.yesOptionText}</span>
                            </div>

                            {/* ✅ Yes Option 2 */}
                            <div
                                className={`${styles.optionRow} ${this.state.question2YesSelected ? styles.choiceSelected : ''}`}
                                onClick={() => this.toggleRadio('question2YesSelected', 'questionYesSelected', 'questionNoSelected', 'questionMaybeSelected')}
                            >
                                <span>{content.secondYesOptionText}</span>
                            </div>

                            {/* ✅ No Option */}
                            <div
                                className={`${styles.optionRow} ${this.state.questionNoSelected ? styles.choiceSelected : ''}`}
                                onClick={() => this.toggleRadio('questionNoSelected', 'questionYesSelected', 'question2YesSelected', 'questionMaybeSelected')}
                            >
                                <span>{content.noOptionText}</span>
                            </div>

                            {/* ✅ Maybe Option */}
                            <div
                                className={`${styles.optionRow} ${this.state.questionMaybeSelected ? styles.choiceSelected : ''}`}
                                onClick={() => this.toggleRadio('questionMaybeSelected', 'questionYesSelected', 'question2YesSelected', 'questionNoSelected')}
                            >
                                <span>{content.maybeOptionText}</span>
                            </div>

                        </form>

                        {/* ✅ Continue Button */}
                        <button
                            className={`${styles.continueButton} ${(this.state.questionYesSelected || this.state.questionNoSelected || this.state.question2YesSelected || this.state.questionMaybeSelected) ? styles.continueButtonActive : ''}`}
                            onClick={this.continue}
                        >
                            {content.btn1Text}
                        </button>
                    </div>

                    <div className={styles.bottomSpace}></div>

                    {/* Pass appLanguage safely to BottomNavBarNew */}
                    <BottomNavBarNew appLanguage={appLanguage}/>
                </div>
            </>
        );
    }
}

export default ContraTestAnswersWithYesYesNoMaybe;