import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import RadioCard from '../../Components/RadioCard';
import { useHistory } from 'react-router-dom';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './Onboarding1New.module.css';

const Onboarding1New = ({ appLanguage }) => {
  const history = useHistory();

  // State Management
  const [stepNumber, setStepNumber] = useState('');
  const [title, setTitle] = useState('');
  const [title2, setTitle2] = useState(''); // Second title dynamically
  const [questionOptions, setQuestionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [buttonText, setButtonText] = useState('');

  // Contentful Data Retrieval
  useEffect(() => {
    ReactGA.send('pageview', '/onboarding1-new');
    window.scrollTo(0, 0);
    document.title = 'Onboarding 1 New';

    // path: /eligibility-start

    const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);

    const getContentEntries = entries?.filter(
      (obj) => obj.sys.contentType.sys.id === 'abortionFlow'
    );

    const getPageEntries = getContentEntries?.filter(
      (obj) => obj.sys.id === '7JKhfbGEpikOGSnzbIIZzq'
    );

    if (getPageEntries && getPageEntries.length > 0) {
      const pageFields = getPageEntries[0].fields;

      // Fetch all fields dynamically
      setStepNumber(pageFields.stepNumber?.[appLanguage] || '');
      setTitle(pageFields.title?.[appLanguage] || '');
      setTitle2(pageFields.title2?.[appLanguage] || ''); // Added dynamic title2
      setButtonText(pageFields.buttonText?.[appLanguage] || '');

      // Populate radio options dynamically
      const options = [];
      if (pageFields.optionText1) {
        options.push({ option: pageFields.optionText1[appLanguage], stateName: 'optionText1' });
      }
      if (pageFields.optionText2) {
        options.push({ option: pageFields.optionText2[appLanguage], stateName: 'optionText2' });
      }
      if (pageFields.optionText3) {
        options.push({ option: pageFields.optionText3[appLanguage], stateName: 'optionText3' });
      }
      setQuestionOptions(options);
    }
  }, [appLanguage]);

  // Handle Radio Selection
  const handleCardClick = (stateName) => {
    setSelectedOption(stateName);
  };

  // Handle Continue Button
  const handleContinue = () => {
    const selectedOptionObj = questionOptions.find((opt) => opt.stateName === selectedOption);

    if (selectedOptionObj) {
      // Determine the path dynamically based on the selected option
      let path = '';
      switch (selectedOption) {
        case 'optionText1':
          path = '/eligibility-calendar-lmp'; // Adjust this path as needed
          break;
        case 'optionText2':
          path = '/eligibility-tip'; // Adjust this path as needed
          break;
        case 'optionText3':
          path = '/eligibility-calendar-edd'; // Adjust this path as needed
          break;
        default:
          path = '/'; // Fallback path
      }

      // Save selected option to localStorage (if needed)
      const selectedQuestions = [selectedOptionObj.option];
      window.localStorage.setItem('onboardingQ1selectedQuestions', JSON.stringify(selectedQuestions));

      // Navigate to the selected path
      history.push(path);
    }
  };

  return (
  <>
    <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    <div className={styles.background}>
      {/* Title and Step Counter */}
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.stepCounter}>{stepNumber}</p>
      </div>

      {/* White Card */}
      <div className={styles.whiteCard}>
        <h2 className={styles.subtitle}>{title2}</h2>
        <div className={styles.radioGroup}>
          {questionOptions.map((option, index) => (
            <RadioCard
              key={index}
              text={option.option}
              isSelected={selectedOption === option.stateName}
              onClick={() => handleCardClick(option.stateName)}
            />
          ))}
        </div>

        <div className={styles.extraSpaceTop}></div>
              {/* Continue Button */}
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.continueButton} ${
            selectedOption ? styles.buttonActive : styles.buttonInactive
          }`}
          onClick={handleContinue}
          disabled={!selectedOption}
        >
          {buttonText || 'Continuar'}
        </button>
      </div>
      </div>

      <div className={styles.extraSpaceTop}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
};

export default Onboarding1New;