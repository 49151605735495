import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DownArrow from '../Images/downArrow.svg';
import * as ContentfulMarkdown from 'react-markdown';

function BasicAccordionRounded(props) {
  const { heading, content, expanded, onChange, disabled } = props;

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      disabled={disabled}
      sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.15)',
        marginBottom: '16px',
        background: '#FFF',
        width: '348px',
      }}
    >
      <AccordionSummary
        expandIcon={
          <img
            src={DownArrow}
            alt="Expand"
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        }
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // Title and icon on the same line
          padding: '13px 16px',
          backgroundColor: '#FFF', // Ensures no grey background
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between', // Title and arrow alignment
          },
        }}
      >
        {/* Title */}
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '13px', // Adjusted font size
            fontWeight: '600', // Adjusted font weight
            lineHeight: '19px',
            color: '#333333', // Darker color for better contrast
            wordBreak: 'break-word',
          }}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      {expanded && ( // Line only visible when expanded
        <div
          style={{
            width: '301px',
            height: '1px',
            backgroundColor: '#4D4D4D',
            margin: '8px auto', // Adjust spacing
          }}
        ></div>
      )}
      <AccordionDetails
        sx={{
          padding: '13px 16px',
          backgroundColor: '#FFF', // Ensure no grey background
        }}
      >
        {/* Description */}
        <Typography
          component="div" // Use div to support rich text
          sx={{
            fontFamily: 'Inter',
            fontSize: '13px', // Slightly larger font for readability
            fontWeight: '400',
            lineHeight: '19px',
            color: '#505050 !important',
          }}
        >
          <ContentfulMarkdown
            children={content}
            allowDangerousHtml={true}
            components={{
              p: ({ node, ...props }) => (
                <p
                  {...props}
                  style={{
                    marginBottom: '10px', // Add space between paragraphs
                    color: '#666', // Light gray text
                  }}
                >
                  {props.children}
                </p>
              ),
              a: ({ node, ...props }) => (
                <a
                  {...props}
                  style={{
                    color: '#222EB9',
                    textDecoration: 'underline',
                    fontWeight: '600',
                  }}
                >
                  {props.children}
                </a>
              ),
            }}
          />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default BasicAccordionRounded;