import React from "react";
import { Link } from "react-router-dom";
import styles from "./PopUpNew.module.css";

export default function PopUpNew({
  show,
  title,
  description,
  button1Text,
  onButton1Click,
  button2Text,
  button2Path,
}) {
  if (!show) return null; // If `show` is false, don't render

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContainer}>
        <h2 className={styles.popupTitle}>{title}</h2>
        <p className={styles.popupDescription}>{description}</p>

        <div className={styles.buttonRow}>
          {/* Primary button */}
          <button className={styles.popupButton} onClick={onButton1Click}>
            {button1Text}
          </button>

          {/* Optional second link */}
          {button2Text && (
            <Link to={button2Path || "#"} className={styles.popupLink}>
              {button2Text}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}