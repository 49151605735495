import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import LargeImageCard from '../../Components/LargeImageCard';
import SmallIconCard from '../../Components/SmallIconCard';
import BannerCard from '../../Components/BannerCard';
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import { Link } from "react-router-dom";
import styles from './HomePageNew.module.css';

export default function HomePageNew({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    extraText: '',
    largeCardTitle1: '',
    largeCardTittle2: '',
    smallCardTitle1: '',
    smallCardTitle2: '',
    smallCardTitle3: '',
    smallCardTitle4: '',
    bannerCardDescription1: '',
    bannerCardDescription2: '',
    bannerCardTitle1: '',
    bannerCardTitle2: '',
    smallCardLink1: '',
    smallCardLink2: '',
    smallCardLink3: '',
    smallCardLink4: '',
    externalBannerLink: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
    image6: '',
    image7: '',
    image8: '',
    arrowIcon: '',
    lockIcon: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Home';

    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    const assetsMapping = {
      image1: "4PRStO660MxXni9z6sY8j8",
      image2: "01OKOkJKnkhbdVZXksKyej",
      image3: "67btoCBBjGCy3f0Ud3GDNK",
      image4: "4PBDZ5N27s1aTw3sXzu96s",
      image5: "5nXcEQAyyrDtoQfrk0OF3w",
      image6: "3cYMHlvMm6yrWNmY9gVnS0",
      image7: "OhBvnRbWdrnPMdn7y11FU",
      image8: "y3Rd7KceR170l23wC9ZX9",
      arrowIcon: "39RtDZzFYwLgFYIZzzSN1k",
      lockIcon: "1fz4mD5lp7lWSawOZjs8BY",
    };

    const entryId = "1LOw826mQ6Pn0yTGAw5eqQ";

    const processedEntries = processEntries(entries, appLanguage, {
      'largeMenu': {
        entryId: entryId,
        fieldsMapping: {
          title: 'title',
          title2: 'title2',
          title3: 'title3',
          title4: 'title4',
          title5: 'title5',
          extraText: 'extraText',
          largeCardTitle1: 'largeCardTitle1',
          largeCardTittle2: 'largeCardTittle2',
          smallCardTitle1: 'smallCardTitle1',
          smallCardTitle2: 'smallCardTitle2',
          smallCardTitle3: 'smallCardTitle3',
          smallCardTitle4: 'smallCardTitle4',
          smallCardLink1: 'smallCardLink1',
          smallCardLink2: 'smallCardLink2',
          smallCardLink3: 'smallCardLink3',
          smallCardLink4: 'smallCardLink4',
          externalBannerLink: 'externalBannerLink',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardDescription2: 'bannerCardDescription2',
          bannerCardTitle1: 'bannerCardTitle1',
          bannerCardTitle2: 'bannerCardTitle2',
        }
      },
    });

    const processedAssets = processAssets(assets, appLanguage, assetsMapping);

    setPageState({
      title: processedEntries['largeMenu']?.title || '',
      title2: processedEntries['largeMenu']?.title2 || '',
      title3: processedEntries['largeMenu']?.title3 || '',
      title4: processedEntries['largeMenu']?.title4 || '',
      title5: processedEntries['largeMenu']?.title5 || '',
      extraText: processedEntries['largeMenu']?.extraText || '',
      largeCardTitle1: processedEntries['largeMenu']?.largeCardTitle1 || 'Default Large Card 1',
      largeCardTittle2: processedEntries['largeMenu']?.largeCardTittle2 || 'Default Large Card 2',
      smallCardTitle1: processedEntries['largeMenu']?.smallCardTitle1 || 'Default Small Card 1',
      smallCardTitle2: processedEntries['largeMenu']?.smallCardTitle2 || 'Default Small Card 2',
      smallCardTitle3: processedEntries['largeMenu']?.smallCardTitle3 || 'Default Small Card 3',
      smallCardTitle4: processedEntries['largeMenu']?.smallCardTitle4 || 'Default Small Card 4',
      smallCardLink1: processedEntries['largeMenu']?.smallCardLink1 || '/resources-library',
      smallCardLink2: processedEntries['largeMenu']?.smallCardLink2 || '/faqs-library',
      smallCardLink3: processedEntries['largeMenu']?.smallCardLink3 || '/myths-library',
      smallCardLink4: processedEntries['largeMenu']?.smallCardLink4 || '/real-stories-library',
      externalBannerLink: processedEntries['largeMenu']?.externalBannerLink || 'https://hola.ayacontigo.org/en/foro', // ✅ External URL
      bannerCardDescription1: processedEntries['largeMenu']?.bannerCardDescription1 || 'Default Banner Card Description 1',
      bannerCardDescription2: processedEntries['largeMenu']?.bannerCardDescription2 || 'Default Banner Card Description 2',
      bannerCardTitle1: processedEntries['largeMenu']?.bannerCardTitle1 || 'Join the Forum',
      bannerCardTitle2: processedEntries['largeMenu']?.bannerCardTitle2 || 'Join the Forum 2',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
      image3: processedAssets?.image3 || '',
      image4: processedAssets?.image4 || '',
      image5: processedAssets?.image5 || '',
      image6: processedAssets?.image6 || '',
      image7: processedAssets?.image7 || '',
      image8: processedAssets?.image8 || '',
      arrowIcon: processedAssets?.arrowIcon || '',
      lockIcon: processedAssets?.lockIcon || '',
    });
  }, [appLanguage]);

  const hasVisitedPinSuccess = localStorage.getItem("hasVisitedPinSuccess") === "true";
  const pinSetupPath = hasVisitedPinSuccess ? "/more-set-pin-success" : "/more-set-pin";

  return (
    <div className={styles.homePageContainer}>

      <div className={styles.headerContainer}>
      <div className={styles.sectionTitle}>{pageState.title}</div>
      <Link to={pinSetupPath}>
                  <img className={styles.lockIcon} src={pageState.lockIcon} alt="Lock Icon" />
      </Link>
      </div>
      <div className={styles.sectionTitle2}>{pageState.title2}</div>

      <LargeImageCard 
        title={pageState.largeCardTitle1}
        image={pageState.image1}
        arrowIcon={pageState.arrowIcon}
        link="/contra-library"
        backgroundColor="#E3F4FF"
      />

      <LargeImageCard 
        title={pageState.largeCardTittle2}
        image={pageState.image2}
        arrowIcon={pageState.arrowIcon}
        link="/abor-library"
        backgroundColor="#FDE8D8"
      />
  
      <div className={styles.exploraContainer}>
        <div className={styles.sectionTitleExplora}>{pageState.title3}</div>
        <div className={styles.scrollInfo}>{pageState.extraText}</div>
      </div>

      <div className={styles.exploreSection}>
  <div className={styles.carouselContainer}>
    {[
      { title: pageState.smallCardTitle1, icon: pageState.image3, link: "/resources-library" },
      { title: pageState.smallCardTitle2, icon: pageState.image4, link: "/faqs-library" },
      { title: pageState.smallCardTitle3, icon: pageState.image5, link: "/myths-library" },
      { title: pageState.smallCardTitle4, icon: pageState.image6, link: "/real-stories-library" }
    ].map((card, index) => (
      <SmallIconCard 
        key={index} 
        title={card.title} 
        icon={card.icon} 
        link={card.link} // ✅ Links are correctly passed
      />
    ))}
  </div>
</div>

      <div className={styles.sectionTitle2}>{pageState.title4}</div>

      {/* ✅ External link for BannerCard */}
             <BannerCard 
                title={pageState.bannerCardTitle1} 
                description={pageState.bannerCardDescription1}
                image={pageState.image7}
                gradient="linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%)"
                link={pageState.externalBannerLink} 
                isExternal={true} 
                imagePosition='right'
                imageWidth="130px"
                imageHeight='130px'
                titleMaxWidth="300px"
                descriptionMaxWidth="300px"
                cardWidth="349px"
                cardHeight="88px"
                titleFont="Inter"
                  titleFontSize="12px"
                  fontSize="12px"
                  titleFontWeight="700"
                  /* descriptionFontSize="10px" */
                  descriptionFontWeight="400"
                  titleFontFamily="GT Walsheim Pro"
                  descriptionFontFamily="Inter"
                  descriptionColor='#FFFFFF'
                />

      <div className={styles.sectionTitle2}>{pageState.title5}</div>

               <BannerCard 
                title={pageState.bannerCardTitle2} 
                description={pageState.bannerCardDescription2}
                image={pageState.image8}
                gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"

                link="/chat-main"
                imageWidth="81px"
                imageHeight="80px"
                imagePosition="top-right"
                titleMaxWidth="256px"
                descriptionMaxWidth="286px"
                cardWidth="349px"
                cardHeight="88px"
                titleFont="Inter"
                  titleFontSize="14px"
                  titleFontWeight="700"
                  descriptionFontSize="13px"
                  descriptionFontWeight="400"
                  titleFontFamily="GT Walsheim Pro"
                  descriptionFontFamily="Inter"
                  descriptionColor='#FFFFFF'
                />

        <div className={styles.bottomSpace}></div>

      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}