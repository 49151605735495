import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import FeedbackCard from '../../Components/FeedbackCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './PillAbortionTest.module.css';

export default function PillAbortionAfterOneTest({ appLanguage }) {
  const [pageState, setPageState] = useState({
    headerTitle: '',
    introCard: { title: '', description: '' },
    questions: [],
    yesText: '',
    noText: '',
    smallCards: [],
    headerImage: '',
    extraText: '',
    extraText2: '',
    ctaCopy1: '',
  });

  const [answers, setAnswers] = useState({});
  const [allAnswered, setAllAnswered] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Abor | Test';

    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    const processedEntries = processEntries(entries, appLanguage, {
      stepsCompleteYourAbortion: {
        entryId: '4yIvv6DJQMvBdJZY8wQf9I',
        fieldsMapping: {
          headerTitle: 'headerTitle',
          title: 'title',
          text: 'text',
          stepNumber: 'stepNumber',
          question1: 'question1',
          question2: 'question2',
          question3: 'question3',
          question4: 'question4',
          question5: 'question5',
          question6: 'question6',
          question7: 'question7',
          question8: 'question8',
          yesText: 'yesText',
          noText: 'noText',
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          smallCardTitle2: 'smallCardTitle2',
          smallCardDescription2: 'smallCardDescription2',
          ctaCopy1: 'ctaCopy1',
          extraText: 'extraText',
          extraText2: 'extraText2',
        },
      },
    });

    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '01OKOkJKnkhbdVZXksKyej',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      blueArrowIcon: 'h150pgUHcW5LIJwnFodWZ',
    });

    setPageState({
      headerTitle: processedEntries.stepsCompleteYourAbortion?.headerTitle || '',
      introCard: {
        title: processedEntries.stepsCompleteYourAbortion?.title || '',
        description: processedEntries.stepsCompleteYourAbortion?.text || '',
        ovalText: processedEntries.stepsCompleteYourAbortion?.stepNumber || '',
      },
      questions: [
        processedEntries.stepsCompleteYourAbortion?.question1,
        processedEntries.stepsCompleteYourAbortion?.question2,
        processedEntries.stepsCompleteYourAbortion?.question3,
        processedEntries.stepsCompleteYourAbortion?.question4,
        processedEntries.stepsCompleteYourAbortion?.question5,
        processedEntries.stepsCompleteYourAbortion?.question6,
        processedEntries.stepsCompleteYourAbortion?.question7,
        processedEntries.stepsCompleteYourAbortion?.question8,
      ],
      // We'll no longer directly use these for button text, 
      // but we'll still store them in case we need them elsewhere.
      yesText: processedEntries.stepsCompleteYourAbortion?.yesText || 'Sí',
      noText: processedEntries.stepsCompleteYourAbortion?.noText || 'No',

      smallCards: [
        {
          title: processedEntries.stepsCompleteYourAbortion?.smallCardTitle1 || '',
          description: processedEntries.stepsCompleteYourAbortion?.smallCardDescription1 || '',
          icon: processedAssets.blueArrowIcon || '',
          link: '/contra-library',
        },
        {
          title: processedEntries.stepsCompleteYourAbortion?.smallCardTitle2 || '',
          description: processedEntries.stepsCompleteYourAbortion?.smallCardDescription2 || '',
          icon: processedAssets.blueArrowIcon || '',
          link: '/abor-after-managingemotions',
        },
      ],
      extraText: processedEntries.stepsCompleteYourAbortion?.extraText || '',
      extraText2: processedEntries.stepsCompleteYourAbortion?.extraText2 || '',
      ctaCopy1: processedEntries.stepsCompleteYourAbortion?.ctaCopy1 || '',
      headerImage: processedAssets.headerImage || '',
    });
  }, [appLanguage]);

  // Decide text for yes/no based on appLanguage (English or Spanish).
  const yesLabel = appLanguage?.startsWith('en') ? 'Yes' : 'Sí';
  // "No" is the same in English/Spanish, but you could also do 
  // something like: appLanguage?.startsWith('en') ? 'No' : 'No';
  const noLabel  = 'No';

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers((prev) => ({ ...prev, [questionIndex]: answer }));
  };

  useEffect(() => {
    setAllAnswered(
      pageState.questions.length > 0 &&
        pageState.questions.every((_, index) => answers[index] !== undefined)
    );
  }, [answers, pageState.questions]);

  const handleSubmit = () => {
    if (!allAnswered) return;

    // ------------------------------------------
    // Logic copied from the live app's 4 scenarios
    // ------------------------------------------
    // 1) no no no no yes no no no => negative
    if (
      answers[0] === 'no' &&
      answers[1] === 'no' &&
      answers[2] === 'no' &&
      answers[3] === 'no' &&
      answers[4] === 'yes' &&
      answers[5] === 'no' &&
      answers[6] === 'no' &&
      answers[7] === 'no'
    ) {
      window.location.href = '/abor-aftertest-negative';
    }
    // 2) yes yes yes yes no yes yes yes => negative
    else if (
      answers[0] === 'yes' &&
      answers[1] === 'yes' &&
      answers[2] === 'yes' &&
      answers[3] === 'yes' &&
      answers[4] === 'no' &&
      answers[5] === 'yes' &&
      answers[6] === 'yes' &&
      answers[7] === 'yes'
    ) {
      window.location.href = '/abor-aftertest-negative';
    }
    // 3) no no no no yes yes yes yes => negative
    else if (
      answers[0] === 'no' &&
      answers[1] === 'no' &&
      answers[2] === 'no' &&
      answers[3] === 'no' &&
      answers[4] === 'yes' &&
      answers[5] === 'yes' &&
      answers[6] === 'yes' &&
      answers[7] === 'yes'
    ) {
      window.location.href = '/abor-aftertest-negative';
    }
    // 4) yes yes yes yes no no no no => positive
    else if (
      answers[0] === 'yes' &&
      answers[1] === 'yes' &&
      answers[2] === 'yes' &&
      answers[3] === 'yes' &&
      answers[4] === 'no' &&
      answers[5] === 'no' &&
      answers[6] === 'no' &&
      answers[7] === 'no'
    ) {
      window.location.href = '/abor-aftertest-positive';
    }
    // All else => negative
    else {
      window.location.href = '/abor-aftertest-negative';
    }
  };

  return (
    
      <div className={styles.libraryAbortionMenuContainer}>
      <TopNavBarNew backgroundColor="#FDE8D8" breadcrumbColor="#333" />
      <div className={styles.spacer}></div>
      <div className={styles.headerContainer}>
      <HeaderCardNew
        title={pageState.headerTitle}
        image={pageState.headerImage}
        backgroundColor="#FDE8D8"
        className={styles.headerContainer}
        borderRadius="0px"
      />
      </div>

      <div className={styles.extraSpace2}></div>
      <div className={styles.testPageContainer}>
      <FlexibleCard
        blueTitle={pageState.introCard.title}
        description={pageState.introCard.description}
        borderRadius="10px"
        width="100%"
        showOval={true}
        ovalText={pageState.introCard.ovalText}
        className={styles.specificFlexibleCard}
      />

      <div className={styles.questionsSection}>
        {pageState.questions.map((question, index) => (
          <div key={index} className={styles.questionCard}>
            <p className={styles.questionText}>{question}</p>
            <div className={styles.questionCardButtons}>
              <button
                className={`${styles.yesNoButton} ${answers[index] === 'yes' ? styles.active : ''}`}
                onClick={() => handleAnswerChange(index, 'yes')}
              >
                {yesLabel}
              </button>
              <button
                className={`${styles.yesNoButton} ${answers[index] === 'no' ? styles.active : ''}`}
                onClick={() => handleAnswerChange(index, 'no')}
              >
                {noLabel}
              </button>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={handleSubmit}
        className={`${styles.submitButton} ${!allAnswered ? styles.inactive : ''}`}
        disabled={!allAnswered}
      >
        {pageState.ctaCopy1}
      </button>

      <FeedbackCard title={pageState.extraText} appLanguage={appLanguage} />

      {/* Extra Text Section */}
      <div className={styles.leftAlignWrapper}>
        <div className={styles.extraTitletwo}>{pageState.extraText2}</div>
      </div>
      
      <div className={styles.carouselContainer}>
        {pageState.smallCards.map((card, index) => (
          <CarouselSmallCard
            key={index}
            title={card.title}
            extraText={card.description}
            icon={card.icon}
            link={card.link}
            backgroundColor="#FFCCA7"
            borderColor="#FF8652"
          />
        ))}
      </div>
      </div>
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}