import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioCard.module.css';
import QuestionmarkIcon from '../Images/questionmarkIcon.svg'; // Adjust this path to match your project structure

const RadioCard = ({
  text,
  isSelected,
  hasIcon = false,
  onClick,
  onIconClick,
  maxTextWidth = '100%', // Adjust text width when the icon is present
}) => {
  return (
    <button
      className={`${styles.cardButton} ${isSelected ? styles.selected : ''} ${
        hasIcon ? styles.cardWithIcon : ''
      }`}
      onClick={onClick}
    >
      <div className={styles.cardContent}>
        <p className={styles.cardText} style={{ maxWidth: maxTextWidth }}>
          {text}
        </p>
        {hasIcon && (
          <button
            className={styles.iconButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent card click when icon is clicked
              onIconClick();
            }}
          >
            <img
              src={QuestionmarkIcon}
              alt="Info"
              className={styles.questionmarkIcon}
            />
          </button>
        )}
      </div>
    </button>
  );
};

RadioCard.propTypes = {
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  hasIcon: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  maxTextWidth: PropTypes.string,
};

export default RadioCard;