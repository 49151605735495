import React, { Component } from "react";
import { Link } from "react-router-dom";  // Import Link for internal navigation
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
/* import { processAssets } from "../../utils/contentProcessor"; */
import styles from "./PINSetUp.module.css";

export class PINSetUpSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: {},
      assets: {},
      appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en", // Ensure appLanguage is always available
    };
  }

  componentDidMount() {
    // Check if the user has visited the PIN success page before
    localStorage.setItem("hasVisitedPinSuccess", "true");

    // Get Contentful data
    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    
/*     const assets = JSON.parse(localStorage.getItem("ayaAppAssets")); */

    // Find the content entry for this page
    const pageEntry = entries.find((obj) => obj.sys.id === "4aTdTg8bSRIFGslvxEa2XE");

    let pageContent = {};
    if (pageEntry) {
      pageContent = {
        title: pageEntry.fields.largeCardTitle1?.[this.state.appLanguage] || "Sobre Vitala",
        description: pageEntry.fields.largeCardDescription1?.[this.state.appLanguage] || "",
        buttonText: pageEntry.fields.largeCardTitle2?.[this.state.appLanguage] || "",
        buttonLink: pageEntry.fields.buttonLink || "/home",  // Internal path
      };
    }

    this.setState({ pageContent });
  }

  render() {
    const { pageContent, appLanguage } = this.state;

    return (
      <div className={styles.container}>
        {/* Top Navbar with Title */}
        <TopNavBarNew pageTitle={pageContent.title} />

        <div className={styles.extraSpace}></div>

        {/* White Card with Blue Button Inside */}
        <div className={styles.card}>
          <p className={styles.cardTitle}>{pageContent.title}</p>
          <p className={styles.cardText}>{pageContent.description}</p>
          <Link to={pageContent.buttonLink} className={styles.blueCard}>
            <span className={styles.blueCardText}>{pageContent.buttonText}</span>
{/*             {this.state.assets.arrowIcon && (
              <img src={this.state.assets.arrowIcon} alt="Arrow" className={styles.arrowIcon} />
            )} */}
          </Link>
        </div>

        {/* Bottom Nav Bar with appLanguage */}
        <BottomNavBarNew appLanguage={appLanguage} />
      </div>
    );
  }
}

export default PINSetUpSuccess;