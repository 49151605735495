import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import FeedbackCard from '../../Components/FeedbackCard';
import BannerCard from '../../Components/BannerCard';
import CallToActionCard from '../../Components/CallToActionCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './PillAbortion.module.css';

export default function PillAbortionAfterTwo({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    extraText: '',
    extraText2: '',
    extraText3: '',
    narrowCardTitle1: '',
    narrowCardTitle2: '',
    // Individual Large Cards
    largeCard1: {},
    largeCard2: {},
    largeCard3: {},
    largeCardTitle4: '',
    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',
    smallCardTitle2: '',
    smallCardDescription2: '',

    // Banner Card
    bannerCardDescription1: '',
    bannerCardTitle1: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Pill Abortion After Two';
    
    //path: /pillabortionaftertwo

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = '3nZNr8FcpBx1zuE1Dl8skg';
    const largeCardEntryId = 'sixjuzKBIytE4uhEV8TvG';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          narrowCardTitle1: 'narrowCardTitle1',
          narrowCardTitle2: 'narrowCardTitle2',
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          smallCardTitle2: 'smallCardTitle2',
          smallCardDescription2: 'smallCardDescription2',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1',
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '01OKOkJKnkhbdVZXksKyej',
      arrowIconWhite: '5t0SZSeDrtBlIZ3o0Apjcu',
      arrowIconDark: '35s9SZBtTGy9KGs0lc41lt',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: 'y3Rd7KceR170l23wC9ZX9',
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      extraText: processedEntries['abortoConPastillas']?.extraText || '',
      extraText2: processedEntries['abortoConPastillas']?.extraText2 || '',
      extraText3: processedEntries['abortoConPastillas']?.extraText3 || '',
      narrowCardTitle1: processedEntries['abortoConPastillas']?.narrowCardTitle1 || '',
      narrowCardTitle2: processedEntries['abortoConPastillas']?.narrowCardTitle2 || '',
      
      // Banner Card
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
      bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',


      // Small Cards Section
      smallCardTitle1: processedEntries['abortoConPastillas']?.smallCardTitle1 || '',
      smallCardDescription1: processedEntries['abortoConPastillas']?.smallCardDescription1 || '',
      smallCardTitle2: processedEntries['abortoConPastillas']?.smallCardTitle2 || '',
      smallCardDescription2: processedEntries['abortoConPastillas']?.smallCardDescription2 || '',

      // Individual Large Cards
      largeCard1: {
        ovalText: processedEntries.largeCardCollection?.largeCardTitle4 || '',
        blueTitle: processedEntries['largeCardCollection']?.largeCardTitle1 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription1 || '',
      },
      largeCard2: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription2 || '',
      },
      largeCard3: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle3 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription3 || '',
      },

      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
    });
  }, [appLanguage]);

  return (
    <div className={styles.libraryAbortionMenuContainer}>
    <TopNavBarNew backgroundColor="#FDE8D8" breadcrumbColor="#333" />
      {/* Header Section */}
      <div className={styles.headerContainer2}>
        <HeaderCardNew
          title={pageState.headerTitle}
          image={pageState.headerImage}
          backgroundColor="#FDE8D8"
          borderRadius="0px"
        />
        <div className={styles.bottomSpaceHeader}></div>
     
      </div>
      <div className={styles.homePageContainer}>

        {/* Render Individual Large Cards */}
        <FlexibleCard
          blueTitle={pageState.largeCard1.blueTitle}
          blueTitleWidth="213px"
          description={pageState.largeCard1.description}
          showOval={true}
          ovalText={pageState.largeCard1.ovalText}
          width="351px"
          borderRadius="10px"
        />

        {/* Render Individual Large Cards */}
        <FlexibleCard
          blackTitle={pageState.largeCard2.blackTitle}
          description={pageState.largeCard2.description}
          width="351px"
          borderRadius="10px"
        />

        {/* Call To Action Card 1 */}
        <CallToActionCard
          title={pageState.narrowCardTitle1}
          icon={pageState.arrowIconWhite}
          showIcon={true}
          isLink={true}
          buttonLink="/real-stories-library"
          useCardGradient={true}
          titleColor="#fff"
          cardGradient="linear-gradient(90deg, #E12541 0%, #F85833 100%)"
          width="356px"
          height="52px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />


        <FlexibleCard
          blackTitle={pageState.largeCard3.blackTitle}
          description={pageState.largeCard3.description}
          width="351px"
          borderRadius="10px"
        />

        {/* Call To Action Card 2 */}
        <CallToActionCard
          title={pageState.narrowCardTitle2}
          icon={pageState.arrowIconWhite}
          showIcon={true}
          isLink={true}
          buttonLink="/self-care-library"
          useCardGradient={true}
          titleColor="#fff"
          cardGradient="linear-gradient(90deg, #E12541 0%, #F85833 100%)"
          width="356px"
          height="52px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />

        <BannerCard 
        title={pageState.bannerCardTitle1} 
        description={pageState.bannerCardDescription1}
        image={pageState.image1}
        gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
        link="/chat-main"
        imageWidth="81px"
        imageHeight="80px"
        imagePosition="top-right"
        imageOffsetY=""
        imageOffsetX=""
        titleMaxWidth="256px"
        descriptionMaxWidth="286px"
        cardWidth="349px"
        cardHeight="88px"
        />

        {/* Extra Text Section */}
        <div className={styles.extraTitle}>{pageState.extraText}</div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText2} appLanguage={appLanguage} />

        <div className={styles.leftAlignWrapper}>

        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.extraText3}</div>

                {/* Render Small Cards Section */}
                {pageState.smallCardTitle1 && (
          <div className={styles.carouselContainer}>
            <CarouselSmallCard
              title={pageState.smallCardTitle1}
              extraText={pageState.smallCardDescription1}
              icon={pageState.arrowIconDark}
              backgroundColor="#FFCCA7"
              borderColor="#F75712"
              link="/contra-library"
            />
            <CarouselSmallCard
              title={pageState.smallCardTitle2}
              extraText={pageState.smallCardDescription2}
              icon={pageState.arrowIconDark}
              backgroundColor="#FFCCA7"
              borderColor="#F75712"
              link="/abor-after-test"
            />
          </div>
        )}

      </div>
      </div>
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
