import React from "react";
import { RadioGroup, FormControlLabel, Radio, Button, Typography, Container } from "@mui/material";
import markdownRenderer from "../../utils/markdownRenderer";
import styles from "./IntroPages.module.css";

export class OnboardingLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: localStorage.getItem("ayaAppLanguage") || "es-US", // Use local storage to persist selected language
      heading: "",
      text1: "",
      text2: "",
      button: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Onboarding Language";

    this.loadPageContent(this.state.selectedLanguage);
  }

  loadPageContent = (locale) => {
    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const entryId = "6JJgVeV5fN0sg8A2z9MTkY";
    const entry = entries.find((entry) => entry.sys.id === entryId);

    if (entry) {
      const getFieldValue = (field) => entry.fields[field]?.[locale] || entry.fields[field]?.["en"];
      this.setState({
        heading: getFieldValue("heading") || "",
        text1: getFieldValue("text1") || "",
        text2: getFieldValue("text2") || "",
        button: getFieldValue("buttonText") || "",
      });
    } else {
      console.error(`Entry with ID ${entryId} not found.`);
    }
  };

  handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    this.setState({ selectedLanguage }, () => {
      localStorage.setItem("ayaAppLanguage", selectedLanguage); // Persist selection
      this.loadPageContent(selectedLanguage); // Reload page content for the selected language
    });

    this.props.handleLanguageChange?.(selectedLanguage); // Inform parent component if provided
  };

  handleContinue = () => {
    const { selectedLanguage } = this.state;
    if (selectedLanguage) {
      this.props.history.push(`/onboarding-imaya?lang=${selectedLanguage}`);
    } else {
      alert("Please select a language.");
    }
  };
  

  render() {
    return (
      <Container className={styles.centerContent}>
        <Typography variant="h5" gutterBottom>
          {markdownRenderer(this.state.heading, styles.title)}
        </Typography>

        <RadioGroup
          className={styles.radioGroup}
          value={this.state.selectedLanguage}
          onChange={this.handleLanguageChange}
        >
          <FormControlLabel value="es-US" control={<Radio />} label={this.state.text1 || "Español (US)"} />
          <FormControlLabel value="en" control={<Radio />} label={this.state.text2 || "English"} />
        </RadioGroup>

        <Button
          variant="contained"
          color="primary"
          className={styles.whiteButton}
          onClick={this.handleContinue}
        >
          {this.state.button || "Continue"}
        </Button>
      </Container>
    );
  }
}

export default OnboardingLanguage;
