import React from 'react'
import ReactGA from 'react-ga4'
import * as ContentfulMarkdown from 'react-markdown'

import logo from '../../Images/NewLogoIconWhite.svg'


export class EnterPin extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            pinInputOne: '',
            pinInputTwo: '',
            pinInputThree: '',
            pinInputFour: '',
            // pinError: false,
            // pinErrorMessage: '',
            // pinSuccess: false,
        }

        this.pinInputOneRef = React.createRef();
        this.pinInputTwoRef = React.createRef();
        this.pinInputThreeRef = React.createRef();
        this.pinInputFourRef = React.createRef();
    }


    async componentDidMount() {
        ReactGA.send("/enter-pin")

        document.title = "Enter Pin"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        let getTitle = ''

        await (function () {
            const getEntries = localStorage.getItem('ayaAppEntries')
            const entries = JSON.parse(getEntries)

            const getPageEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'enterPin');

            let pageEntries = []
            

            if (getPageEntries && getPageEntries[0].fields) {
                pageEntries = getPageEntries[0].fields

                if (pageEntries.text) {
                    getTitle = pageEntries.text[this.props.appLanguage]
                }
            }
        }).bind(this)()

        this.setState({
            title: getTitle,
        })
    }


    onPinNumChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

        if (this.state.pinInputOne || this.state.pinInputTwo || this.state.pinInputThree || this.state.pinInputFour) {
            this.pinInputOneRef.current.placeholder = ''
            this.pinInputTwoRef.current.placeholder = ''
            this.pinInputThreeRef.current.placeholder = ''
            this.pinInputFourRef.current.placeholder = ''
        } else {
            this.pinInputOneRef.current.placeholder = '1'
            this.pinInputTwoRef.current.placeholder = '2'
            this.pinInputThreeRef.current.placeholder = '3'
            this.pinInputFourRef.current.placeholder = '4'
        }

        // if (this.state.pinError) {
        //     this.setState({ pinError: false })
        // }


        const pin1 = this.state.pinInputOne
        const pin2 = this.state.pinInputTwo
        const pin3 = this.state.pinInputThree
        const pin4 = this.state.pinInputFour

        const pinEntered = `${pin1}${pin2}${pin3}${pin4}`

        if (pin1 === pin2 && pin2 === pin3 && pin3 === pin4) {
            // Go to dummy page
            return window.location.href = 'https://www.vogue.mx';

        } else {
            if (pinEntered.length === 4) {
                // Check if pin entered === pin in local storage
                if (localStorage.getItem('userPin') && localStorage.getItem('userPin') === pinEntered) {
                    sessionStorage.setItem('hasAccess', true);
                    this.props.authenticateUser(true, true)
                    return

                } else if (localStorage.getItem('userPin') && localStorage.getItem('userPin') !== pinEntered) {
                    await this.setState({
                        ...this.state,
                        pinInputOne: '',
                        pinInputTwo: '',
                        pinInputThree: '',
                        pinInputFour: ''
                    })
                    this.pinInputOneRef.current.placeholder = '1'
                    this.pinInputTwoRef.current.placeholder = '2'
                    this.pinInputThreeRef.current.placeholder = '3'
                    this.pinInputFourRef.current.placeholder = '4'

                    this.pinInputOneRef.current.focus()

                    return
                }
            }
        }
    }


    skipPin = () => {
        // ...?
        this.props.history.push('/home');
    }


    render() {
        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 alignCenter">
                <div className='width78Percent backgroundColorDarkPurple landscapeWidth67Percent flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop25 paddingBottom23Percent pagesInnerDiv'>

                    <img src={logo} style={{ width: '40px', height: '40px' }} className='marginBottom40' alt='' />

                    <ContentfulMarkdown children={this.state.title} className='boldFont fontSize18 lineHeight20 colorWhite marginBottom25' allowDangerousHtml={true} />

                    <div className='width100Percent maxWidth275 marginBottom30'>
                        <div className='width100Percent flexRow justifyBetween marginBottom5'>
                            <input
                                ref={this.pinInputOneRef}
                                type='number'
                                name='pinInputOne'
                                value={this.state.pinInputOne}
                                onChange={(e) => this.onPinNumChange(e)}
                                max='1'
                                onInput={(e) => {
                                    if (e.target.value.length > 0) {
                                        e.target.value = e.target.value.slice(0, 1);
                                        this.pinInputTwoRef.current.focus();
                                    } else {
                                        this.pinInputOneRef.current.focus();
                                    }
                                }}
                                placeholder='1'
                                className='pinInputs'
                            />

                            <input
                                ref={this.pinInputTwoRef}
                                type='number'
                                name='pinInputTwo'
                                value={this.state.pinInputTwo}
                                onChange={(e) => this.onPinNumChange(e)}
                                max='1'
                                onInput={(e) => {
                                    if (e.target.value.length > 0) {
                                        e.target.value = e.target.value.slice(0, 1);
                                        this.pinInputThreeRef.current.focus();
                                    } else {
                                        this.pinInputTwoRef.current.focus();
                                    }
                                }}
                                placeholder='2'
                                className='pinInputs'
                            />

                            <input
                                ref={this.pinInputThreeRef}
                                type='number'
                                name='pinInputThree'
                                value={this.state.pinInputThree}
                                onChange={(e) => this.onPinNumChange(e)}
                                max='1'
                                onInput={(e) => {
                                    if (e.target.value.length > 0) {
                                        e.target.value = e.target.value.slice(0, 1);
                                        this.pinInputFourRef.current.focus();
                                    } else {
                                        this.pinInputThreeRef.current.focus();
                                    }
                                }}
                                placeholder='3'
                                className='pinInputs'
                            />

                            <input
                                ref={this.pinInputFourRef}
                                type='number'
                                name='pinInputFour'
                                value={this.state.pinInputFour}
                                onChange={(e) => this.onPinNumChange(e)}
                                max='1'
                                onInput={(e) => {
                                    if (e.target.value.length > 0) {
                                        e.target.value = e.target.value.slice(0, 1);
                                    } else {
                                        this.pinInputFourRef.current.focus();
                                    }
                                }}
                                placeholder='4'
                                className='pinInputs'
                            />
                        </div>

                        {/* {this.state.pinError &&
                                <p className='boldFont fontSize15 lineHeight19 colorPeach4 marginBottom0'>{this.state.pinErrorMessage}</p>
                            } */}
                    </div>

                    {/* <LargeButton
                            largeButtonFunc={this.setPin}
                            text={this.state.btn1Text}
                            textColor={'colorBlue8'}
                            backgroundColor={'backgroundColorPeach3'}
                            marginBottom={'20px'}
                        /> */}
                </div> {/* pagesInnerDiv */}
            </div>
        )
    }
}