import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import BannerCard from '../../Components/BannerCard';
import CallToActionCard from '../../Components/CallToActionCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from '../AbortionWPills/PillAbortion.module.css';

export default function SelfcareFood({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    narrowCardTitle1: '',
    narrowCardTitle2: '',
  
    // Individual Large Cards
    largeCard1: {},

    // Banner Card
    bannerCardDescription1: '',
    bannerCardTitle1: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    heartIcon: '',
    image1: '',
    image2: '',
    pdfUrl: '', // PDF URL placeholder
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Selfcare Food';

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = 'c1qQpOaa9rZa6XVzFvtrb';
    const largeCardEntryId = '53UbuCjpqYV9D5nuGnDSOQ';
    const pdfAssetId = '6km6pvGRzYljDIE9SIEeWR'; // PDF Entry ID

    // Process the entries from Contentful
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1', 
          narrowCardTitle1: 'narrowCardTitle1',
          narrowCardTitle2: 'narrowCardTitle2',
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
        },
      },
    });

    // Process the assets from Contentful, including the PDF
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '2QGLgde5r3vqnFKv9cINQM',
      arrowIconWhite: '5t0SZSeDrtBlIZ3o0Apjcu',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: '5fJMGLk7i6JHzw6ztzvEDH',
      image2: 'y3Rd7KceR170l23wC9ZX9',
      pdfUrl: pdfAssetId, // Fetch PDF from Contentful
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      narrowCardTitle1: processedEntries['abortoConPastillas']?.narrowCardTitle1 || '',
      narrowCardTitle2: processedEntries['abortoConPastillas']?.narrowCardTitle2 || '',

      // Banner Card
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
      bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',

      // Individual Large Cards
      largeCard1: {
        blueTitle: processedEntries['largeCardCollection']?.largeCardTitle1 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription1 || '',
      },

      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
      pdfUrl: processedAssets?.pdfUrl || '', // Ensure PDF URL is retrieved
    });
  }, [appLanguage]);

  return (
<div className={styles.libraryAbortionMenuContainer}>
        <TopNavBarNew backgroundColor="#FFDFE7" />
        {/* Header Section */}
        <div className={styles.headerContainerContraception}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#FFDFE7"
            borderRadius="0px"
          />
        </div>
        <div className={styles.bottomSpace}></div>
        <div className={styles.homePageContainer}>

        {/* Render Individual Large Cards */}
        <FlexibleCard
          blueTitle={pageState.largeCard1.blueTitle}
          titleColor="#4350E6"
          description={pageState.largeCard1.description}
          width="351px"
          borderRadius="10px"
        />

        {/* Second Call To Action Card - Download PDF from Contentful */}
        {pageState.pdfUrl && (
          <a 
            href={pageState.pdfUrl} 
            download
            style={{ textDecoration: "none" }}
          >
            <CallToActionCard
              title={pageState.narrowCardTitle1}
              icon={pageState.arrowIconWhite}
              showIcon={true}
              isLink={true}
              buttonLink="#"
              useCardGradient={true}
              cardGradient="linear-gradient(90deg, #E12541 0%, #F85833 100%)"
              titleColor="#fff"
              width="318px"
              height="43px"
              iconPosition="right"
              borderRadius="10px"
              titlePadding="15px 20px 5px 0"
              titleMargin="0 10px 10px 10px"
              titleFont="Inter"
              titleFontSize="14px"
              titleFontWeight="700"
            />
          </a>
        )}

        {/* Banner Card */}
        <BannerCard 
          title={pageState.bannerCardTitle1} 
          description={pageState.bannerCardDescription1}
          image={pageState.image2}
          gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
          link="/chat-main"
          imageWidth="81px"
          imageHeight="80px"
          imagePosition="top-right"
          imageOffsetY=""
          imageOffsetX=""
          titleMaxWidth="256px"
          descriptionMaxWidth="286px"
          cardWidth="349px"
          cardHeight="88px"
          titleFont="Inter"
          titleFontSize="14px"
          titleFontWeight="700"
          titlePaddingTop="22px"
          descriptionFontSize="13px"
          descriptionFontWeight="400"
        />
      </div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}