import { Link } from 'react-router-dom';
import Slider from "react-slick";
import HeaderCardNew from '../../Components/HeaderCardNew';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import BasicCard from '../../Components/BasicCardComplex'
import styles from './ResourcesPages.module.css'
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

function LegalAssistance(props) {
	const appLanguage = props.appLanguage || localStorage.getItem("language") || "en";

	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageImageOne, setPageImageOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [subTitleOne, setSubTitleOne] = useState('')
	const [subTitleTwo, setSubTitleTwo] = useState('')
	const [cardOneLogo, setCardOneLogo] = useState('')
	const [cardTwoLogo, setCardTwoLogo] = useState('')
	const [headerImage, setHeaderImage] = useState('')
	const [headerTitle, setHeaderTitle] = useState('')
	const [cardOneTitle, setCardOneTitle] = useState('')
	const [cardTwoTitle, setCardTwoTitle] = useState('')
	const [cardOneDescription, setCardOneDescription] = useState('')
	const [cardTwoDescription, setCardTwoDescription] = useState('')
  const [arrowIconOne, setArrowIconOne] = useState('');
  const [phoneIcon, setPhoneIcon] = useState('');
	const [buttonOne, setButtonOne] = useState('')
	const [buttonTwo, setButtonTwo] = useState('')
	const [arrowIconTwo, setArrowIconTwo] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [navButtonOne, setNavButtonOne] = useState('')
	const [navButtonTwo, setNavButtonTwo] = useState('')
	const [navButtonThree, setNavButtonThree] = useState('')
	const [navButtonFour, setNavButtonFour] = useState('')
	const [navButtonFive, setNavButtonFive] = useState('')
	const [navButtonSix, setNavButtonSix] = useState('')

	const location = useLocation();
  const currentPath = location.pathname;

  const items = [
	{ name: navButtonOne, path: "/resources-legalassistance", backgroundColor: "#4350E6", color: "#fff", borderRadius: "20px", width: "232px", height: "24px", padding: "2px" },
	{ name: navButtonTwo, path: "/resources-pillsandclinics", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "132px", height: "24px", padding: "2px"  },
	{ name: navButtonThree, path: "/resources-medicaladvice", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "191px", height: "24px", padding: "2px"   },
	{ name: navButtonFour, path: "/resources-financialassistance", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "138px", height: "24px", padding: "2px" },
	{ name: navButtonFive, path: "/resources-emotionalsupport", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "132px", height: "24px", padding: "2px"  },
	{ name: navButtonSix, path: "/resources-immigrants", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "161px", height: "24px", padding: "2px"  },
	
  ];
	
	  const settings = {
		dots: false,
		infinite: true,
		speed: 200,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
  		centerPadding: '5px', 
		variableWidth: true,
		beforeChange: (current, next) => setSelectedItem(items[next].name),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerPadding: '10px',
			  },
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
				/* centerPadding: '5px', */
			  },
			},
		  ]
	};


	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-legal-assistance");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Legal Assistance";
	
        // this hides the Crisp Chat 
       /*  $crisp.push(["do", "chat:hide"]) */

		// GET ALL ENTRIES 
		const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);


		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesCategory"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "6d9ubiwgxz8dlpp3hSiLKf"
		)

		

		// GET NAVITEM ONE
		let navItem1 = ''
			if (getContent?.length > 0) {
				const navItemOneData = getContent[0].fields;
				navItem1 = navItemOneData.topNavButton1? navItemOneData.topNavButton1[props.appLanguage] : '';
      }
			setNavButtonOne(navItem1);


			
		// GET NAVITEM TWO
		let navItem2 = ''
		if (getContent?.length > 0) {
			const navItemTwoData = getContent[0].fields;
			navItem2 = navItemTwoData.topNavButton2? navItemTwoData.topNavButton2[props.appLanguage] : '';
		}
		setNavButtonTwo(navItem2);



		// GET NAVITEM THREE
		let navItem3 = ''
		if (getContent?.length > 0) {
			const navItemThreeData = getContent[0].fields;
			navItem3 = navItemThreeData.topNavButton3? navItemThreeData.topNavButton3[props.appLanguage] : '';
		}
		setNavButtonThree(navItem3);



		// GET NAVITEM FOUR
		let navItem4 = ''
		if (getContent?.length > 0) {
			const navItemFourData = getContent[0].fields;
			navItem4 = navItemFourData.topNavButton4? navItemFourData.topNavButton4[props.appLanguage] : '';
		}
		setNavButtonFour(navItem4);



		// GET NAVITEM FIVE 
		let navItem5 = ''
		if (getContent?.length > 0) {
			const navItemFiveData = getContent[0].fields;
			navItem5 = navItemFiveData.topNavButton5? navItemFiveData.topNavButton5[props.appLanguage] : '';
		}
		setNavButtonFive(navItem5);



		// GET NAVITEM SIX
		let navItem6 = ''
		if (getContent?.length > 0) {
			const navItemSixData = getContent[0].fields;
			navItem6 = navItemSixData.topNavButton6? navItemSixData.topNavButton6[props.appLanguage] : '';
		}
		setNavButtonSix(navItem6);



		// GET PAGE TITLE 
		let pageTitle = ''
			if (getContent?.length > 0) {
				const pageTitleData = getContent[0].fields;
				pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
      }
			setPageTitleOne(pageTitle);
	

		
		// GET PAGE DESCRIPTION
		let pageDescription = ''
		if (getContent?.length > 0) {
			const pageDescriptionData = getContent[0].fields;
			pageDescription = pageDescriptionData.textSubtitle2? pageDescriptionData.textSubtitle2[props.appLanguage] : '';
		}
		setPageDescriptionOne(pageDescription);



		// GET SUBTITLE ONE text
		let subtitleOneText = ''
		if (getContent?.length > 0) {
			const subTitleOneData = getContent[0].fields;
			subtitleOneText = subTitleOneData.subtitle? subTitleOneData.subtitle[props.appLanguage] : '';
		}
		setSubTitleOne(subtitleOneText);



		// GET SUBTITLE TWO text 
		let subtitleTwo = ''
		if (getContent?.length > 0) {
			const subTitleTwoData = getContent[0].fields;
			subtitleTwo = subTitleTwoData.subtitle2? subTitleTwoData.subtitle2[props.appLanguage] : '';
		}
		setSubTitleTwo(subtitleTwo);

		

		// CARDS
		// GET CARD ONE title
		let cardOneTitleText = ''
		if (getContent?.length > 0) {
			const cardOneTitleData = getContent[0].fields;
			cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[props.appLanguage] : '';
		}
		setCardOneTitle(cardOneTitleText);
	
				

		// GET CARD ONE DESCRIPTION
		let cardOneDescriptionText = ''
		if (getContent?.length > 0) {
			const cardOneDescriptionData = getContent[0].fields;
			cardOneDescriptionText = cardOneDescriptionData.card1Description? cardOneDescriptionData.card1Description[props.appLanguage] : '';
		}
		setCardOneDescription(cardOneDescriptionText);



		// GET CARD ONE button one
		let button1 = ''
		if (getContent?.length > 0) {
			const buttonOneData = getContent[0].fields;
			button1 = buttonOneData.card1Button1? buttonOneData.card1Button1[props.appLanguage] : '';
		}
		setButtonOne(button1);



		// GET CARD ONE button two
		let button2 = ''
		if (getContent?.length > 0) {
			const buttonTwoData = getContent[0].fields;
			button2 = buttonTwoData.card1Button2? buttonTwoData.card1Button2[props.appLanguage] : '';
		}
		setButtonTwo(button2);



		// GET CARD TWO title
		let cardTwoTitleText = ''
		if (getContent?.length > 0) {
			const cardTwoTitleData = getContent[0].fields;
			cardTwoTitleText = cardTwoTitleData.card2Title? cardTwoTitleData.card2Title[props.appLanguage] : '';
		}
		setCardTwoTitle(cardTwoTitleText);



		// GET CARD TWO DESCRIPTION
		let cardTwoDescriptionText = ''
		if (getContent?.length > 0) {
			const cardTwoDescriptionData = getContent[0].fields;
				cardTwoDescriptionText = cardTwoDescriptionData.card2Description? cardTwoDescriptionData.card2Description[props.appLanguage] : '';
			}
		setCardTwoDescription(cardTwoDescriptionText);

				// GET HEADER TITLE
				let headerTitle = ''
				if (getContent?.length > 0) {
					const headerTitleData = getContent[0].fields;
					headerTitle = headerTitleData.headerTitle? headerTitleData.headerTitle[props.appLanguage] : '';
				}
				setHeaderTitle(headerTitle);



		// ASSETS
		// GET MAIN PAGE image
	const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);

    const getImages = assets.filter(
      obj => obj.sys.id === '2wbIHxmflXGf1oaCx1fWjw'
    );

    let imageOne = '';
    if (getImages?.length > 0 && getImages[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageOne = 'https:' + getImages[0].fields.file[props.appLanguage].url;
		}
    setPageImageOne(imageOne)



		// GET CARD logo ONE
		const getCardOneLogo = assets.filter(
      obj => obj.sys.id === 'kh5bYJSzM27ygDMKE8uAy'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoOne = 'https:' + getCardOneLogo[0].fields.file[props.appLanguage].url;
		}
    setCardOneLogo(logoOne)



		// GET CARD logo TWO 
		const getCardTwoLogo = assets.filter(
      obj => obj.sys.id === '7eIVycI7KfoqggJj8nWWGy'
    );

    let logoTwo = '';
    if (getCardTwoLogo?.length > 0 && getCardTwoLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoTwo = 'https:' + getCardTwoLogo[0].fields.file[props.appLanguage].url;
		}
    setCardTwoLogo(logoTwo)
	


		// GET ARROW ICON
		const getArrowIcon = assets.filter(
      obj => obj.sys.id === 'h150pgUHcW5LIJwnFodWZ'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIcon = 'https:' + getArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setArrowIconOne(arrowIcon);



		// GET PHONE ICON
		const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2wFi6weRCSSPpRKvVEjHEW'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
		}
    setPhoneIcon(phoneIconOne);
		


		// GET ARROW ICON TWO 
		const getArrowIconTwo = assets.filter(
      obj => obj.sys.id === 'h150pgUHcW5LIJwnFodWZ'
    );

    let arrowIconTwo = '';
    if (getArrowIconTwo?.length > 0 && getArrowIconTwo[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIconTwo = 'https:' + getArrowIconTwo[0].fields.file[props.appLanguage].url;
		}
    setArrowIconTwo(arrowIconTwo);

				// GET HEADER IMAGE 
				const getHeaderImage = assets.filter(
					obj => obj.sys.id === '134BNLoX94enSRb1DZ6cRj'
				  );
			  
				  let headerImage = '';
				  if (getHeaderImage?.length > 0 && getHeaderImage[0]?.fields?.file?.[props.appLanguage]?.url) {
						  headerImage = 'https:' + getHeaderImage[0].fields.file[props.appLanguage].url;
					  }
				  setHeaderImage(headerImage);


	}, [props.appLanguage])
	// END OF USE EFFECT ^

	
	const language = props.appLanguage;
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://www.reprolegalhelpline.org';
  } else if (language === 'es-US') {
    linkOne = 'https://www.reprolegalhelpline.org/es';
  }

	let linkTwo;
  if (language === 'en') {
    linkTwo = 'https://digitaldefensefund.org';
  } else if (language === 'es-US') {
    linkTwo = 'https://digitaldefensefund.org/espanol';
  }


	return (
		<>

	<TopNavBarNew backgroundColor="#4350E6" breadcrumbColor="#fff" />

	<div className={styles.homeContainerSingleResources}>
	
	<div className={styles.headerContainer}>
					<HeaderCardNew
						  title={headerTitle}
						  image={headerImage}
						  backgroundColor="#4350E6"
						  titleColor="#fff"
						  borderRadius="0px"
						/>
</div>
			
<div className={styles.carouselContainer}>
            <div className={styles.dotsContainer}>
            </div>
				<div className={`custom-dot-container`}>
				<Slider {...settings}>
  {items.map((item, index) => (
    <div key={index} className={`${styles.slideItem}`}>
      <Link
        to={item.path}
        className={`${styles.linkButton} ${styles.customDot} ${item.path === currentPath ? "active" : ""}`}
        style={{
          backgroundColor: item.backgroundColor,
          color: item.color,
          border: "2px solid #4350E6", // Make the border thicker
          borderRadius: item.borderRadius,
          minHeight: "24px", 
          minWidth: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "2px",
          marginBottom: "2px",
          transform: "translate3d(0, 0, 0)", // Forces hardware acceleration
          willChange: "transform", // Optimizes rendering
        }}
      >
        {item.name}
      </Link>
    </div>
  ))}
</Slider>
				</div>
            </div>
			<div className='legalAssistanceHomeContainer paddingTop20' style={{ backgroundColor: 'white' }}>
			
				
				<div className='cardsContainer'>

						<BasicCard
							content={pageDescriptionOne}
							isUpperCard={true}
						/>
					<div className={styles.moreMargin}>
						<div className='legal-assistance-subtitle'>{subTitleOne}</div>
					</div>
					<div>
						<a
							href={linkOne}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							<BasicCard
								image={cardOneLogo}
								title={
									<div style={{ paddingTop: '5px' }}>
										{cardOneTitle}
									</div>
								}
								content={
									<div style={{ paddingBottom: '10px' }}>
										{cardOneDescription}
									</div>
								}
								button1={buttonOne} 
								button2={
									<div>
										<a href="tel:8448682812" style={{ textDecoration: 'none', color: '#051D58'}}>
											{buttonTwo}
										</a>
									</div>
								}
								arrowIconOne={arrowIconOne}
								phoneIcon={phoneIcon}
							/>
						</a>
					</div>


					<div className={styles.moreMargin}>
						<div className='legal-assistance-subtitle' style={{ textAlign: 'left' }}>{subTitleTwo}</div>
					</div>
						<a
						href={linkTwo}
						target="_blank"
						rel="noopener noreferrer"
					>
						<BasicCard
							image={cardTwoLogo}
							title={
								<div style={{ paddingTop: '10px' }}>
									{cardTwoTitle}
								</div>
							}
							content={cardTwoDescription}
							arrow={arrowIconTwo}
						/>
					</a>
					<div className={styles.bottomSpace}></div>
				</div>
				
			</div>
			
			<BottomNavBarNew appLanguage={appLanguage}/>
			</div>
		</>
	);
	
}

export default LegalAssistance