import React from 'react';
import styles from './LanguageSelectionPage.module.css';
import languageIcon from '../../Images/languageIcon.svg';

export class LanguageSelectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: null,
    };
  }

  handleLanguageChange = (event) => {
    this.setState({ selectedLanguage: event.target.value });

    if (window.gtag) {
      window.gtag('event', 'language_selected', {
        selected_language: event.target.value,
      });
    }
  };

  handleEnterClick = () => {
    if (this.state.selectedLanguage) {
      this.props.handleLanguageChange(this.state.selectedLanguage);
      this.props.history.push('/onboarding-imaya');
    } else {
      alert('Please select a language.');
    }
  };

  render() {
    const isButtonDisabled = !this.state.selectedLanguage;
    const buttonText = this.state.selectedLanguage === 'en' ? 'Continue' : 'Continuar';

    return (
      <div className={styles.headerContainer}>
        <img className={styles.logo} src={languageIcon} alt="logo" />
       {/*  <div className={styles.titles}> */}
        <h1 className={styles.heading}>¿Qué idioma prefieres?</h1>
        <h2 className={styles.subheading}>What is your language preference?</h2>
        {/* </div> */}
        <div className={styles.radioContainer}>

        <label className={styles.radioItem}>
            <input
              type="radio"
              id="spanish"
              name="language"
              value="es-US"
              onChange={this.handleLanguageChange}
            />
            <div className={styles.languageContainer}>
            <span className={styles.language1}>Español</span>
            <p className={styles.language2}>Spanish</p>
            </div>
          </label>

          <label className={styles.radioItem}>
            <input
              type="radio"
              id="english"
              name="language"
              value="en"
              onChange={this.handleLanguageChange}
            />
            <div className={styles.languageContainer}>
            <span className={styles.language1}>Inglés</span>
            <p className={styles.language2}>English</p>
            </div>
          </label>

        </div>

        <button
          className={styles.continueButton}
          onClick={this.handleEnterClick}
          disabled={isButtonDisabled}
        >
          {buttonText}
        </button>
      </div>
    );
  }
}