import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { processAssets, processEntries } from '../../utils/contentProcessor';
import styles from './OnboardingTestFailed.module.css';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function OnboardingTestFailed({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: '',
    title2: '',
    extraText: '',
    extraText2: '',
    button1: '',
    button2: '',
    icon: '',
    illustration: '',
    selectedChoices: []
  });

  const history = useHistory();

  useEffect(() => {
    // Fetch Contentful data from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Process entries and assets
    const processedEntries = processEntries(entries, appLanguage, {
      popupPages: {
        entryId: '7jIl7Gs7qaN0uBhy2y0wfo', // same ID you already use
        fieldsMapping: {
          title: 'title',
          title2: 'title2',
          extraText: 'extraText',
          extraText2: 'extraText2',
          button1: 'button1',
          button2: 'button2'
        }
      }
    });

    const processedAssets = processAssets(assets, appLanguage, {
      icon: '509AEqxEJDlZ2wD4F7a2mt',
      illustration: 'y3Rd7KceR170l23wC9ZX9'
    });

    // --- NEW LOGIC: read user-selected data from localStorage
    const allSelected = JSON.parse(localStorage.getItem('userSelectedOptions')) || [];
    // Filter out any items that are "last option" => we only want failing picks
    const failChoices = allSelected
      .filter((choiceObj) => choiceObj.isLastOption !== true)
      .map((choiceObj) => choiceObj.optionText);

    // Set the state with the processed data + failing choices
    setPageState((prevState) => ({
      ...prevState,
      title: processedEntries?.popupPages?.title || '',
      title2: processedEntries?.popupPages?.title2 || '',
      extraText: processedEntries?.popupPages?.extraText || '',
      extraText2: processedEntries?.popupPages?.extraText2 || '',
      button1: processedEntries?.popupPages?.button1 || '',
      button2: processedEntries?.popupPages?.button2 || '',
      icon: processedAssets?.icon || '',
      illustration: processedAssets?.illustration || '',

      // Store the final array of strings to display
      selectedChoices: failChoices
    }));
  }, [appLanguage]);

  return (
    <>
    
      <TopNavBarNew backgroundColor="#fff" breadcrumbColor="#333" />
      <div className={styles.pageContainer}>
      {/* Title */}
      <div className={styles.title}>{pageState.title}</div>

      {/* Icon */}
      <img src={pageState.icon} alt="Warning Icon" className={styles.icon} />

      {/* Title2 */}
      <div className={styles.title2}>{pageState.title2}</div>

      {/* Selected Choices Box */}
      <div className={styles.choicesBox}>
        <div className={styles.extraText}>{pageState.extraText}</div>
        <div className={styles.choicesList}>
          {pageState.selectedChoices.map((choice, index) => (
            <div key={index} className={styles.choiceCard}>
              <div className={styles.choiceText}>{choice}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Illustration */}
      <img
        src={pageState.illustration}
        alt="Illustration"
        className={styles.illustration}
      />

      {/* Extra Text2 */}
      <div className={styles.extraText2}>{pageState.extraText2}</div>

      {/* Buttons */}
      <button
        className={styles.button1}
        onClick={() => history.push('/chat-main')}
      >
        {pageState.button1}
      </button>
      <button
        className={styles.button2}
        onClick={() => history.push('/home')}
      >
        {pageState.button2}
      </button>

      <div className={styles.extraSpaceTop}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}