import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import styles from "./SelfcareNutritionPDF.module.css";
import TopNavBarNew from "../../Components/TopNavBarNew";
/* import BottomNavBarNew from "../../Components/BottomNavBarNew"; */
import { processAssets } from "../../utils/contentProcessor";

export default function SelfcareNutritionPDF({ appLanguage }) {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    ReactGA.send("pageview", "/selfcare-nutrition-pdf");
    window.scrollTo(0, 0);
    document.title = "Selfcare Nutrition PDF";

    // Get assets from localStorage
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets"));
    
    // Ensure assets exist before proceeding
    if (!assets) {
      console.error("❌ No assets found in localStorage");
      return;
    }

    // Process assets using the same function as in SelfcareFood.js
    const pdfAssetId = "6km6pvGRzYljDIE9SIEeWR";
    const processedAssets = processAssets(assets, appLanguage, { pdfUrl: pdfAssetId });

    // Set PDF URL
    if (processedAssets.pdfUrl) {
      console.log("✅ Successfully retrieved PDF URL:", processedAssets.pdfUrl);
      setPdfUrl(processedAssets.pdfUrl.startsWith("http") ? processedAssets.pdfUrl : `https:${processedAssets.pdfUrl}`);
    } else {
      console.error("❌ Failed to retrieve PDF URL from processed assets.");
    }
  }, [appLanguage]);

  return (
    <>
      <TopNavBarNew backgroundColor="#fff" breadcrumbColor="#333" />
      <div className={styles.background}>
        <div className={styles.whiteCard}>
          <h2>Self-Care Nutrition Guide</h2>
          {pdfUrl ? (
            <iframe
              src={pdfUrl}
              width="100%"
              height="600px"
              style={{ border: "none" }}
              title="Self-Care Nutrition Guide"
            />
          ) : (
            <p style={{ color: "red" }}>Failed to load PDF</p>
          )}
        </div>
      </div>
      {/* <BottomNavBarNew appLanguage={appLanguage}/> */}
    </>
  );
}