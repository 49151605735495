import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { processEntries, processAssets } from '../../utils/contentProcessor';
/* import CustomButton from '../../Components/CustomButton'; */
import styles from './IntroPage3.module.css';

export default function IntroPage3({ appLanguage }) {
  const history = useHistory();

  const [pageState, setPageState] = useState({
    description: '',
    longText: '',
    button1: '',
    image: '',
    image2: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Onboarding Care Team';

    if (localStorage.getItem("hasSeenIntro")) {
      history.replace("/home");
      return;
    }

    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    const assetsMapping = {
      image: "4h52N6qOrvgHFykAbrb9vo", 
      image2: "2gBdo7rvzXUteYvan0bkJI",
    };

    const entryId = "5zBlm1X451ETCosDpMY0xV";

    const processedEntries = processEntries(entries, appLanguage, {
      'introPage3': {
        entryId: entryId,
        fieldsMapping: {
          longText: 'longText',
          description: 'description',
          button1: 'button1',
        }
      },
    });

    const processedAssets = processAssets(assets, appLanguage, assetsMapping);

    setPageState({
      longText: processedEntries['introPage3'].longText || '',
      description: processedEntries['introPage3'].description || '',
      button1: processedEntries['introPage3'].button1 || '',
      image: processedAssets.image || '',
      image2: processedAssets.image2 || '',
    });

    localStorage.setItem("hasSeenIntro", "true");

  }, [appLanguage, history]);

  const goToNextPage = () => {
    history.push('/onboarding-privacy');
  };

  return (
    <div id="scroll">
      <div className={styles.centerContent}>

        {/* ✅ Bilingual Title Logic */}
        {appLanguage === "es-US" ? (
          <h1 className={styles.title}>
            Contamos con un{' '}
            <span style={{ color: '#4350E6', fontWeight: 'bold' }}>Equipo de Apoyo</span>{' '}
            personalizado
          </h1>
        ) : (
          <h1 className={styles.title}>
            We have a dedicated{' '}
            <span style={{ color: '#4350E6', fontWeight: 'bold' }}>Support Team</span>
          </h1>
        )}

        {/* ✅ Description from Contentful */}
        <p className={styles.description}>{pageState.description}</p>
        <img src={pageState.image} alt="Support Team" className={styles.image} />

        <div className={styles.chatInfoSection}>
          <img src={pageState.image2} alt="Support Team" className={styles.icon} />
          <p className={styles.description2}>{pageState.longText}</p>
        </div>

        <button 
          onClick={goToNextPage} 
          className={styles.blueButton}
        >
          {pageState.button1}
        </button>
      </div>
    </div>
  );
}