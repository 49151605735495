import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './ChatCrisp.module.css';

export default function ChatCrisp({ appLanguage }) {
  const [chatClosed, setChatClosed] = useState(false);
  const [pageState, setPageState] = useState({ image1: '' });

  const history = useHistory();

  // Translations
  const translations = {
    en: {
      subTitle: "Do you want to keep chatting?",
      btnChat: "Go back to the in-app chat",
      btnWhatsApp: "Talk to us on WhatsApp"
    },
    "es-US": {
      subTitle: "¿Quieres seguir chateando?",
      btnChat: "Volver al chat de la app",
      btnWhatsApp: "Háblanos por WhatsApp"
    }
  };

  const { subTitle, btnChat, btnWhatsApp } = translations[appLanguage] || translations["en"];

  useEffect(() => {
    if (window.$crisp) {
      console.log("Crisp chat initialized...");
      window.$crisp.push(['do', 'chat:show']); 
      window.$crisp.push(['do', 'chat:open']); 

      // ✅ Hide Crisp when chat is closed
      window.$crisp.push(["on", "chat:closed", () => {
        setChatClosed(true);
        window.$crisp.push(['do', 'chat:hide']);
      }]);
    }

    return () => {
      if (window.$crisp) {
        window.$crisp.push(['do', 'chat:hide']);
      }
    };
  }, []);

  useEffect(() => {
    try {
      // ✅ Handle localStorage gracefully (for incognito mode)
      const assets = JSON.parse(localStorage.getItem('ayaAppAssets')) || {};

      const processedAssets = processAssets(assets, appLanguage, {
        image1: '4rqnkzV2uPRN3I5QxG5UQy',
      });

      setPageState({
        image1: processedAssets?.image1 || '',
      });
    } catch (error) {
      console.warn("localStorage is not available in incognito mode:", error);
      setPageState({ image1: '' }); // Provide a fallback
    }
  }, [appLanguage]);

  return (
    <div className={styles.homePageContainer}>
      {!chatClosed ? (
        <div className={styles.chatOverlay}>
          {/* Crisp chat opens immediately */}
        </div>
      ) : (
        <>
        <div className={styles.homePageContainer}>
          <TopNavBarNew backgroundColor="#DCE0FF" breadcrumbColor="#333" />
          <div className={styles.extraSpace2}></div>
          <HeaderCardNew
            title="Chat"
            image={pageState.image1}
            backgroundColor="#DCE0FF"
            titleColor="#303030"
            borderRadius="0px"
          />

          <div className={styles.subTitle}>
            <p>{subTitle}</p>
          </div>
          <div className={styles.buttonContainer}>
            {/* ✅ Ensures reload works even in incognito */}
            <button
  className={styles.button1}
  onClick={() => {
    window.location.replace(window.location.href);
  }}
>
  {btnChat}
</button>

            <button
              className={styles.button2}
              onClick={() => window.open('https://wa.me/+12062783105', '_blank')}
            >
              {btnWhatsApp}
            </button>
          </div>

          <div className={styles.bottomSpace}></div>
          <BottomNavBarNew appLanguage={appLanguage}/>
        </div>
        </>
      )}
    </div>
  );
}