import React from 'react';
import styles from './LargeImageCard.module.css';

export default function LargeImageCard({ title, image, arrowIcon, link, backgroundColor }) {
  return (
    <div 
      className={styles.largeCardContainer} 
      onClick={() => window.location.href = link}
      style={{ backgroundColor: backgroundColor || 'transparent' }} // Default to transparent if no color is provided
    >
      <div className={styles.cardContent}>
        <div className={styles.cardTitle}>{title}</div>
      </div>
      <img src={image} alt={title} className={styles.cardImage} />
      <img src={arrowIcon} alt="Arrow" className={styles.arrowIcon} />
    </div>
  );
}