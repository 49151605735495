import React from 'react';
import TopNavBarNew from '../../Components/TopNavBarNew';
import BottomNavBarNew from '../../Components/BottomNavBarNew';
import styles from './ContraTestQuestions.module.css';

export class ContraTestAnswersWithYesNo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            questionYesSelected: false,
            questionNoSelected: false,
            appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en", // Ensure appLanguage is always available
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */
    }

    componentDidUpdate(prevProps) {
        if (prevProps.appLanguage !== this.props.appLanguage) {
            this.setState({
                appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en"
            });
        }
    }

    toggleRadio = (currentSelectedStateName, otherStateName) => {
        this.setState({
            [currentSelectedStateName]: true,
            [otherStateName]: false
        });
    };

    continue = () => {
        if (this.state.questionYesSelected) {
            this.props.continue('yes');
        } else if (this.state.questionNoSelected) {
            this.props.continue('no');
        }
    };

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { content } = this.props;
        const { appLanguage } = this.state;

        return (
            <>
            <div className={styles.pageContainer}>
                <TopNavBarNew backgroundColor="#4450E5" breadcrumbColor="#fff"/>
                <div className={styles.topSection}>
                <p className={styles.pageTitle}>{content.pageTitle}</p>
                    <p className={styles.stepNumber}>{content.stepNumber}</p>
                </div>

                <div className={styles.whiteCard}>
                    <h1 className={styles.title}>{content.question}</h1>
                    <form className={styles.optionContainer}>
                        <div
                            className={`${styles.optionRow} ${this.state.questionYesSelected ? styles.choiceSelected : ''}`}
                            onClick={() => this.toggleRadio('questionYesSelected', 'questionNoSelected')}
                        >
                            <span>{content.yesOptionText}</span>
                        </div>

                        <div
                            className={`${styles.optionRow} ${this.state.questionNoSelected ? styles.choiceSelected : ''}`}
                            onClick={() => this.toggleRadio('questionNoSelected', 'questionYesSelected')}
                        >
                            <span>{content.noOptionText}</span>
                        </div>
                    </form>

                    <button
                        className={`${styles.continueButton} ${this.state.questionYesSelected || this.state.questionNoSelected ? styles.continueButtonActive : ''}`}
                        onClick={this.continue}
                    >
                        {content.btn1Text}
                    </button>
                </div>
                <div className={styles.bottomSpace}></div>

                {/* Pass appLanguage safely to BottomNavBarNew */}
                <BottomNavBarNew appLanguage={appLanguage}/>
            </div>
            </>
        );
    }
}

export default ContraTestAnswersWithYesNo;