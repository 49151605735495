// ContraTestResultCard.js
import React, { Component } from 'react';
import styles from './ContraTestResultCard.module.css';
import { Link } from 'react-router-dom'
import arrowIcon from '../../Images/BlueArrowNew.svg'; 
// Adjust the import path based on your folder structure

class ContraTestResultCard extends Component {
  render() {
    // This "methodName" or "title" could come from props
    const { content } = this.props;

    return (
        <Link to={content.goToPageLink} className={styles.resultCard}>
            <p className={styles.methodName}>{content.title}</p>
            <img src={arrowIcon} alt="Enter Button" className={styles.icon} />
        </Link>
    )
}
}

export default ContraTestResultCard;