import React, { useEffect, useState } from "react";
import { processAssets } from "../utils/contentProcessor"; // Assuming contentProcessor.js is located in ../utils
import styles from "./PopUpEligibility.module.css";

export default function PopUpEligibility({
  show,
  title,
  paragraph,
  buttonText,
  onClose,
  appLanguage,
}) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    // Load the image using the same logic as Onboarding2NewFUMTips
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets"));

    const processedAssets = processAssets(assets, appLanguage, {
      image1: "3KztPVcLQnzoqXk6MdvpBK",
    });

    setImageUrl(processedAssets?.image1 || "");
  }, [appLanguage]);

  if (!show) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
      {imageUrl && <img src={imageUrl} alt="Popup Illustration" className={styles.image} />}
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{paragraph}</p>
        <button className={styles.button} onClick={onClose}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}