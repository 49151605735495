import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import BasicAccordionRounded from '../../Components/BasicAccordionRounded';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import FeedbackCard from '../../Components/FeedbackCard';
import BannerCardWithLinkEsVE from "../../Components/BannerCardWithLinkEsVE";
import BannerCard from '../../Components/BannerCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from '../AbortionWPills/PillAbortion.module.css';

export default function EmergencyPills({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    pageTitle: '',
    extraText: '',
    extraText2: '',
    extraText3: '',
    largeCardTitle9: '',

    // Individual Large Cards
    largeCard1: {},
    largeCard2: {},
    largeCard3: {},
    largeCard4: {},
    largeCard5: {},
    largeCard6: {},
    largeCard7: {},

    // Accordions

    accordions: [],

    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',

    // Banner Card
    bannerCardTitle1: '',
    bannerCardDescription1: '',
    bannerCardImage: '',

    // Call to Action Card
    bannerCardDescription2: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
    image2: '',
  });

   const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Emergency Pills';
    
    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = '5GcvJhmeE8t6CeUPM0bESF';
    const largeCardEntryId = '5TtQTQdUowScjNQInVbDZd';

    // Process the entries from Contentful
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardDescription2: 'bannerCardDescription2',
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
          largeCardDescription4: 'largeCardDescription4',
          largeCardTitle5: 'largeCardTitle5',
          largeCardDescription5: 'largeCardDescription5',
          largeCardTitle6: 'largeCardTitle6',
          largeCardDescription6: 'largeCardDescription6',
          largeCardTitle7: 'largeCardTitle7',
          largeCardDescription7: 'largeCardDescription7',
          largeCardTitle8: 'largeCardTitle8',
          largeCardDescription8: 'largeCardDescription8',
          largeCardTitle9: 'largeCardTitle9',
        },
      }      
    });

        // Create accordion data
        const accordions = [
          {
            title: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
            description: processedEntries['largeCardCollection']?.largeCardDescription2 || '',
          },
          {
            title: processedEntries['largeCardCollection']?.largeCardTitle3 || '',
            description: processedEntries['largeCardCollection']?.largeCardDescription3 || '',
          },
          {
            title: processedEntries['largeCardCollection']?.largeCardTitle4 || '',
            description: processedEntries['largeCardCollection']?.largeCardDescription4 || '',
          },
        ];

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '4PRStO660MxXni9z6sY8j8',
      arrowIconWhite: '5t0SZSeDrtBlIZ3o0Apjcu',
      arrowIconDark: 'h150pgUHcW5LIJwnFodWZ',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: '70YCCVQKYte0NzyiPpxCyn', // Flexible card image
      image2: 'y3Rd7KceR170l23wC9ZX9', // Banner Card Image
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      pageTitle: processedEntries['abortoConPastillas']?.pageTitle || '',
      extraText: processedEntries['abortoConPastillas']?.extraText || '',
      extraText2: processedEntries['abortoConPastillas']?.extraText2 || '',
      extraText3: processedEntries['abortoConPastillas']?.extraText3 || '',

      // Banner Card
      bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
      bannerCardImage: processedAssets?.image2 || '',

      // Call To Action Card
      bannerCardDescription2: processedEntries['abortoConPastillas']?.bannerCardDescription2 || '',

      // Small Cards Section
      smallCardTitle1: processedEntries['abortoConPastillas']?.smallCardTitle1 || '',
      smallCardDescription1: processedEntries['abortoConPastillas']?.smallCardDescription1 || '',

      //Accordions

      accordions,

      // Individual Large Cards
      largeCard1: {
        ovalText: processedEntries.largeCardCollection?.largeCardTitle9 || '',
        blueTitle: processedEntries.largeCardCollection?.largeCardTitle1 || '',
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle8 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription1 || '',
        images: [{ url: processedAssets.image1 || '' }],
      },
      largeCard2: {
        title: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription2 || '',
      },

      largeCard3: {
        title: processedEntries['largeCardCollection']?.largeCardTitle3 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription3 || '',
      },
      largeCard4: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle5 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription5 || '',
      },
      largeCard5: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle6 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription6 || '',
      },
      largeCard6: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle7 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription7 || '',
      },
      largeCard7: {
        description: processedEntries['largeCardCollection']?.largeCardDescription8 || '',
      },

      // Carousel Cards
      carouselCard1: {
        title: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription2 || '',
      },
      carouselCard2: {
        title: processedEntries['largeCardCollection']?.largeCardTitle3 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription3 || '',
      },

      // Assets
      headerImage: processedAssets.headerImage || '',
      arrowIconWhite: processedAssets.arrowIconWhite || '',
      arrowIconDark: processedAssets.arrowIconDark || '',
      heartIcon: processedAssets.heartIcon || '',
      image1: processedAssets.image1 || '',
      image2: processedAssets.image2 || '',

    });
  }, [appLanguage]);

  return (
    
      <div className={styles.libraryAbortionMenuContainer}>
        <TopNavBarNew backgroundColor="#E3F4FF" breadcrumbColor="#333" />
        
        {/* Header Section */}
        <div className={styles.headerContainerContraception}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#E3F4FF"
            borderRadius="0px"
          />
        </div>
        <div className={styles.bottomSpace}></div>
        <div className={styles.homePageContainer}>
        
        {/* First Card (Flexible) */}
        <FlexibleCard
          blueTitle={pageState.largeCard1.blueTitle}
          blackTitle={pageState.largeCard1.blackTitle}
          description={pageState.largeCard1.description}
          images={pageState.largeCard1.images}
          blackTitleBottom={true}
          imagePosition="below-blue-title"
          imageWidth="150px"
          blackTitleFontSize="13px"
          showOval={true}
          ovalText={pageState.largeCard1.ovalText}
          width="351px"
          borderRadius="10px"
        />


        {/* Accordions */}
        <div className={styles.accordionWrapper}>
          {pageState.accordions.map((accordion, index) => (
            <BasicAccordionRounded
              key={index}
              heading={accordion.title}
              content={accordion.description}
              expanded={expanded === index}
              onChange={(event, isExpanded) => setExpanded(isExpanded ? index : false)}
            />
          ))}
        </div>

        <FlexibleCard
            blackTitle={pageState.largeCard4.blackTitle}
            description={pageState.largeCard4.description}
            width="351px"
            borderRadius="10px"
        />

        <FlexibleCard
            blackTitle={pageState.largeCard5.blackTitle}
            description={pageState.largeCard5.description}
            width="351px"
            borderRadius="10px"
        />

        <FlexibleCard
            blackTitle={pageState.largeCard6.blackTitle}
            description={pageState.largeCard6.description}
            width="351px"
            borderRadius="10px"
        />

          {/* Banner Card */}
          <BannerCard 
          title={pageState.bannerCardTitle1} 
          description={pageState.bannerCardDescription1}
          link="/chat-main"
          image={pageState.bannerCardImage}
          backgroundColor="#4450E5"
          borderRadius="10px"
        />

        <div className={styles.moreSpace}></div>

{/* Condom Link Card */}
<BannerCardWithLinkEsVE
  linkPath="/contra-barrier-condom" // ✅ Internal link (same for both languages)
  descriptionEn="There is no protection against STIs with this method. Remember to use a condom to protect yourself."
  descriptionEs="No hay protección contra las ITS con este método. Recuerda usar un condón para protegerte."
/>

        <FlexibleCard
            description={pageState.largeCard7.description}
            width="351px"
            borderRadius="10px"
        />

        {/* Extra Text Section */}
        <div className={styles.extraTitle}>{pageState.extraText}</div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText2} appLanguage={appLanguage} />

        <div className={styles.leftAlignWrapper}>

        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.extraText3}</div>

                {/* Render Small Cards Section */}
                {pageState.smallCardTitle1 && (
          <div className={styles.carouselContainer}>
           <CarouselSmallCard
                         title={pageState.smallCardTitle1}
                         extraText={pageState.smallCardDescription1}
                         icon={pageState.arrowIconDark}
                         backgroundColor="#E3F4FF"
                        borderColor="#89D0FE"
                        link="/contra-emergency-long-copperiud"
                       />
                     </div>
                   )}
           
                 </div>
                 </div>
                 <div className={styles.bottomSpace}></div>
                 <BottomNavBarNew appLanguage={appLanguage}/>
               </div>
             );
           }