import React, { Component } from "react";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import { processAssets } from "../../utils/contentProcessor";
import styles from "./AboutVitalaMore.module.css";

export class AboutVitalaMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: {},
      assets: {},
      appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en", // Ensure appLanguage is always available
    };
  }

  componentDidMount() {
    this.loadPageData();
  }

  // 1) If the parent changes appLanguage after mount, re-load data
  componentDidUpdate(prevProps) {
    if (prevProps.appLanguage !== this.props.appLanguage) {
      this.setState(
        { appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en" },
        this.loadPageData
      );
    }
  }

  // 2) Extracted code to a helper
  loadPageData = () => {
    const { appLanguage } = this.state;

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets")) || [];

    // Find the content entry for this page
    const pageEntry = entries.find(
      (obj) => obj.sys.id === "67K4qbhgyIRYW2hzISadej"
    );

    let pageContent = {};
    if (pageEntry?.fields) {
      // Safely pick the field in the correct locale,
      // with fallback to 'en' or any other fallback
      const headerTitle =
        pageEntry.fields.headerTitle?.[appLanguage] ||
        pageEntry.fields.headerTitle?.["en"] ||
        "Sobre Vitala";

      const description =
        pageEntry.fields.largeCardDescription1?.[appLanguage] ||
        pageEntry.fields.largeCardDescription1?.["en"] ||
        "";

      const buttonText =
        pageEntry.fields.largeCardTitle1?.[appLanguage] ||
        pageEntry.fields.largeCardTitle1?.["en"] ||
        "";

      const buttonLink = pageEntry.fields.buttonLink || "";

      pageContent = {
        title: headerTitle,
        description,
        buttonText,
        buttonLink,
      };
    } else {
      // Fallback if the entry or fields are missing
      pageContent = {
        title: "Sobre Vitala", // or "About Vitala" - up to you
        description: "",
        buttonText: "",
        buttonLink: "",
      };
    }

    // Assets
    const assetsMapping = {
      logo: "4vVHGW2Z81N0j575SCLf06",
      arrowIcon: "h150pgUHcW5LIJwnFodWZ",
    };
    const processedAssets = processAssets(assets, appLanguage, assetsMapping);

    this.setState({ pageContent, assets: processedAssets });
  };

  render() {
    const { pageContent, assets, appLanguage } = this.state;

    return (
      <div className={styles.container}>
        {/* Top Navbar with Title */}
        <TopNavBarNew pageTitle={pageContent.title} />

        <div className={styles.extraSpace}></div>

        {/* Logo */}
        {assets.logo && (
          <img
            src={assets.logo}
            alt="Vitala Logo"
            className={styles.logo}
          />
        )}

        <div className={styles.extraSpace}></div>

        {/* White Card with Blue Card Inside */}
        <div className={styles.card}>
          <p className={styles.cardText}>{pageContent.description}</p>
          <a
            href={pageContent.buttonLink || "https://www.vitalaglobal.org/"}
            className={styles.blueCard}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.blueCardText}>{pageContent.buttonText}</span>
            {assets.arrowIcon && (
              <img src={assets.arrowIcon} alt="Arrow" className={styles.arrowIcon} />
            )}
          </a>
        </div>

        {/* Bottom Nav Bar with appLanguage */}
        <BottomNavBarNew appLanguage={appLanguage} />
      </div>
    );
  }
}

export default AboutVitalaMore;