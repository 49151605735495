import React from 'react';
import styles from './HeaderCardNew.module.css';

export default function HeaderCardNew({
  title,
  image,
  backgroundColor = '#FDE8D8',
  borderRadius = '8px',
  titleColor = '#333',
  imageSize = '100%',
  imageBottom = '0', // Default bottom position
  imageRight = '0', // Default right position
  imageTransform = 'translate(0, 0)', // Default transform
}) {
  return (
    <div className={styles.headerCard} style={{ backgroundColor, borderRadius }}>
      <div
        className={styles.headerImageWrapper}
        style={{
          bottom: imageBottom,
          right: imageRight,
        }}
      >
        <img
          src={image}
          alt={title}
          className={styles.headerImage}
          style={{ width: imageSize, transform: imageTransform }}
        />
      </div>
      <div className={styles.headerTitle} style={{ color: titleColor }}>
        {title}
      </div>
    </div>
  );
}