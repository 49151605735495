import React, { useState } from 'react';
import styles from './ContraTestQuestions3.module.css';
import PopUpEligibility from '../../Components/PopUpEligibility';
import TopNavBarNew from '../../Components/TopNavBarNew';
import BottomNavBarNew from '../../Components/BottomNavBarNew';
import questionmarkIcon from "../../Images/questionmark.svg";

export default function ContraTestAnswersWithOptions({ 
  content, 
  continue: continueFunc, 
  infoPopups = {} 
}) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [openPopup, setOpenPopup] = useState(null);
  const appLanguage = content.appLanguage || localStorage.getItem("language") || "en"; // Ensure appLanguage is available

  const toggleCheckbox = (option) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [option]: !prevState[option]
    }));
  };

  const handlePopupOpen = (popupKey) => {
    setOpenPopup(popupKey);
  };

  const handlePopupClose = () => {
    setOpenPopup(null);
  };

  return (
    <div className={styles.pageContainer}>
      <TopNavBarNew backgroundColor="#4450E5" breadcrumbColor="#fff"/>
      <div className={styles.topSection}>
      <p className={styles.pageTitle}>{content.pageTitle}</p>
        <p className={styles.stepNumber}>{content.stepNumber}</p>
      </div>

      <div className={styles.whiteCard}>
          <h1 className={styles.title}>{content.question}</h1>
              <form className={styles.optionContainer}>
              {content.options.map((option, i) => (
                <div key={i} className={styles.optionRow}>
                  {/* ✅ If it's the last option, style it as a non-clickable text */}
                  {i === content.options.length - 1 ? (
                    <div className={styles.infoText}>
                      {option.option}
                    </div>
                  ) : (
                    <div className={styles.choiceContainer}>
                      <div 
                        className={`${styles.choice} ${selectedOptions[option.stateName] ? styles.choiceSelected : ''}`} 
                        onClick={() => toggleCheckbox(option.stateName)}
                      >
                        <span>{option.option}</span>
                      </div>
                    </div>
                  )}

                    {/* ✅ Keep the question mark icon in its original position */}
                    {infoPopups[option.stateName] && (
                      <div className={styles.iconContainer}>
                        <img 
                          src={questionmarkIcon} 
                          alt="Info" 
                          className={styles.icon} 
                          onClick={() => handlePopupOpen(option.stateName)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </form>

              {/* ✅ The button is now always active */}
              <button 
                className={`${styles.continueButton} ${styles.continueButtonActive}`} 
                onClick={() => continueFunc(Object.keys(selectedOptions).filter(key => selectedOptions[key]).length)}
              >
                {content.btn1Text}
              </button>
      </div>

      {openPopup && infoPopups[openPopup] && (
        <PopUpEligibility
          show={true}
          title={infoPopups[openPopup].title}
          paragraph={infoPopups[openPopup].paragraph}
          buttonText="OK"
          onClose={handlePopupClose}
          appLanguage={appLanguage}
        />
      )}
      <div className={styles.bottomSpace}></div>

      {/* Pass appLanguage safely to BottomNavBarNew */}
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}