import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CarouselSmallCard.module.css';

export default function CarouselSmallCard({
  title,
  extraText,
  icon,
  backgroundColor = '#FFF5ED',
  borderColor = '#FEBF90',
  borderRadius = '8px',
  link = '#',
}) {
  return (
    <Link 
      to={link} 
      className={styles.carouselSmallCardLink} 
      style={{ textDecoration: 'none' }}
    >
      <div
        className={styles.carouselSmallCard}
        style={{ 
          backgroundColor, 
          borderColor, 
          borderRadius, 
          border: `1px solid ${borderColor}` 
        }}
      >
        <div className={styles.cardContent}>
          <div className={styles.cardTitle}>{title}</div>
          
          {/* Updated row layout for extra text & icon */}
          <div className={styles.rowLayout}>
            <div className={styles.extraText}>{extraText}</div>
            <div className={styles.cardIconWrapper}>
              <img src={icon} alt="arrow icon" className={styles.cardIcon} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}