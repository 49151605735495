import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { processEntries, processAssets } from '../../utils/contentProcessor';
/* import CustomButton from '../../Components/CustomButton'; */
import IconTextElement from '../../Components/IconTextElement';
import styles from './IntroPage2.module.css';

const LOCALES = {
  en: "en",
  esUS: "es-US",
  esVE: "es-VE",
};

export default function IntroPage2({ appLanguage }) {
  const [pageState, setPageState] = useState({
    titleLong: '',
    button: '',
    icon1: '',
    description: '',
    icon2: '',
    description2: '',
    icon3: '',
    description3: '',
    icon4: '',
    description4: '',
    icon5: '',
    description5: '',
  });

  const history = useHistory();

  useEffect(() => {
    const storedLanguage = mapLocale(localStorage.getItem('ayaAppLanguage') || appLanguage);
    localStorage.setItem("ayaAppLanguage", storedLanguage);

    loadPageContent(storedLanguage);
  }, [appLanguage]);

  const mapLocale = (language) => {
    switch (language) {
      case "en":
        return LOCALES.en;
      case "es-US":
        return LOCALES.esUS;
      case "es-VE":
      default:
        return LOCALES.esVE;
    }
  };


  const loadPageContent = (locale) => {
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    if (!entries || !assets) {
      console.error("Entries or assets are missing from localStorage.");
      return;
    }

    const entryId = "4rZ6nk0PBHcRXX8I1n8xBX";
    const assetsMapping = {
      icon1: "HOeIZKML9npvNMmW7yH5c",
      icon2: "4CJmgJoiv05sFzb0b4MrZX",
      icon3: "2vuMo27OHQue2kVtgMOeR9",
      icon4: "7e8Uv97JKTm5RpdBdRC8M8",
      icon5: "1EBXFvF9PT7sjrLigVlpz4",
    };

    const processedEntries = processEntries(entries, locale, {
      introPage: {
        entryId,
        fieldsMapping: {
          titleLong: 'titleLong', 
          button: 'button',
          description: 'description',
          description2: 'description2',
          description3: 'description3',
          description4: 'description4',
          description5: 'description5',
        }
      },
    });

    const processedAssets = processAssets(assets, locale, assetsMapping);

    setPageState({
      titleLong: processedEntries.introPage?.titleLong || '',
      button: processedEntries.introPage?.button || '',
      icon1: processedAssets.icon1 || '',
      description: processedEntries.introPage?.description || '',
      icon2: processedAssets.icon2 || '',
      description2: processedEntries.introPage?.description2 || '',
      icon3: processedAssets.icon3 || '',
      description3: processedEntries.introPage?.description3 || '',
      icon4: processedAssets.icon4 || '',
      description4: processedEntries.introPage?.description4 || '',
      icon5: processedAssets.icon5,
      description5: processedEntries['introPage']?.description5 || '',
    });
  };

  const goToNextPage = () => {
    history.push('/onboarding-careteam');
  };

  return (
    <div className={`${styles.flexColumn} ${styles.growFullHeight} ${styles.pagesInnerDiv}`} id="scroll" style={{ overflowY: 'auto', minHeight: '100vh' }}>
      <div className={styles.centerContent}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: pageState.titleLong }} />
        <IconTextElement className={styles.description} iconUrl={pageState.icon1} text={pageState.description} />
        {/* <IconTextElement className={styles.description} iconUrl={pageState.icon2} text={pageState.description2} /> */}
        <IconTextElement className={styles.description} iconUrl={pageState.icon3} text={pageState.description3} />
        <IconTextElement className={styles.description} iconUrl={pageState.icon4} text={pageState.description4} />
        <IconTextElement className={styles.description} iconUrl={pageState.icon5} text={pageState.description5} />
        <div className={styles.extraSpace}></div>
        <button className={styles.blueButton} onClick={goToNextPage}>
          {pageState.button}
        </button>
      </div>
    </div>
  );
}