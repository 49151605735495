import React from 'react';
import styles from './CallToActionCard.module.css';

export default function CallToActionCard({
  title,
  description,
  leftIcon,
  icon,
  buttonText,
  buttonLink,
  mailto, // 📌 New prop for email links
  isLink = true,
  disableCursor = false,
  useCardGradient = false,
  cardColor,
  cardGradient = 'linear-gradient(90deg, #E12541 0%, #F85833 100%)',
  borderColor,
  titleColor = '#333',
  descriptionColor = '#666',
  buttonGradient = 'linear-gradient(90deg, #E12541 0%, #F85833 100%)',
  showIcon = true,
  iconPosition = 'right',
  width = '356px',
  height = 'auto',
  borderRadius = '10px',
  boxShadow,
  titlePadding = '0',
  titleMargin = '0',
  titleFont = `'GT Walsheim Pro', sans-serif`,
  titleFontSize = '18px',
  titleFontWeight = '700',
  descriptionFont = `'Inter', sans-serif`,
  descriptionFontSize = '14px',
  descriptionFontWeight = '400',
}) {
  const cardStyle = {
    background: useCardGradient ? cardGradient : cardColor || '#FFF4E1',
    width: width,
    height: height,
    borderRadius: borderRadius,
    border: borderColor ? `2px solid ${borderColor}` : 'none',
    boxShadow: boxShadow || '0px 4px 10px rgba(0, 0, 0, 0.1)',
    cursor: isLink || mailto ? 'pointer' : disableCursor ? 'default' : 'inherit',
    textDecoration: 'none', // Ensure no underlining for links
  };

  const renderCardContent = () => (
    <div
      className={styles.cardContent}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      {leftIcon && (
        <div className={styles.leftIconContainer}>
          <img src={leftIcon} alt="Left Icon" className={styles.cardLeftIcon} />
        </div>
      )}

      <div className={styles.textContainer}>
        <h3
          className={styles.cardTitle}
          style={{
            color: titleColor,
            fontFamily: titleFont,
            fontSize: titleFontSize,
            fontWeight: titleFontWeight,
            padding: titlePadding,
            margin: titleMargin,
          }}
        >
          {title}
        </h3>
        {description && (
          <p
            className={styles.cardDescription}
            style={{
              color: descriptionColor,
              fontFamily: descriptionFont,
              fontSize: descriptionFontSize,
              fontWeight: descriptionFontWeight,
            }}
          >
            {description}
          </p>
        )}
      </div>

      {showIcon && icon && (
        <div className={styles.rightIconContainer}>
          <img src={icon} alt="Right Icon" className={styles.cardRightIcon} />
        </div>
      )}
    </div>
  );

  return mailto ? (
    <a href={`mailto:${mailto}`} className={styles.callToActionCard} style={cardStyle}>
      {renderCardContent()}
    </a>
  ) : isLink && buttonLink ? (
    <div
      className={styles.callToActionCard}
      style={cardStyle}
      onClick={() => (window.location.href = buttonLink)}
    >
      {renderCardContent()}
    </div>
  ) : (
    <div className={styles.callToActionCard} style={cardStyle}>
      {renderCardContent()}
    </div>
  );
}