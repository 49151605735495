import React from "react";
import styles from "./MenuCard.module.css";

// Importing local SVG icon
import MenuCardArrow from "../Images/MenuCardArrow.svg";

export default function MenuCard({ title, path, className }) {
  return (
    <div className={`${styles.menuCard} ${className}`} onClick={() => window.location.href = path}>
      <span className={styles.title}>{title}</span>
      <img src={MenuCardArrow} alt="Arrow" className={styles.menuArrow} />
    </div>
  );
}