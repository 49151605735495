import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

function CustomButton({ text, onClick, variant = "contained", className, style }) {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      className={className}
      style={{
        borderRadius: 20,
        boxShadow: 'none',
        width: '100%', // Set to 100% to make it responsive based on container
        ...style, // Inline styles passed via props
      }}
    >
      {text}
    </Button>
  );
}

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CustomButton;
