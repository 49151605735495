import React, { useState } from "react";
/**
 * IMPORTANT for DayPicker v7:
 *  - Use the *default import* from "react-day-picker"
 *  - Use "react-day-picker/lib/style.css"
 */
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

import "./MyCalendar.css";

import leftArrowImg from "../Images/CalendarArrowIconLeft.svg";
import rightArrowImg from "../Images/CalendarArrowIconRight.svg";

export default function MyCalendar({ onDateSelected }) {
  const [selectedDay, setSelectedDay] = useState(null);
  const [highlightToday, setHighlightToday] = useState(true);

  const isSameDay = (d1, d2) =>
    d1?.getDate() === d2?.getDate() &&
    d1?.getMonth() === d2?.getMonth() &&
    d1?.getFullYear() === d2?.getFullYear();

  const today = new Date();

  /**
   * Called when the user clicks a day in the calendar
   */
  const handleDayClick = (day, modifiers) => {
    setSelectedDay(day);
    setHighlightToday(false);
    onDateSelected?.(day);
  };

  /**
   * Custom nav bar component to show your left/right arrows
   */
  function MyCustomNavBar({
    onPreviousClick,
    onNextClick,
    showPreviousButton,
    showNextButton,
    month
  }) {
    const monthNames = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const label = `${monthNames[month.getMonth()]} ${month.getFullYear()}`;

    return (
      <div className="myCalendarNavBar">
        {showPreviousButton && (
          <button
            type="button"
            className="navButton leftArrow"
            onClick={() => {
              if (typeof onPreviousClick === "function") {
                onPreviousClick();
              } else {
                console.error("onPreviousClick is not a function.");
              }
            }}
          >
            <img src={leftArrowImg} alt="Prev" />
          </button>
        )}

        <div className="monthYearLabel">{label}</div>

        {showNextButton && (
          <button
            type="button"
            className="navButton rightArrow"
            onClick={() => {
              if (typeof onNextClick === "function") {
                onNextClick();
              } else {
                console.error("onNextClick is not a function.");
              }
            }}
          >
            <img src={rightArrowImg} alt="Next" />
          </button>
        )}
      </div>
    );
  }

  /**
   * We define a custom "highlightToday" modifier:
   * If highlightToday is true *and* it's today's date => highlight in blue circle
   */
  const modifiers = {
    highlightToday: (day) => highlightToday && isSameDay(day, today),
  };

  // The style for that highlight circle
  const modifiersStyles = {
    highlightToday: {
      backgroundColor: "#4350E6",
      color: "#fff",
      borderRadius: "50%",
    }
  };

  return (
    <DayPicker
      // Hooks up day clicks
      onDayClick={handleDayClick}
      // Remembers the selected day
      selectedDays={selectedDay}
      canChangeMonth // allows month navigation
      firstDayOfWeek={1} // Monday start
      months={[
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ]}
      weekdaysShort={["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"]}
      // Hide default "Month Year" caption so we only see our custom nav bar
      captionElement={() => null}
      // Pass the nav bar props via arrow function:
      navbarElement={(navbarProps) => <MyCustomNavBar {...navbarProps} />}
      className="myCustomCalendar"
      // Our custom highlight logic
       modifiers={modifiers}
      modifiersStyles={modifiersStyles}
    />
  );
}
