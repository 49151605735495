import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { processAssets, processEntries } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import BasicAccordionRounded from '../../Components/BasicAccordionRounded';
/* import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew"; */
import styles from './Chat.module.css';

export default function ChatMenu({ appLanguage }) {
  const history = useHistory();

  const [pageState, setPageState] = useState({
    headerTitle: '',
    subTitle1: '',
    subTitle2: '',
    subTitle3: '',
    subTitle4: '',
    button1: '',
    button2: '',
    image1: '',
    accordions: [],
  });

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Chat Main';

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const largeCardEntryId = '7JS3r1Xf7siPY0nwNmgvJl';

    // Process entries
    const processedEntries = processEntries(entries, appLanguage, {
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          subTitle1: 'subTitle1',
          subTitle2: 'subTitle2',
          subTitle3: 'subTitle3',
          subTitle4: 'subTitle4',
          button1: 'button1',
          button2: 'button2',
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
        },
      },
    });

    // Process assets
    const processedAssets = processAssets(assets, appLanguage, {
      image1: '4rqnkzV2uPRN3I5QxG5UQy',
    });

    // Create accordion data
    const accordions = [
      {
        title: processedEntries['largeCardCollection']?.largeCardTitle1 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription1 || '',
      },
      {
        title: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription2 || '',
      },
    ];

    setPageState({
      headerTitle: processedEntries['largeCardCollection']?.headerTitle || '',
      subTitle1: processedEntries['largeCardCollection']?.subTitle1 || '',
      subTitle2: processedEntries['largeCardCollection']?.subTitle2 || '',
      subTitle3: processedEntries['largeCardCollection']?.subTitle3 || '',
      subTitle4: processedEntries['largeCardCollection']?.subTitle4 || '',
      button1: processedEntries['largeCardCollection']?.button1 || 'Chat Support',
      button2: processedEntries['largeCardCollection']?.button2 || 'WhatsApp',
      image1: processedAssets?.image1 || '',
      accordions,
    });
  }, [appLanguage]);

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.chatMenuContainer}>
              {/* <TopNavBarNew backgroundColor="#DCE0FF" breadcrumbColor="#333" /> */}
        {/* Header Section */}
        {/* <div className={styles.extraSpace2}></div> */}
        <div className={styles.headerContainer}>
        <HeaderCardNew
          title={pageState.headerTitle}
          image={pageState.image1}
          backgroundColor="#DCE0FF"
          titleColor="#303030"
          borderRadius="0px"
        />
        </div>
        {/* Subtitle */}
        <div className={styles.leftAlignWrapper}>
          <div className={styles.extraTitletwo}>{pageState.subTitle1}</div>
        </div>

        {/* Accordions */}
        <div className={styles.accordionWrapper}>
          {pageState.accordions.map((accordion, index) => (
            <BasicAccordionRounded
              key={index}
              heading={accordion.title}
              content={accordion.description}
              expanded={expanded === index}
              onChange={(event, isExpanded) => setExpanded(isExpanded ? index : false)}
            />
          ))}
        </div>

        {/* Subtitle 2 */}
        <div className={styles.extraTitle}>{pageState.subTitle2}</div>

        {/* Buttons */}
        <button
            className={styles.button1}
            onClick={() => window.open('https://wa.me/+12062783105', '_blank')}
          >
            {pageState.subTitle3}
          </button>

        <button
          className={styles.button2}
          onClick={() => history.push('/chat-internal')}
        >
          {pageState.subTitle4}
        </button>
      </div>
      <div className={styles.bottomSpace}></div>
      {/* <BottomNavBarNew /> */}
    </div>
  );
}