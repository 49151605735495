import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import RealStoriesCard from '../../Components/RealStoriesCard';
import FeedbackCard from '../../Components/FeedbackCard';
import BannerCard from '../../Components/BannerCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './RealStories.module.css';

export default function RealStoriesThree({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    extraText: '',
    extraText2: '',

    // Individual Large Cards
    largeCard1: {},

    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',
    smallCardTitle2: '',
    smallCardDescription2: '',
    smallCardTitle3: '',
    smallCardDescription3: '',

        // Banner Card
        bannerCardDescription1: '',
        bannerCardTitle1: '',


    // Assets
    headerImage: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Real Stories Three';
    
    //path: /real-stories-three

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = '2fSaMrQFU5UNyCuIaBIHyN';
    const largeCardEntryId = '2mO2L8bAN4FnsjpLlwW8p3';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          extraText: 'extraText',
          extraText2: 'extraText2',
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          smallCardTitle2: 'smallCardTitle2',
          smallCardTitle3: 'smallCardTitle3',
          smallCardTitle4: 'smallCardTitle4',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1',
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
        largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '2QqrfQvbM7wiJKDebz76V2',
      arrowIconDark: '35s9SZBtTGy9KGs0lc41lt',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: 'y3Rd7KceR170l23wC9ZX9',
      image2: 'vOXgrGXEMSZUSsfvgBHl9',
      image3: '5J3wSsAw8I1n2G15MO9dwz',
      image4: '347Kd8twz9RPwSicZyEJwF',
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      extraText: processedEntries['abortoConPastillas']?.extraText || '',
      extraText2: processedEntries['abortoConPastillas']?.extraText2 || '',
      narrowCardTitle1: processedEntries['abortoConPastillas']?.narrowCardTitle1 || '',
      narrowCardTitle2: processedEntries['abortoConPastillas']?.narrowCardTitle2 || '',

            // Banner Card
            bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
            bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',


      // Small Cards Section
      smallCardTitle1: processedEntries['abortoConPastillas']?.smallCardTitle1 || '',
      smallCardDescription1: processedEntries['abortoConPastillas']?.smallCardDescription1 || '',
      smallCardTitle2: processedEntries['abortoConPastillas']?.smallCardTitle2 || '',
      smallCardDescription2: processedEntries['abortoConPastillas']?.smallCardDescription2 || '',
      smallCardTitle3: processedEntries['abortoConPastillas']?.smallCardTitle3 || '',
      smallCardDescription3: processedEntries['abortoConPastillas']?.smallCardDescription3 || '',
      smallCardTitle4: processedEntries['abortoConPastillas']?.smallCardTitle4 || '',
      smallCardDescription4: processedEntries['abortoConPastillas']?.smallCardDescription4 || '',

      // Individual Large Cards
      largeCard1: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle1 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription1 || '',
      },

      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
      image3: processedAssets?.image3 || '',
      image4: processedAssets?.image4 || '',
      image5: processedAssets?.image5 || '',
    });
  }, [appLanguage]);

  return (
    <div className={styles.homePageContainer}>
      <TopNavBarNew backgroundColor="#4350E6" breadcrumbColor="#fff" />
      <div className={styles.libraryAbortionMenuContainer}>
        {/* Header Section */}
        <div className={styles.headerContainer}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#4350E6"
            borderRadius="0px"
            titleColor="#fff"
          />
        </div>
        <div className={styles.bottomSpaceHeader}></div>

        {/* Render Individual Large Cards */}
        <FlexibleCard
        blackTitle={pageState.largeCard1.blackTitle}
        titleColor="#4350E6"
          description={pageState.largeCard1.description}
          width="351px"
          borderRadius="10px"
        />

        <BannerCard 
        title={pageState.bannerCardTitle1} 
        description={pageState.bannerCardDescription1}
        image={pageState.image1}
        gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
        link="/chat-main"
        imageWidth="81px"
        imageHeight="80px"
        imagePosition="top-right"
        imageOffsetY=""
        imageOffsetX=""
        titleMaxWidth="256px"
        descriptionMaxWidth="286px"
        cardWidth="349px"
        cardHeight="88px"
        />

        <div className={styles.bottomSpaceHeader}></div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText} appLanguage={appLanguage} />


        <div className={styles.bottomSpaceHeader}></div>

        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitleTwo}>{pageState.extraText2}</div>
        </div>

        <div className={styles.realStoriesContainer}>
      <RealStoriesCard
        title={pageState.smallCardTitle1}
        isLink={true}
        buttonLink="/real-story-one"
        description={pageState.smallCardDescription1}
        image={pageState.image2}
        icon={pageState.arrowIconDark}  // Arrow icon path or use pageState.arrowIcon
        backgroundColor="#FDE8D8"
      />
      <RealStoriesCard
        title={pageState.smallCardTitle2}
        isLink={true}
        buttonLink="/real-story-two"
        description={pageState.smallCardDescription1}
        image={pageState.image3}
        icon={pageState.arrowIconDark}   // Arrow icon path or use pageState.arrowIcon
        backgroundColor="#FFE6EE"
      />
      <RealStoriesCard
        title={pageState.smallCardTitle3}
        isLink={true}
        buttonLink="/real-story-four"
        description={pageState.smallCardDescription1}
        image={pageState.image4}
        icon={pageState.arrowIconDark}   // Arrow icon path or use pageState.arrowIcon
        backgroundColor="#DAF0FF"
      />
    </div>

      </div>
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
