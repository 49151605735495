import React from 'react';
import ReactGA from 'react-ga4';
import { ContraTestAnswersWithYesYesNoMaybe } from './ContraTestAnswersWithYesYesNoMaybe'; // Import updated YesYesNoMaybe component
/* import styles from './ContraTestQuestions.module.css'; */

export class ContraTestQ3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 3,
      contentObj: {},
    };
  }

  componentDidMount() {
    ReactGA.send("/contra-test-questionthree");
    window.scrollTo(0, 0);
    document.title = "Contraception Test Q3";

    // Retrieve data from localStorage
    const getEntries = JSON.parse(localStorage.getItem('ayaAppEntries')) || [];
    const getContentEntries = getEntries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
    const getPageEntries = getContentEntries.find(obj => obj.sys.id === '58p1TRpqQxH7DOUC9B2Kdf');

    if (getPageEntries) {
      const fields = getPageEntries.fields;
      this.setState({
        contentObj: {
          pageTitle: fields.pageTitle?.[this.props.appLanguage] || '',
          stepNumber: fields.stepNumber?.[this.props.appLanguage] || '',
          question: fields.title?.[this.props.appLanguage] || '',
          yesOptionText: fields.choice1?.[this.props.appLanguage] || '',
          secondYesOptionText: fields.choice2?.[this.props.appLanguage] || '',
          noOptionText: fields.choice3?.[this.props.appLanguage] || '',
          maybeOptionText: fields.choice4?.[this.props.appLanguage] || '',
          btn1Text: fields.ctaCopy1?.[this.props.appLanguage] || '',
        }
      });
    }
  }

  continue = (answer) => {
    window.localStorage.setItem('contraTestQ3Answer', answer);
    this.props.history.push('/contra-test-questionfour'); // ✅ Move to the next question
  };

  goBackFunc = () => {
    this.props.history.goBack();
  };

  render() {
    const { contentObj } = this.state;

    return (
      <>
        <div>
          <ContraTestAnswersWithYesYesNoMaybe
            appLanguage={this.props.appLanguage}
            history={this.props.history}
            content={contentObj}
            questionWidth={'width83Percent'}
            continue={this.continue}
            goBack={this.goBackFunc}
          />
        </div>
      </>
    );
  }
}