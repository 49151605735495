import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";  // Import useHistory for navigation
import { processEntries, processAssets } from "../../utils/contentProcessor";
import styles from "./OnboardingTestSuccess.module.css";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function OnboardingTestSuccess({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: "",
    title2: "",
    buttonText: "",
    image: "",
  });

  const history = useHistory();  // Initialize history for navigation

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Eligibility Success";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets"));

    const entryId = "5vZOpioRPmrIcA3CC9KFhu";
    const imageAssetId = "7E9b9VruHMnOt8HcuEtroN";

    const processedEntries = processEntries(entries, appLanguage, {
      abortionFlow: {
        entryId,
        fieldsMapping: {
          title: "title",
          title2: "title2",
          buttonText: "buttonText",
        },
      },
    });

    const processedAssets = processAssets(assets, appLanguage, {
      image: imageAssetId,
    });

    setPageState({
      title: processedEntries.abortionFlow?.title || "",
      title2: processedEntries.abortionFlow?.title2 || "",
      buttonText: processedEntries.abortionFlow?.buttonText || "",
      image: processedAssets?.image || "",
    });
  }, [appLanguage]);

  return (
      
      <div className={styles.background}>
      <TopNavBarNew backgroundColor="#4450E5" breadcrumbColor="#fff" />
        <h1 className={styles.title}>{pageState.title}</h1>
        <h2 className={styles.title2}>{pageState.title2}</h2>
        {pageState.image && (
          <div className={styles.imageContainer}>
            <img
              src={pageState.image}
              alt="Success Illustration"
              className={styles.image}
            />
          </div>
        )}

      <button 
        className={styles.button} 
        onClick={() => history.push("/abor-before-whatpillsdoineed")} // Replace with actual path
      >
        {pageState.buttonText}
      </button>

      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
      </div>
  );
}