import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { processAssets, processEntries } from '../../utils/contentProcessor';
import styles from './PillAbortionTestFailed.module.css';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function PillAbortionTestFailed({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: '',
    title2: '',
    extraText: '',
    button1: '',
    button2: '',
    icon: '',
    illustration: '',
    selectedChoices: []
  });

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Abor | Test | Result';

    // path: /abor-aftertest-negative


    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));



    // Process entries and assets
    const processedEntries = processEntries(entries, appLanguage, {
      popupPages: {
        entryId: 'WDXw4rYHNRUDKZ7KFCZt6', 
        fieldsMapping: {
          title: 'title',
          title2: 'title2',
          extraText: 'extraText',
          button1: 'button1',
          button2: 'button2'
        }
      }
    });

    const processedAssets = processAssets(assets, appLanguage, {
      icon: '509AEqxEJDlZ2wD4F7a2mt',
      illustration: 'y3Rd7KceR170l23wC9ZX9'
    });


    setPageState((prevState) => ({
      ...prevState,
      title: processedEntries?.popupPages?.title || '',
      title2: processedEntries?.popupPages?.title2 || '',
      extraText: processedEntries?.popupPages?.extraText || '',
      button1: processedEntries?.popupPages?.button1 || '',
      button2: processedEntries?.popupPages?.button2 || '',
      icon: processedAssets?.icon || '',
      illustration: processedAssets?.illustration || '',
    }));
  }, [appLanguage]);

  return (
    
    <div className={styles.pageContainer}>
    <TopNavBarNew backgroundColor="#fff" breadcrumbColor="#333" />
    
      {/* Title */}
      <div className={styles.title}>{pageState.title}</div>

      {/* Icon */}
      <img src={pageState.icon} alt="Warning Icon" className={styles.icon} />

      {/* Title2 */}
      <div className={styles.title2}>{pageState.title2}</div>

      {/* Illustration */}
      <img
        src={pageState.illustration}
        alt="Illustration"
        className={styles.illustration}
      />

      {/* Extra Text2 */}
      <div className={styles.extraText2}>{pageState.extraText}</div>

      {/* Buttons */}
      <button
        className={styles.button1}
        onClick={() => history.push('/chat-main')}
      >
        {pageState.button1}
      </button>
      <button
        className={styles.button2}
        onClick={() => history.push('/home')}
      >
        {pageState.button2}
      </button>
      <div className={styles.extraSpace}></div>
      <div className={styles.bottomSpace}></div>
      
      <BottomNavBarNew appLanguage={appLanguage}/>
      </div>
    
  );
}