// import logo from './logo.svg';
import React from "react";
import client from "./Service/Client"; // Contentful
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import "./App.css";
/* import '@ionic/react/css/core.css'; */

import { SafeAreaController } from "@aashu-dubey/capacitor-statusbar-safe-area";

/* import ChatManager from './Components/ChatManager' */

import TopNavBarNew from "./Components/TopNavBarNew";
import BottomNavBarNew from "./Components/BottomNavBarNew";

import { EnterPin } from "./Pages/EnterPin/EnterPin";
import HomePageNew from "./Pages/Home/HomePageNew";


//REDESIGN 2025

//ONBOARDING

import SplashPage from "./Pages/SplashPage/SplashPage"
import { LanguageSelectionPage } from "./Pages/SplashPage/LanguageSelectionPage";
import IntroPage1 from "./Pages/SplashPage/IntroPage1";
import IntroPage2 from "./Pages/SplashPage/IntroPage2";
import IntroPage3 from "./Pages/SplashPage/IntroPage3";
import { IntroPage5Privacy } from "./Pages/SplashPage/IntroPage5Privacy";
import IntroPageSurvey from "./Pages/SplashPage/IntroPageSurvey";
import { PrivacyPolicyPage } from "./Pages/SplashPage/PrivacyPolicyPage";
import { TermsOfUsePage } from "./Pages/SplashPage/TermsOfUsePage";



//NEW ONBOARDING ABORTION EILIGIBILITY TEST

import Onboarding1New from './Pages/Onboarding/Onboarding1New';
import OnboardingLanguage from './Pages/SplashPage/OnboardingLanguage';
import Onboarding2New from './Pages/Onboarding/Onboarding2New';
import Onboarding2NewFUMTips from './Pages/Onboarding/Onboarding2NewFUMTips';
import Onboarding2NewEco from './Pages/Onboarding/Onboarding2NewEco';
import Onboarding3New from './Pages/Onboarding/Onboarding3New';
import Onboarding4New from './Pages/Onboarding/Onboarding4New';
import Onboarding5New from './Pages/Onboarding/Onboarding5New';
import OnboardingTestSuccess from './Pages/Onboarding/OnboardingTestSuccess';
import OnboardingTestFailed from './Pages/Onboarding/OnboardingTestFailed';
import OnboardingUnder12Weeks from './Pages/Onboarding/OnboardingUnder12Weeks';
import OnboardingOver12Weeks from './Pages/Onboarding/OnboardingOver12Weeks';

import LibraryAbortionMenu from "./Pages/LibraryAbortion/LibraryAbortionMenu";
import LibraryContraceptionMenu from "./Pages/LibraryContraception/LibraryContraceptionMenu";

import BirthControlPills from "./Pages/LibraryContraception/BirthControlPills";
import IUD from "./Pages/LibraryContraception/IUD";
import SubdermanImplant from "./Pages/LibraryContraception/SubdermalImplant";
import ContraceptiveInjection from "./Pages/LibraryContraception/ContraceptiveInjection";
import EmergencyPills from "./Pages/LibraryContraception/EmergencyPills";
import CopperIntrauterineDevice from "./Pages/LibraryContraception/CopperIntrauterineDevice";
import Condoms from "./Pages/LibraryContraception/Condoms";
import TubalLitigation from "./Pages/LibraryContraception/TubalLitigation";
import Vasectomy from "./Pages/LibraryContraception/Vasectomy";
import MethodsFertilityKnowledge from "./Pages/LibraryContraception/MethodsFetilityKnowledge";
import CoitusInterruptus from "./Pages/LibraryContraception/CoitusInterruptus";
import VaginalRing from "./Pages/LibraryContraception/VaginalRing";
import HormonalPatch from "./Pages/LibraryContraception/HormonalPatch";
import CervicalCap from "./Pages/LibraryContraception/CervicalCap";
import Diafragma from "./Pages/LibraryContraception/Diafragma";


import PillAbortionBeforeOne from "./Pages/AbortionWPills/PillAbortionBeforeOne";
import PillAbortionBeforeTwo from "./Pages/AbortionWPills/PillAbortionBeforeTwo";
import PillAbortionBeforeThree from "./Pages/AbortionWPills/PillAbortionBeforeThree";
import PillAbortionDuringOne from "./Pages/AbortionWPills/PillAbortionDuringOne";
import PillAbortionDuringTwo from "./Pages/AbortionWPills/PillAbortionDuringTwo";
import PillAbortionDuringThree from "./Pages/AbortionWPills/PillAbortionDuringThree";
import PillAbortionAfterOneTest from "./Pages/AbortionWPills/PillAbortionAfterOneTest";
import PillAbortionTestFailed from "./Pages/AbortionWPills/PillAbortionTestFailed";
import PillAbortionTestSuccess from "./Pages/AbortionWPills/PillAbortionTestSuccess";
import PillAbortionAfterTwo from "./Pages/AbortionWPills/PillAbortionAfterTwo";


// Redesign FAQs, Real Stores, Myths and Realities Pages


import Selfcare from "./Pages/Selfcare/Selfcare";
import SelfcareConnection from "./Pages/Selfcare/SelfcareConnection"
import SelfcareFood from "./Pages/Selfcare/SelfcareFood"
import SelfcareExpression from "./Pages/Selfcare/SelfcareExpression"
import SelfcareAttention from "./Pages/Selfcare/SelfcareAttention"
import SelfcareReflection from "./Pages/Selfcare/SelfcareReflection"
import SelfcareNutritionPDF from "./Pages/Selfcare/SelfcareNutritionPDF"


import RealStoriesLibrary from "./Pages/RealStories/RealStoriesLibrary";
import RealStoriesOne from "./Pages/RealStories/RealStoriesOne";
import RealStoriesTwo from "./Pages/RealStories/RealStoriesTwo";
import RealStoriesThree from "./Pages/RealStories/RealStoriesThree";
import RealStoriesFour from "./Pages/RealStories/RealStoriesFour";

import MythsAndRealities from "./Pages/MythsAndRealities/MythsAndRealities";

import FAQs from "./Pages/FAQ/FAQs";

// Chat Pages

import ChatMenu from "./Pages/Chat/ChatMenu";
import ChatCrisp from "./Pages/Chat/ChatCrisp";

//More Pages

import MoreMenu from "./Pages/More/MoreMenu";
import { AboutVitalaMore } from "./Pages/More/AboutVitalaMore";
import { PINSetUp } from "./Pages/More/PINSetUp";
import { PINSetUpSuccess } from "./Pages/More/PINSetUpSuccess";
import { TermsOfUseMore } from "./Pages/More/TermsOfUseMore";
import { PrivacyPolicyMore } from "./Pages/More/PrivacyPolicyMore";
import LanguageChange from "./Pages/More/LanguageChange";
/* import RateAya from "./Pages/More/RateAya"; */


// RESOURCES
 import ResourcesHome from "./Pages/Resources/ResourcesHome";
 import EmotionalSupport from "./Pages/Resources/EmotionalSupport";
 import FinancialHelp from "./Pages/Resources/FinancialHelp";
 import ImmiSupport from "./Pages/Resources/ImmiSupport";
 import LegalAssistance from "./Pages/Resources/LegalAssistance";
 import MedicalAdvice from "./Pages/Resources/MedicalAdvice";
 import PillsAndClinics from "./Pages/Resources/PillsAndClinics";

 // Contraception Test

 import { ContraTestQ1 } from "./Pages/ContraceptionTest/ContraTestQ1";
import { ContraTestQ2 } from "./Pages/ContraceptionTest/ContraTestQ2";
import { ContraTestQ3 } from "./Pages/ContraceptionTest/ContraTestQ3";
import { ContraTestQ4 } from "./Pages/ContraceptionTest/ContraTestQ4";
import { ContraTestQ5 } from "./Pages/ContraceptionTest/ContraTestQ5";
import { ContraTestQ6 } from "./Pages/ContraceptionTest/ContraTestQ6";
import { ContraTestQ7 }  from "./Pages/ContraceptionTest/ContraTestQ7";
import { ContraTestQ8 } from "./Pages/ContraceptionTest/ContraTestQ8";
import { ContraTestQ9 } from "./Pages/ContraceptionTest/ContraTestQ9";
import { ContraTestResult } from "./Pages/ContraceptionTest/ContraTestResult"



import ReactGA from "react-ga4";

//this is for the multi-language feature of the app. It will check the url for the language parameter and set the language accordingly. If no language is specified, it will default to es-VE

ReactGA.initialize("G-F6TWXP1ZRG");

// If you want to read ?lang= from the URL
function getLanguageFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("lang");
}

function Layout({
  children,
  showTopNav = true,
  showBottomNav = true,
  topNavProps = {},
  bottomNavProps = {},
}) {
  return (
    <div className="appWrapper">
      {showTopNav && (
        <div className="topNavBarContainer">
          <TopNavBarNew {...topNavProps} />
        </div>
      )}

      <div className="appContent">{children}</div>

      {showBottomNav && (
        <div className="bottomNavBarContainer">
          <BottomNavBarNew {...bottomNavProps} />
        </div>
      )}
    </div>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false, // Will become true after client.sync finishes
      appLanguage: "es-US", // Default to "es-US"
      authenticate: { hasPin: false, hasAccess: true },
      firstTimeUser: true,
    };

    // Binds
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
  }

  // ========== (A) handleLanguageChange: store language + sync again ========== //
  handleLanguageChange(newLanguage) {
    if (newLanguage !== this.state.appLanguage) {
      // 1) Update localStorage
      localStorage.setItem("language", newLanguage);

      // 2) Set state (trigger componentDidUpdate → client.sync)
      this.setState({ appLanguage: newLanguage, dataLoaded: false });

      // 3) Update the URL ?lang=
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("lang", newLanguage);
      window.history.pushState({}, "", currentUrl.toString());
    }
  }

  // ========== (B) In componentDidMount, decide initial language & authenticate ========== //
  componentDidMount() {
    SafeAreaController.injectCSSVariables();

    // 1) Attempt reading from URL or localStorage
    const languageFromLocalStorage = localStorage.getItem("language");
    const languageFromUrl = getLanguageFromUrl();
    const finalLanguage = languageFromUrl || languageFromLocalStorage || "es-US";

    // 2) Store in localStorage & state
    localStorage.setItem("language", finalLanguage);
    this.setState({ appLanguage: finalLanguage });

    // 3) GA events
    ReactGA.set({ Language: finalLanguage });
    ReactGA.event({
      category: "Language Selection",
      action: "Select",
      label: finalLanguage,
    });

    // 4) Auth checks (like your existing code)
    if (localStorage.getItem("userPin")) {
      if (sessionStorage.getItem("hasAccess")) {
        this.authenticateUser(true, true);
      } else {
        this.authenticateUser(true, false);
      }
    } else {
      this.authenticateUser(false, true);
    }
    // (No direct sync here because we do it in componentDidUpdate after authenticate.)
  }

  // ========== (C) componentDidUpdate: if language changed or auth changed, sync ========== //
  async componentDidUpdate(prevProps, prevState) {
    // 1) If the language changed, we want to sync new data
    if (this.state.appLanguage !== prevState.appLanguage) {
      this.syncContentfulData(this.state.appLanguage);
    }

    // 2) If the user’s authentication changed
    if (this.state.authenticate !== prevState.authenticate) {
      if (this.state.authenticate.hasAccess === true) {
        this.syncContentfulData(this.state.appLanguage);
      }
    }
  }

  // ========== (D) syncContentfulData: The old live approach ========== //
  async syncContentfulData(selectedLocale) {
    // You can do a quick check for offline
    if (!window.navigator.onLine) {
      // if offline but we have old data, set dataLoaded to true
      if (localStorage.getItem("ayaAppEntries") && localStorage.getItem("ayaAppAssets")) {
        this.setState({ dataLoaded: true });
      } else {
        alert("App needs wifi to download initial data");
      }
      return;
    }

    try {
      // 1) Use client.sync with the chosen locale
      const getContent = await client.sync({
        initial: true, // or false if not a first sync
        resolveLinks: false,
        locale: selectedLocale, // "es-US" or "en", etc.
      });

      // 2) Parse the response
      const responseObj = JSON.parse(getContent.stringifySafe());
      const entries = responseObj.entries;
      const assets = responseObj.assets;

      // 3) Store in localStorage so each page can read them
      try {
        localStorage.setItem("ayaAppEntries", JSON.stringify(entries));
        localStorage.setItem("ayaAppAssets", JSON.stringify(assets));
      } catch (err) {
        console.warn("⚠️ localStorage write failed — possibly incognito mode", err);
      }

      // 4) Mark data as loaded
      this.setState({ dataLoaded: true });

      // 5) Cache assets using the Cache API
      const cache = await caches.open("contentful-cache");
      for (const asset of assets) {
        const file = asset.fields.file?.[selectedLocale] || asset.fields.file?.["en"];
        if (file?.url) {
          const url = "https:" + file.url;
          try {
            await cache.add(url);
          } catch (err) {
            console.warn("Failed to cache asset:", url, err);
          }
        }
      }
    } catch (error) {
      console.error("Error syncing contentful data:", error);

      // If there's old data, we can let the user proceed
      if (localStorage.getItem("ayaAppEntries") && localStorage.getItem("ayaAppAssets")) {
        this.setState({ dataLoaded: true });
      } else {
        alert("App needs wifi to download initial data");
      }
    }
  }

  // ========== (E) authenticateUser logic unchanged from your code ========== //
  authenticateUser(hasPinBool, hasAccessBool) {
    this.setState({
      authenticate: { hasPin: hasPinBool, hasAccess: hasAccessBool },
    });
  }


  render() {
    // Has a pin but needs to be authenticated
    return (

      <div className="flexColumn flexGrow1 appWrapper">
        {/* <ChatManager /> */}
        <Router basename="/">
          <Route
            path="/enter-pin"
            render={(props) =>
              this.state.authenticate.hasPin ? (
                <>
                  {this.state.authenticate.hasAccess === false ? (
                    <EnterPin
                      {...props}
                      appLanguage={this.state.appLanguage}
                      authenticateUser={this.authenticateUser}
                    />
                  ) : (
                    <Redirect to="/home" />
                  )}
                </>
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            path="/"
            exact
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <SplashPage
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/"
            exact
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <SplashPage
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding-language"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <LanguageSelectionPage
                  {...props}
                  appLanguage={this.state.appLanguage}
                  handleLanguageChange={this.handleLanguageChange}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding-imaya"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <IntroPage1
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/onboarding-benefits"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <IntroPage2
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/onboarding-careteam"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <IntroPage3
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

         
          <Route
            path="/onboarding-privacy"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <IntroPage5Privacy
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding-terms-of-use-page"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <TermsOfUsePage
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding-privacy-policy-page"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PrivacyPolicyPage
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          <Route
            path="/onboarding-survey"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <IntroPageSurvey
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

            <Route
              path="/splash-screen"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <Onboarding1New
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

            <Route
              path="/home"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <Layout showTopNav={false} showBottomNav={false}>
                  <HomePageNew
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                </Layout>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

          {/* Eligibility test */}

          <Route
            path="/eligibility-start"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding1New
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-calendar-lmp"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding2New
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-tip"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding2NewFUMTips
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-calendar-edd"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding2NewEco
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/eligibility-question-one"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding3New
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-question-two"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding4New
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-question-three"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding5New
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-positive-result"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <OnboardingTestSuccess
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-negative-result"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <OnboardingTestFailed
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          <Route
            path="/eligibility-under-12"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <OnboardingUnder12Weeks
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/eligibility-over-12"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <OnboardingOver12Weeks
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          {/* LibraryContraception */}

          <Route
            path="/contra-short-birthcontrolpills"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <BirthControlPills
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          <Route
            path="/contra-long-iud"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <IUD
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-long-implant"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <SubdermanImplant
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-short-injection"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <ContraceptiveInjection
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-emergency-emergencypill"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <EmergencyPills
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-emergency-long-copperiud"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <CopperIntrauterineDevice
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-barrier-condom"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <Condoms
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          <Route
            path="/contra-permanent-tuballigation"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <TubalLitigation
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-permanent-vasectomy"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <Vasectomy
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-natural-fertility"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <MethodsFertilityKnowledge
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-natural-coitus"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <CoitusInterruptus
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-short-ring"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <VaginalRing
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          <Route
            path="/contra-short-patch"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <HormonalPatch
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-barrier-cervicalcap"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <CervicalCap
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contra-barrier-diaphragm"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <Diafragma
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />




          {/* LibraryAbortion */}


          <Route
            path="/abor-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <LibraryAbortionMenu
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          <Route
            path="/contra-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <LibraryContraceptionMenu
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-before-whatpillsdoineed"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionBeforeOne
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-before-whattoexpect"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionBeforeTwo
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-before-howtopreparemyself"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionBeforeThree
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-during-usemifeandmiso"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionDuringOne
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-during-usemiso"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionDuringTwo
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-during-warningsigns"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionDuringThree
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abor-after-test"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionAfterOneTest
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

<Route
              path="/abor-aftertest-positive"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <PillAbortionTestSuccess
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/abor-aftertest-negative"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <PillAbortionTestFailed
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />


          <Route
            path="/abor-after-managingemotions"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <PillAbortionAfterTwo
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          {/* FAQ and Real stories */}

          <Route
            path="/faqs-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <FAQs
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />



          <Route
            path="/real-stories-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <RealStoriesLibrary
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/real-story-one"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <RealStoriesOne
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
            Route />

          <Route
            path="/real-story-two"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <RealStoriesTwo
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/real-story-three"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <RealStoriesThree
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/real-story-four"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <RealStoriesFour
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


          {/* Resources and Self-care */}



          <Route
            path="/resources-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ResourcesHome
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

<Route
            path="/resources-legalassistance"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <LegalAssistance
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

<Route
            path="/resources-pillsandclinics"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PillsAndClinics
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


<Route
            path="/resources-medicaladvice"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <MedicalAdvice
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


<Route
            path="/resources-immigrants"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ImmiSupport
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


<Route
            path="/resources-financialassistance"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <FinancialHelp
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />


<Route
            path="/resources-emotionalsupport"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <EmotionalSupport
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />



          <Route
            path="/self-care-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <Selfcare
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/self-care-connection"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <SelfcareConnection
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/self-care-nutrition"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <SelfcareFood
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

<Route
              path="/self-care-nutrition-doc-sp"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <SelfcareNutritionPDF
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />


          <Route
            path="/self-care-expression"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <SelfcareExpression
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/self-care-attention"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <SelfcareAttention
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/self-care-reflection"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <SelfcareReflection
                  {...props}
                  appLanguage={this.state.appLanguage}
                />
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          {/* Myths and realities */}

          <Route
            path="/myths-library"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <MythsAndRealities {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

         {/*  More Pages */}

          <Route
              path="/more"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <MoreMenu
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

          <Route
              path="/more-changelanguage"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <LanguageChange
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

            <Route
              path="/more-aboutvitala"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <AboutVitalaMore
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

            {/*             <Route
              path="/more-rateaya"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <RateAya
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            /> */}

<Route
              path="/more-privacypolicy"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <PrivacyPolicyMore
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

            <Route
              path="/more-termsofuse"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <TermsOfUseMore
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/more-set-pin"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <PINSetUp
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

            <Route
              path="/more-set-pin-success"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <PINSetUpSuccess
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

            {/* Contraception Test */}

            <Route
              path="/contra-test-questionone"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ1
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questiontwo"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ2
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questionthree"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ3
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questionfour"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ4
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questionfive"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ5
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questionsix"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ6
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questionseven"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ7
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questioneight"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ8
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-questionnine"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestQ9
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

<Route
              path="/contra-test-result"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <>
                    {this.state.dataLoaded === true ? (
                      <ContraTestResult
                        {...props}
                        appLanguage={this.state.appLanguage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />


          {/* Chat Pages  */}

          <Route
              path="/chat-main"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <Layout showTopNav={true} showBottomNav={true} topNavProps={{
                    backgroundColor: "#DCE0FF",
                    breadcrumbColor: "#333",
                  }}>
                  <ChatMenu
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                </Layout>
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />


            <Route
              path="/chat-internal"
              render={(props) =>
                this.state.authenticate.hasAccess === true ? (
                  <ChatCrisp
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <Redirect to="/enter-pin" />
                )
              }
            />

          {/* Resources */}


          <Route
            path="/TermsOfUsePage"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <TermsOfUsePage
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
        </Router>
      </div>

    );
  }
}

/* export default App */
export default withRouter(App);