/* import './ImmiSupport.modules.css' */
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import HeaderCardNew from '../../Components/HeaderCardNew';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import BasicCard from '../../Components/BasicCardComplex'
import styles from './ResourcesPages.module.css'
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import { useState, useEffect } from 'react'
/* import { BottomNavBar } from '../../Components/BottomNavBar' */
import ReactGA from 'react-ga4';

function ImmiSupport(props) {
	const appLanguage = props.appLanguage || localStorage.getItem("language") || "en";

	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageImageOne, setPageImageOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [cardOneLogo, setCardOneLogo] = useState('')
	const [cardTwoLogo, setCardTwoLogo] = useState('')
	const [cardThreeLogo, setCardThreeLogo] = useState('')
	const [cardFourLogo, setCardFourLogo] = useState('')
	const [cardFiveLogo, setCardFiveLogo] = useState('')
	const [headerImage, setHeaderImage] = useState('')
	const [headerTitle, setHeaderTitle] = useState('')
	const [cardOneTitle, setCardOneTitle] = useState('')
	const [cardTwoTitle, setCardTwoTitle] = useState('')
	const [cardThreeTitle, setCardThreeTitle] = useState('')
	const [cardOneDescription, setCardOneDescription] = useState('')
	const [cardTwoDescription, setCardTwoDescription] = useState('')
	const [cardThreeDescription, setCardThreeDescription] = useState('')
	const [cardFourTitle, setCardFourTitle] = useState('')
	const [cardFourDescription, setCardFourDescription] = useState('')
	const [cardFiveTitle, setCardFiveTitle] = useState('')
	const [cardFiveDescription, setCardFiveDescription] = useState('')
	const [helpLineButton, setHelpLineButton] = useState('')
	const [phoneIcon, setPhoneIcon] = useState('');
	const [arrowIconTwo, setArrowIconTwo] = useState('');
	const [buttonOne, setButtonOne] = useState('')
	const [buttonTwo, setButtonTwo] = useState('')
	const [lastButton, setLastButton] = useState('')
	const [lastButtonArrow, setLastButtonArrow] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [cardFourButtonTitle, setCardFourButtonTitle] = useState('');
	const [navButtonOne, setNavButtonOne] = useState('')
	const [navButtonTwo, setNavButtonTwo] = useState('')
	const [navButtonThree, setNavButtonThree] = useState('')
	const [navButtonFour, setNavButtonFour] = useState('')
	const [navButtonFive, setNavButtonFive] = useState('')
	const [navButtonSix, setNavButtonSix] = useState('')

	const location = useLocation();
  const currentPath = location.pathname;

  const items = [
	
	{ name: navButtonSix, path: "/resources-immigrants", backgroundColor: "#4350E6", color: "#fff", borderRadius: "20px", width: "232px", height: "24px", padding: "2px" },
	{ name: navButtonOne, path: "/resources-legalassistance", backgroundColor: "#fff" , border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "191px", height: "24px", padding: "2px"  },
	{ name: navButtonTwo, path: "/resources-pillsandclinics", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "132px", height: "24px", padding: "2px"  },
	{ name: navButtonThree, path: "/resources-medicaladvice", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "191px", height: "24px", padding: "2px"   },
	{ name: navButtonFour, path: "/resources-financialassistance", backgroundColor: "#fff", border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "138px", height: "24px", padding: "2px" },
	{ name: navButtonFive, path: "/resources-emotionalsupport", backgroundColor: "#fff" , border: "1px solid #4350E6", color: "#4350E6", borderRadius: "20px", width: "191px", height: "24px", padding: "2px"  },
  ];

	  const settings = {
		dots: false,
		infinite: true,
		speed: 200,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
  		centerPadding: '5px', 
		variableWidth: true,
		beforeChange: (current, next) => setSelectedItem(items[next].name),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerPadding: '10px',
			  },
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
				/* centerPadding: '5px', */
			  },
			},
		  ]
	};


	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-support");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Support";
	
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */

		// GET ALL ENTRIES
		const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);


		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesCategory"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "3so8WlOy0mczkcgtq8kyaZ"
		)

		

		// GET NAVITEM ONE
		let navItem1 = ''
			if (getContent?.length > 0) {
				const navItemOneData = getContent[0].fields;
				navItem1 = navItemOneData.topNavButton1? navItemOneData.topNavButton1[props.appLanguage] : '';
      }
			setNavButtonOne(navItem1);


			
		// GET NAVITEM TWO
		let navItem2 = ''
		if (getContent?.length > 0) {
			const navItemTwoData = getContent[0].fields;
			navItem2 = navItemTwoData.topNavButton2? navItemTwoData.topNavButton2[props.appLanguage] : '';
		}
		setNavButtonTwo(navItem2);



		// GET NAVITEM THREE
		let navItem3 = ''
		if (getContent?.length > 0) {
			const navItemThreeData = getContent[0].fields;
			navItem3 = navItemThreeData.topNavButton3? navItemThreeData.topNavButton3[props.appLanguage] : '';
		}
		setNavButtonThree(navItem3);



		// GET NAVITEM FOUR
		let navItem4 = ''
		if (getContent?.length > 0) {
			const navItemFourData = getContent[0].fields;
			navItem4 = navItemFourData.topNavButton4? navItemFourData.topNavButton4[props.appLanguage] : '';
		}
		setNavButtonFour(navItem4);



		// GET NAVITEM FIVE 
		let navItem5 = ''
		if (getContent?.length > 0) {
			const navItemFiveData = getContent[0].fields;
			navItem5 = navItemFiveData.topNavButton5? navItemFiveData.topNavButton5[props.appLanguage] : '';
		}
		setNavButtonFive(navItem5);



		// GET NAVITEM SIX
		let navItem6 = ''
		if (getContent?.length > 0) {
			const navItemSixData = getContent[0].fields;
			navItem6 = navItemSixData.topNavButton6? navItemSixData.topNavButton6[props.appLanguage] : '';
		}
		setNavButtonSix(navItem6);


		
		// GET PAGE title
			let pageTitle = ''
				if (getContent?.length > 0) {
					const pageTitleData = getContent[0].fields;
					pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
				}
				setPageTitleOne(pageTitle);



		// GET PAGE description
			let pageDescription = ''
			if (getContent?.length > 0) {
				const pageDescriptionData = getContent[0].fields;
				pageDescription = pageDescriptionData.description? pageDescriptionData.description[props.appLanguage] : '';
			}
			setPageDescriptionOne(pageDescription);



		// GET HELPLINE ACCESS BUTTON text
		let helplineAccessButton = ''
			if (getContent?.length > 0) {
				const helpLineAccessButtonData = getContent[0].fields;
				helplineAccessButton = helpLineAccessButtonData.helplineAccessButton? helpLineAccessButtonData.helplineAccessButton[props.appLanguage] : '';
			}
			setHelpLineButton(helplineAccessButton);



		// GET CARD ONE titlE
			let cardOneTitleText = ''
				if (getContent?.length > 0) {
					const cardOneTitleData = getContent[0].fields;
					cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[props.appLanguage] : '';
				}
				setCardOneTitle(cardOneTitleText);



		// GET CARD ONE description
			let cardOneDescriptionText = ''
			if (getContent?.length > 0) {
				const cardOneDescriptionData = getContent[0].fields;
				cardOneDescriptionText = cardOneDescriptionData.card1DescriptionLong? cardOneDescriptionData.card1DescriptionLong[props.appLanguage] : '';
			}
			setCardOneDescription(cardOneDescriptionText);



		// GET CARD TWO title
			let cardTwoTitleText = ''
				if (getContent?.length > 0) {
					const cardTwoTitleData = getContent[0].fields;
					cardTwoTitleText = cardTwoTitleData.card2Title? cardTwoTitleData.card2Title[props.appLanguage] : '';
				}
				setCardTwoTitle(cardTwoTitleText);



		// GET CARD TWO description
			let cardTwoDescriptionText = ''
			if (getContent?.length > 0) {
				const cardTwoDescriptionData = getContent[0].fields;
				cardTwoDescriptionText = cardTwoDescriptionData.card2Description? cardTwoDescriptionData.card2Description[props.appLanguage] : '';
			}
			setCardTwoDescription(cardTwoDescriptionText);



		// GET CARD THREE title
			let cardThreeTitleText = ''
				if (getContent?.length > 0) {
					const cardThreeTitleData = getContent[0].fields;
					cardThreeTitleText = cardThreeTitleData.card3Title? cardThreeTitleData.card3Title[props.appLanguage] : '';
				}
				setCardThreeTitle(cardThreeTitleText);



		// GET CARD THREE description
		let cardThreeDescriptionText = ''
			if (getContent?.length > 0) {
				const cardThreeDescriptionData = getContent[0].fields;
				cardThreeDescriptionText = cardThreeDescriptionData.card3Description? cardThreeDescriptionData.card3Description[props.appLanguage] : '';
			}
			setCardThreeDescription(cardThreeDescriptionText);

			// GET CARD FOUR title
			let cardFourTitleText = ''
				if (getContent?.length > 0) {
					const cardFourTitleData = getContent[0].fields;
					cardFourTitleText = cardFourTitleData.card4Title? cardFourTitleData.card4Title[props.appLanguage] : '';
				}
				setCardFourTitle(cardFourTitleText);
	
		
		
		// GET CARD FOUR description
			let cardFourDescriptionText = ''
			if (getContent?.length > 0) {
				const cardFourDescriptionData = getContent[0].fields;
				cardFourDescriptionText = cardFourDescriptionData.card4Description? cardFourDescriptionData.card4Description[props.appLanguage] : '';
			}
			setCardFourDescription(cardFourDescriptionText);


		
		// GET CARD FOUR button one
		let button1 = ''
		if (getContent?.length > 0) {
			const buttonOneData = getContent[0].fields;
			button1 = buttonOneData.card1Button2? buttonOneData.card1Button2[props.appLanguage] : '';
		}
		setButtonOne(button1);



		// GET CARD FOUR button two
		let button2 = ''
		if (getContent?.length > 0) {
			const buttonTwoData = getContent[0].fields;
			button2 = buttonTwoData.card1Button1? buttonTwoData.card1Button1[props.appLanguage] : '';
		}
		setButtonTwo(button2);


				// GET CARD FIVE title
				let cardFiveTitleText = ''
				if (getContent?.length > 0) {
					const cardFiveTitleData = getContent[0].fields;
					cardFiveTitleText = cardFiveTitleData.card5Title? cardFiveTitleData.card5Title[props.appLanguage] : '';
				}
				setCardFiveTitle(cardFiveTitleText);



		// GET CARD FIVE description
			let cardFiveDescriptionText = ''
			if (getContent?.length > 0) {
				const cardFiveDescriptionData = getContent[0].fields;
				cardFiveDescriptionText = cardFiveDescriptionData.card5Description? cardFiveDescriptionData.card5Description[props.appLanguage] : '';
			}
			setCardFiveDescription(cardFiveDescriptionText);


		// GET LAST button TEXT
		let lastButton1 = ''

		if (getContent?.length > 0) {
			const lastButtonData = getContent[0].fields;
			lastButton1 = lastButtonData.lastButton  ? lastButtonData.lastButton[props.appLanguage] : '';
		}
		setLastButton(lastButton1)

				// GET HEADER TITLE
				let headerTitle = ''
				if (getContent?.length > 0) {
					const headerTitleData = getContent[0].fields;
					headerTitle = headerTitleData.headerTitle? headerTitleData.headerTitle[props.appLanguage] : '';
				}
				setHeaderTitle(headerTitle);


		// GET ASSETS
		const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);


		// GET MAIN PAGE IMAGE ONE
		const getImages = assets.filter(
      obj => obj.sys.id === '4DU2tzCRbFElZcH4UwCe0i'
    );

    let imageOne = '';
    if (getImages?.length > 0 && getImages[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageOne = 'https:' + getImages[0].fields.file[props.appLanguage].url;
		}
    setPageImageOne(imageOne)



		// GET CARD ONE logo
		const getCardOneLogo = assets.filter(
      obj => obj.sys.id === '7HH7BjXQn6UtoHNzTwUJ7s'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoOne = 'https:' + getCardOneLogo[0].fields.file[props.appLanguage].url;
		}
    setCardOneLogo(logoOne)



		// GET CARD TWO logo
		const getCardTwoLogo = assets.filter(
      obj => obj.sys.id === '1aTclhAUhB2jW2h1l5fpOs'
    );

    let logoTwo = '';
    if (getCardTwoLogo?.length > 0 && getCardTwoLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoTwo = 'https:' + getCardTwoLogo[0].fields.file[props.appLanguage].url;
		}
    setCardTwoLogo(logoTwo)



		// GET CARD THREE logo
		const getCardThreeLogo = assets.filter(
			obj => obj.sys.id === '6Qcf4BXau7YJXQml74vZgA'
		);
	
		let logoThree = '';
		if (getCardThreeLogo?.length > 0 && getCardThreeLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoThree = 'https:' + getCardThreeLogo[0].fields.file[props.appLanguage].url;
		}
		setCardThreeLogo(logoThree)

				// GET CARD FOUR logo
				const getCardFourLogo = assets.filter(
					obj => obj.sys.id === '4DdMNRoo2cZhGzyvnvtFwU'
				);
			
				let logoFour = '';
				if (getCardFourLogo?.length > 0 && getCardFourLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
					logoFour = 'https:' + getCardFourLogo[0].fields.file[props.appLanguage].url;
				}
				setCardFourLogo(logoFour)

								// GET CARD FIVE logo
								const getCardFiveLogo = assets.filter(
									obj => obj.sys.id === 'u8Qy8UQ3sJabbVhnXWHvz'
								);
							
								let logoFive = '';
								if (getCardFiveLogo?.length > 0 && getCardFiveLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
									logoFive = 'https:' + getCardFiveLogo[0].fields.file[props.appLanguage].url;
								}
								setCardFiveLogo(logoFive)
				



		// GET PHONE ICON
		const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2wFi6weRCSSPpRKvVEjHEW'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
		}
    setPhoneIcon(phoneIconOne);



		// GET ARROW ICON TWO 
		const getArrowIconTwo = assets.filter(
      obj => obj.sys.id === 'h150pgUHcW5LIJwnFodWZ'
    );

    let arrowIconTwo = '';
    if (getArrowIconTwo?.length > 0 && getArrowIconTwo[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIconTwo = 'https:' + getArrowIconTwo[0].fields.file[props.appLanguage].url;
		}
    setArrowIconTwo(arrowIconTwo);



		// GET WHITE ARROW ICON (last Button arrow)
		const getWhiteArrowIcon = assets.filter(
      obj => obj.sys.id === 'h150pgUHcW5LIJwnFodWZ'
    );

    let whiteArrowIcon = '';
    if (getWhiteArrowIcon?.length > 0 && getWhiteArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			whiteArrowIcon = 'https:' + getWhiteArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setLastButtonArrow(whiteArrowIcon);
	

				// GET HEADER IMAGE 
				const getHeaderImage = assets.filter(
					obj => obj.sys.id === '134BNLoX94enSRb1DZ6cRj'
				  );
			  
				  let headerImage = '';
				  if (getHeaderImage?.length > 0 && getHeaderImage[0]?.fields?.file?.[props.appLanguage]?.url) {
						  headerImage = 'https:' + getHeaderImage[0].fields.file[props.appLanguage].url;
					  }
				  setHeaderImage(headerImage);


	}, [props.appLanguage])

	const language = props.appLanguage;
	
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://laslibres.org/FAQS/';
  } else if (language === 'es-US') {
    linkOne = 'https://laslibres.org/es/Preguntas/#How-to-order';
  }


	return(
		<>
					<div className={styles.homePageContainer}>
					<TopNavBarNew backgroundColor="#4350E6" breadcrumbColor="#fff" />
					<div className={styles.extraSpace2}></div>
						<HeaderCardNew
							  title={headerTitle}
							  image={headerImage}
							  backgroundColor="#4350E6"
							  titleColor="#fff"
							  borderRadius="0px"
							/>

<div className={styles.carouselContainer}>
            <div className={styles.dotsContainer}>
            </div>
				<div className={`custom-dot-container`}>
				<Slider {...settings}>
				{items.map((item, index) => (
					<div key={index} className={`${styles.slideItem}`}>
					<Link
						to={item.path}
						className={`${styles.linkButton} ${styles.customDot} ${item.path === currentPath ? "active" : ""}`}
						style={{
						backgroundColor: item.backgroundColor,
						color: item.color,
						border: "2px solid #4350E6", // Make the border thicker
						borderRadius: item.borderRadius,
						minHeight: "24px", 
						minWidth: "80px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						paddingBottom: "2px",
						marginBottom: "2px",
						transform: "translate3d(0, 0, 0)", // Forces hardware acceleration
						willChange: "transform", // Optimizes rendering
						}}
					>
						{item.name}
					</Link>
					</div>
				))}
				</Slider>
				</div>
            </div>
			<div className={styles.moreMargin}></div>

		<div className='immiSupportContainer' style={{ backgroundColor: 'white' }}>
			
		<BasicCard
            content={pageDescriptionOne}
			isUpperCard={true}
        />

		<div className='cardsContainer'>
    	<a href='https://www.nilc.org/' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardOneLogo}
            title={
							<div style={{ paddingTop: '10px' }}>
								{cardOneTitle}
							</div>
						}
            content={cardOneDescription}
            arrow={arrowIconTwo}
        />
   		 </a>
  	  <a href='http://mariposafund.org/' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardTwoLogo}
            title={cardTwoTitle}
            content={cardTwoDescription}
            arrow={arrowIconTwo}
        />
   		 </a>
   		 <a href='https://fronterafundrgv-org.translate.goog/?_x_tr_sl=en&_x_tr_tl=es&_x_tr_hl=en&_x_tr_pto=wapp' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardThreeLogo}
            title={cardThreeTitle}
            content={cardThreeDescription}
            arrow={arrowIconTwo}
        />
  		  </a>


			<div>
			<BasicCard
  image={cardFourLogo}
  title={
    <div style={{ paddingTop: '5px' }}>
      {cardFourTitle}
    </div>
  }
  content={
    <div style={{ paddingBottom: '10px' }}>
      {cardFourDescription}
    </div>
  }

					// Make the first button an actual link
					button1={
						<a
						href={linkOne}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#051D58', textDecoration: 'none' }}
						>
						{buttonOne}
						</a>
					}

					// The second "button" is just text, no link
					button2={
						<div style={{ color: '#051D58', textDecoration: 'none' }}>
						{buttonTwo}
						</div>
					}

					arrowIconOne={arrowIconTwo}
					phoneIcon={phoneIcon}
					/>
					</div>
					<a href='https://www-redstateaccess-org.translate.goog/?_x_tr_sl=en&_x_tr_tl=es&_x_tr_hl=en&_x_tr_pto=wapp' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardFiveLogo}
            title={cardFiveTitle}
            content={cardFiveDescription}
            arrow={arrowIconTwo}
        />
  		  </a>
		  
		</div>


			<div className='end-of-page'></div>
			{/* <BottomNavBar appLanguage={props.appLanguage} /> */}
		</div>
		<div className={styles.bottomSpace}></div>
		<BottomNavBarNew appLanguage={appLanguage}/>
		</div>
		</>
	)
}

export default ImmiSupport


