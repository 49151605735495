import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { processEntries, processAssets } from "../../utils/contentProcessor";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import * as ContentfulMarkdown from 'react-markdown';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import styles from "./PillAbortionTestSuccess.module.css";

export default function PillAbortionTestSuccess({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: "",
    title2: "",
    description: "",
    buttonText: "",
    image: "",
  });

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Abor Test Result";

    // path: /abor-aftertest-positive

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets"));

    const entryId = "2LRjG3OLBpI9Bik3Hjfqx8";
    const imageAssetId = "ArNk0Jwo6QeP68r5rCIFB";

    const processedEntries = processEntries(entries, appLanguage, {
      abortionFlow: {
        entryId,
        fieldsMapping: {
          title: "title",
          title2: "title2",
          description: "description",
          buttonText: "buttonText",
        },
      },
    });

    const processedAssets = processAssets(assets, appLanguage, {
      image: imageAssetId,
    });

    setPageState({
      title: processedEntries.abortionFlow?.title || "",
      title2: processedEntries.abortionFlow?.title2 || "",
      description: processedEntries.abortionFlow?.description || "",
      buttonText: processedEntries.abortionFlow?.buttonText || "",
      image: processedAssets?.image || "",
    });
  }, [appLanguage]);

  return (
    <>
    <div className={styles.pageContainer}>
      <TopNavBarNew backgroundColor="#4450E5" breadcrumbColor="#fff" />
      
     <div className={styles.background}>
     <div className={styles.extraSpace}></div>
        <h1 className={styles.title}>{pageState.title}</h1>
        <h2 className={styles.title2}>{pageState.title2}</h2>
        
        {pageState.image && (
          <div className={styles.imageContainer}>
            <img
              src={pageState.image}
              alt="Success Illustration"
              className={styles.image}
            />
          </div>
        )}

        {/* ✅ Render description using Contentful Markdown */}
        {pageState.description && typeof pageState.description === 'object' ? (
          documentToReactComponents(pageState.description)
        ) : (
          <ContentfulMarkdown
            className={styles.linkText}
            children={pageState.description}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            allowDangerousHtml={true} 
          />
        )}

        <button 
          className={styles.button} 
          onClick={() => history.push('/abor-library')}
        >
          {pageState.buttonText}
        </button>
        <BottomNavBarNew appLanguage={appLanguage}/>
      </div>
    </div>
    </>
  );
}