import React, { useState, useEffect } from "react";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import MenuCard from "../../Components/MenuCard";
import styles from "./MoreMenu.module.css";

export default function MoreMenu({ appLanguage }) {
  const [pageState, setPageState] = useState({
    subTitleItems: {},
    menuItems: {},
    assets: {},
    isAuthenticated: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "More Menu";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets")) || [];

    // Find the "More Menu" entry in Contentful
    const menuEntry = entries.find(
      (obj) => obj.sys.id === "5rT8zuyAd7mQFA8iV3hF6P"
    );
    if (!menuEntry?.fields) {
      console.error("More Menu entry not found or incomplete in Contentful.");
      return;
    }

    // Fallback chain for each field:
    const menuItems = {
      about:
        menuEntry.fields.largeCardTitle1?.[appLanguage] ||
        menuEntry.fields.largeCardTitle1?.["en"] ||
        "About Vitala",
      rate:
        menuEntry.fields.largeCardTitle2?.[appLanguage] ||
        menuEntry.fields.largeCardTitle2?.["en"] ||
        "Rate App",
      logout:
        menuEntry.fields.largeCardTitle3?.[appLanguage] ||
        menuEntry.fields.largeCardTitle3?.["en"] ||
        "Log Out",
      deleteAccount:
        menuEntry.fields.largeCardTitle4?.[appLanguage] ||
        menuEntry.fields.largeCardTitle4?.["en"] ||
        "Delete Account",
      changePassword:
        menuEntry.fields.largeCardTitle5?.[appLanguage] ||
        menuEntry.fields.largeCardTitle5?.["en"] ||
        "Change Password",
      pin:
        menuEntry.fields.largeCardTitle6?.[appLanguage] ||
        menuEntry.fields.largeCardTitle6?.["en"] ||
        "Set PIN",
      privacy:
        menuEntry.fields.largeCardTitle7?.[appLanguage] ||
        menuEntry.fields.largeCardTitle7?.["en"] ||
        "Privacy Policy",
      terms:
        menuEntry.fields.largeCardTitle8?.[appLanguage] ||
        menuEntry.fields.largeCardTitle8?.["en"] ||
        "Terms of Use",
      language:
        menuEntry.fields.largeCardTitle9?.[appLanguage] ||
        menuEntry.fields.largeCardTitle9?.["en"] ||
        "Language",
    };

    const subTitleItems = {
      aboutUs:
        menuEntry.fields.largeCardTitle10?.[appLanguage] ||
        menuEntry.fields.largeCardTitle10?.["en"] ||
        "About Us",
      security:
        menuEntry.fields.largeCardTitle11?.[appLanguage] ||
        menuEntry.fields.largeCardTitle11?.["en"] ||
        "Security",
      legal:
        menuEntry.fields.largeCardTitle12?.[appLanguage] ||
        menuEntry.fields.largeCardTitle12?.["en"] ||
        "Legal",
      language:
        menuEntry.fields.largeCardTitle13?.[appLanguage] ||
        menuEntry.fields.largeCardTitle13?.["en"] ||
        "Language",
    };

    // Find asset URLs
    const findAssetUrl = (id) => {
      const found = assets.find((a) => a.sys.id === id);
      return found?.fields?.file?.url ? "https:" + found.fields.file.url : "";
    };

    const assetsMap = {
      aboutIcon: findAssetUrl("AzXUwe3eiLPRSAHzDb3mM"),
      rateIcon: findAssetUrl("ChykhAXMCyv021oxQMePd"),
      logoutIcon: findAssetUrl("6rw0lodWQpuo8wcjQy8UFv"),
      deleteIcon: findAssetUrl("4riIDiIPGW6xPOSyCGsiAS"),
      passwordIcon: findAssetUrl("5FyRP6dI2FPTbSoOUNpkPk"),
      pinIcon: findAssetUrl("1fz4mD5lp7lWSawOZjs8BY"),
      privacyIcon: findAssetUrl("7xvQKh9tfRoBcdrSnZh8q5"),
      termsIcon: findAssetUrl("7xvQKh9tfRoBcdrSnZh8q5"),
      languageIcon: findAssetUrl("75yh8HiL3lWPwX620vPJqL"),
    };

    setPageState({
      subTitleItems,
      menuItems,
      assets: assetsMap,
      isAuthenticated: false, // or context-based logic
    });
  }, [appLanguage]);

  // ✅ PIN Setup Logic: Redirects users to the right PIN page
  const hasVisitedPinSuccess =
    localStorage.getItem("hasVisitedPinSuccess") === "true";
  const pinSetupPath = hasVisitedPinSuccess
    ? "/more-set-pin-success"
    : "/more-set-pin";

  return (
    <div className={styles.container}>
      <TopNavBarNew pageTitle="Más" />

      <div className={styles.extraSpace}></div>

      <div className={styles.content}>
        <h2 className={styles.heading}>{pageState.subTitleItems.aboutUs}</h2>
        <MenuCard
          icon={pageState.assets.aboutIcon}
          title={pageState.menuItems.about}
          path="/more-aboutvitala"
          className={styles.menuCard}
        />

        <div className={styles.extraSpace}></div>

        <h2 className={styles.heading}>{pageState.subTitleItems.security}</h2>
        <MenuCard
          icon={pageState.assets.pinIcon}
          title={pageState.menuItems.pin}
          path={pinSetupPath} // ✅ PIN setup logic applied
          className={styles.menuCard}
        />

        <div className={styles.extraSpace}></div>

        <h2 className={styles.heading}>{pageState.subTitleItems.legal}</h2>
        <MenuCard
          icon={pageState.assets.privacyIcon}
          title={pageState.menuItems.privacy}
          path="/more-privacypolicy"
          className={styles.menuCard}
        />
        <MenuCard
          icon={pageState.assets.termsIcon}
          title={pageState.menuItems.terms}
          path="/more-termsofuse"
          className={styles.menuCard}
        />

        <div className={styles.extraSpace}></div>

        <h2 className={styles.heading}>{pageState.subTitleItems.language}</h2>
        <MenuCard
          icon={pageState.assets.languageIcon}
          title={pageState.menuItems.language}
          path="/more-changelanguage"
          className={styles.menuCard}
        />
      </div>
      <div className={styles.extraSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}