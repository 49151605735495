import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import BannerCard from '../../Components/BannerCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import CallToActionCard from '../../Components/CallToActionCard';
import styles from '../AbortionWPills/PillAbortion.module.css';

export default function Selfcare({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    narrowCardTitle1: '',
    narrowCardTitle2: '',
    narrowCardTitle3: '',
    narrowCardTitle4: '',
    narrowCardTitle5: '',
    extraText: '',
    extraText2: '',
  

    // Banner Card
    bannerCardDescription1: '',
    bannerCardTitle1: '',
    bannerCardDescription2: '',
    bannerCardTitle2: '',

    // Assets
    headerImage: '',
    arrowIconDark: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
    image6: '',
    image7: '',
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Selfcare Library';

    // path: /selfcare

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = '6ymOEBNrtSQhpowLPHgsax';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1',
          bannerCardDescription2: 'bannerCardDescription2',
          bannerCardTitle2: 'bannerCardTitle2',
          narrowCardTitle1: 'narrowCardTitle1',
          narrowCardTitle2: 'narrowCardTitle2',
          narrowCardTitle3: 'narrowCardTitle3',
          narrowCardTitle4: 'narrowCardTitle4',
          narrowCardTitle5: 'narrowCardTitle5',
          extraText: 'extraText',
          extraText2: 'extraText2',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '2QGLgde5r3vqnFKv9cINQM',
      arrowIconDark: '35s9SZBtTGy9KGs0lc41lt',
      image1: '1o4vryyT4iehBtLIueRDtu',
      image2: '3UFLFnO0cnjMz9s79q8pVa',
      image3: '4AJ6t1Gxd2LKxzybwjkFXX',
      image4: '7e8Uv97JKTm5RpdBdRC8M8',
      image5: '6uiIPlbRnCskYGB0BqlvkU',
      image6: 'y3Rd7KceR170l23wC9ZX9',
      image7: '2QqrfQvbM7wiJKDebz76V2',
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      narrowCardTitle1: processedEntries['abortoConPastillas']?.narrowCardTitle1 || '',
      narrowCardTitle2: processedEntries['abortoConPastillas']?.narrowCardTitle2 || '',
      narrowCardTitle3: processedEntries['abortoConPastillas']?.narrowCardTitle3 || '',
      narrowCardTitle4: processedEntries['abortoConPastillas']?.narrowCardTitle4 || '',
      narrowCardTitle5: processedEntries['abortoConPastillas']?.narrowCardTitle5 || '',
      extraText: processedEntries['abortoConPastillas']?.extraText || '',
      extraText2: processedEntries['abortoConPastillas']?.extraText2 || '',

      // Banner Card
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
      bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',
      bannerCardDescription2: processedEntries['abortoConPastillas']?.bannerCardDescription2Card || '',
      bannerCardTitle2: processedEntries['abortoConPastillas']?.bannerCardTitle2 || '',

      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
      image3: processedAssets?.image3 || '',
      image4: processedAssets?.image4 || '',
      image5: processedAssets?.image5 || '',
      image6: processedAssets?.image6 || '',
      image7: processedAssets?.image7 || '',
    });
  }, [appLanguage]);

  return (
    
<div className={styles.libraryAbortionMenuContainer}>
        <TopNavBarNew backgroundColor="#FFDFE7" />
        {/* Header Section */}
        <div className={styles.headerContainerContraception}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#FFDFE7"
            borderRadius="0px"
          />
        </div>
        <div className={styles.bottomSpace}></div>
        <div className={styles.homePageContainer}>
        <CallToActionCard
  title={pageState.narrowCardTitle1}
  icon={pageState.arrowIconDark}
  leftIcon={pageState.image1}
  showIcon={true}
  isLink={true}
  buttonLink="/self-care-expression"
  useCardGradient={false} // Set to false to use cardColor
  cardColor="#FFFFFF" 
  titleColor="#4350E6" 
  /* width="357px" */
  height="56px"
  iconPosition="right"
  borderRadius="10px"
  boxShadow="1px 1px 4px 1px rgba(0, 0, 0, 0.15)"
  titlePadding="15px 20px 5px 0"
  titleMargin="0 10px 10px 10px"
  // Custom Font Settings
  titleFont="Inter"
  titleFontSize="15px"
  titleFontWeight="700"
/>

<CallToActionCard
  title={pageState.narrowCardTitle2}
  icon={pageState.arrowIconDark}
  leftIcon={pageState.image2}
  showIcon={true}
  isLink={true}
  buttonLink="/self-care-reflection"
  useCardGradient={false} // Set to false to use cardColor
  cardColor="#FFFFFF" 
  titleColor="#4350E6" 
  /* width="357px" */
  height="56px"
  iconPosition="right"
  borderRadius="10px"
  boxShadow="1px 1px 4px 1px rgba(0, 0, 0, 0.15)"
  titlePadding="15px 20px 5px 0"
  titleMargin="0 10px 10px 10px"
  // Custom Font Settings
  titleFont="Inter"
  titleFontSize="15px"
  titleFontWeight="700"
/>

<CallToActionCard
  title={pageState.narrowCardTitle3}
  icon={pageState.arrowIconDark}
  leftIcon={pageState.image3}
  showIcon={true}
  isLink={true}
  buttonLink="/self-care-connection"
  useCardGradient={false} // Set to false to use cardColor
  cardColor="#FFFFFF" 
  titleColor="#4350E6" 
  width="357px"
  height="56px"
  iconPosition="right"
  borderRadius="10px"
  boxShadow="1px 1px 4px 1px rgba(0, 0, 0, 0.15)"
  titlePadding="15px 20px 5px 0"
  titleMargin="0 10px 10px 10px"
  // Custom Font Settings
  titleFont="Inter"
  titleFontSize="15px"
  titleFontWeight="700"
/>

<CallToActionCard
  title={pageState.narrowCardTitle4}
  icon={pageState.arrowIconDark}
  leftIcon={pageState.image4}
  showIcon={true}
  isLink={true}
  buttonLink="/self-care-attention"
  useCardGradient={false} // Set to false to use cardColor
  cardColor="#FFFFFF" 
  titleColor="#4350E6" 
  width="357px"
  height="56px"
  iconPosition="right"
  borderRadius="10px"
  boxShadow="1px 1px 4px 1px rgba(0, 0, 0, 0.15)"
  titlePadding="15px 20px 5px 0"
  titleMargin="0 10px 10px 10px"
  // Custom Font Settings
  titleFont="Inter"
  titleFontSize="15px"
  titleFontWeight="700"
/>

<CallToActionCard
  title={pageState.narrowCardTitle5}
  icon={pageState.arrowIconDark}
  leftIcon={pageState.image5}
  showIcon={true}
  isLink={true}
  buttonLink="/self-care-nutrition"
  useCardGradient={false} // Set to false to use cardColor
  cardColor="#FFFFFF" 
  titleColor="#4350E6" 
  width="357px"
  height="56px"
  iconPosition="right"
  borderRadius="10px"
  boxShadow="1px 1px 4px 1px rgba(0, 0, 0, 0.15)"
  titlePadding="15px 20px 5px 0"
  titleMargin="0 10px 10px 10px"
  // Custom Font Settings
  titleFont="Inter"
  titleFontSize="15px"
  titleFontWeight="700"
/>

<div className={styles.leftAlignedContent}>

<div className={styles.extraTitletwo}>{pageState.extraText}</div>

</div>
<div className={styles.centeredContent}>
               <BannerCard 
                title={pageState.bannerCardTitle1} 
                description={pageState.bannerCardDescription1}
                image={pageState.image6}
                gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
                link="/chat-main"
                imageWidth="81px"
                imageHeight="80px"
                imagePosition="top-right"
                imageOffsetY=""
                imageOffsetX=""
                titleMaxWidth="256px"
                descriptionMaxWidth="286px"
                cardWidth="349px"
                cardHeight="88px"
                titleFont="Inter"
                  titleFontSize="14px"
                  titleFontWeight="700"
                  /* titlePaddingTop="22px" */
                  descriptionFontSize="13px"
                  descriptionFontWeight="400"
                  titleFontFamily="GT Walsheim Pro"
                  descriptionFontFamily="Inter"
                />
</div>
<div className={styles.leftAlignedContent}>

<div className={styles.extraTitletwo}>{pageState.extraText2}</div>

</div>

<div className={styles.centeredContent}>
                <BannerCard 
                title={pageState.bannerCardTitle2} 
                description={pageState.bannerCardDescription2}
                image={pageState.image7}
                backgroundColor="#E0E2FF"
                titleColor="#484848"
                descriptionColor="#484848"
                link="/real-stories-library"
                imageWidth="81px"
                imageHeight="80px"
                imagePosition="top-right"
                imageOffsetY=""
                imageOffsetX=""
                titleMaxWidth="256px"
                descriptionMaxWidth="286px"
                cardWidth="349px"
                cardHeight="88px"
                titleFont="Inter"
                  titleFontSize="14px"
                  titleFontWeight="700"
                  titlePaddingTop="22px"
                  descriptionFontSize="13px"
                  descriptionFontWeight="400"
                  titleFontFamily="GT Walsheim Pro"
                  descriptionFontFamily="Inter"
                />
                </div>
        </div>


      <div className={styles.bottomSpace}></div>

<BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
