/* import { Link } from 'react-router-dom';
import Slider from "react-slick"; */
import HeaderCardNew from '../../Components/HeaderCardNew';
import BannerCard from '../../Components/BannerCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import styles from './ResourcesPages.module.css'
import BasicCard from '../../Components/BasicCard'
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4';

/* import  { ResourcesTopNav }  from '../../Components/ResourcesTopNav';

import { BottomNavBar } from '../../Components/BottomNavBar' */


function ResourcesHome(props) {
	const appLanguage = props.appLanguage || localStorage.getItem("language") || "en";

	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageImageOne, setPageImageOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [helpLineButton, setHelpLineButton] = useState('')
	const [bannerCardDescription, setBannerCardDescription] = useState('')
	const [bannerCardTitle, setBannerCardTitle] = useState('')
	const [extraText, setExtraText] = useState('')
	const [bannerCardImage, setBannerCardImage] = useState('')
	const [cardOneLogo, setCardOneLogo] = useState('')
	const [cardTwoLogo, setCardTwoLogo] = useState('')
	const [cardThreeLogo, setCardThreeLogo] = useState('')
	const [cardFourLogo, setCardFourLogo] = useState('')
	const [cardFiveLogo, setCardFiveLogo] = useState('')
	const [cardSixLogo, setCardSixLogo] = useState('')
	const [headerImage, setHeaderImage] = useState('')
	const [headerTitle, setHeaderTitle] = useState('')
	const [cardOneTitle, setCardOneTitle] = useState('')
	const [cardTwoTitle, setCardTwoTitle] = useState('')
	const [cardThreeTitle, setCardThreeTitle] = useState('')
	const [cardFourTitle, setCardFourTitle] = useState('')
	const [cardFiveTitle, setCardFiveTitle] = useState('')
	const [cardSixTitle, setCardSixTitle] = useState('')
	const [cardOneDescription, setCardOneDescription] = useState('')
	const [cardTwoDescription, setCardTwoDescription] = useState('')
	const [cardThreeDescription, setCardThreeDescription] = useState('')
	const [cardFourDescription, setCardFourDescription] = useState('')
	const [cardFiveDescription, setCardFiveDescription] = useState('')
	const [cardSixDescription, setCardSixDescription] = useState('')
	const [arrowIconOne, setArrowIconOne] = useState('');
  	const [phoneIcon, setPhoneIcon] = useState('');
	const [buttonOne, setButtonOne] = useState('')
	const [buttonTwo, setButtonTwo] = useState('')
	const [arrowIconTwo, setArrowIconTwo] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);

	const location = useLocation();
  	const currentPath = location.pathname;




	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-emotional-support");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Emotional Support";

        // this hides the Crisp Chat 
       /*  $crisp.push(["do", "chat:hide"]) */
	
		// GET ALL ENTRIES
	const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);

		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesMain"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "1lQHj7S7XBoifgaedO07na"
		)


		// GET HEADER TITLE
		let headerTitle = ''
		if (getContent?.length > 0) {
			const headerTitleData = getContent[0].fields;
			headerTitle = headerTitleData.headerTitle? headerTitleData.headerTitle[appLanguage] : '';
		}
		setHeaderTitle(headerTitle);
		
		// GET EXTRATEXT
			let extraText = ''
			if (getContent?.length > 0) {
				const extraTextData = getContent[0].fields;
				extraText = extraTextData.extraText? extraTextData.extraText[appLanguage] : '';
			}
			setExtraText(extraText);



		// GET BANNERCARD TITLE
			let bannerCardTitle = ''
			if (getContent?.length > 0) {
				const bannerCardTitleData = getContent[0].fields;
				bannerCardTitle = bannerCardTitleData.bannerCardTitle? bannerCardTitleData.bannerCardTitle[appLanguage] : '';
			}
			setBannerCardTitle(bannerCardTitle);

					// GET BANNERCARD DESCRIPTION
			let bannerCardDescription = ''
			if (getContent?.length > 0) {
				const bannerCardDescriptionData = getContent[0].fields;
				bannerCardDescription = bannerCardDescriptionData.bannerCardDescription? bannerCardDescriptionData.bannerCardDescription[appLanguage] : '';
			}
			setBannerCardDescription(bannerCardDescription);


		
		// GET CARD ONE title
			let cardOneTitleText = ''
				if (getContent?.length > 0) {
					const cardOneTitleData = getContent[0].fields;
					cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[appLanguage] : '';
				}
				setCardOneTitle(cardOneTitleText);
		
	
	
		// GET CARD ONE description
			let cardOneDescriptionText = ''
				if (getContent?.length > 0) {
					const cardOneDescriptionData = getContent[0].fields;
					cardOneDescriptionText = cardOneDescriptionData.card1Description? cardOneDescriptionData.card1Description[appLanguage] : '';
				}
				setCardOneDescription(cardOneDescriptionText);
	
	
	
		// GET CARD TWO title
			let cardTwoTitleText = ''
				if (getContent?.length > 0) {
					const cardTwoTitleData = getContent[0].fields;
					cardTwoTitleText = cardTwoTitleData.card2Title? cardTwoTitleData.card2Title[appLanguage] :'';
				}
				setCardTwoTitle(cardTwoTitleText);
	


		// GET CARD ONE button one
			let button1 = ''
			if (getContent?.length > 0) {
				const buttonOneData = getContent[0].fields;
				button1 = buttonOneData.card1Button1? buttonOneData.card1Button1[appLanguage] : '';
			}
			setButtonOne(button1);



		// GET CARD ONE button two
		let button2 = ''
			if (getContent?.length > 0) {
				const buttonTwoData = getContent[0].fields;
				button2 = buttonTwoData.card1Button2? buttonTwoData.card1Button2[appLanguage] : '';
			}
			setButtonTwo(button2);


	
		// GET CARD TWO description
			let cardTwoDescriptionText = ''
			if (getContent?.length > 0) {
				const cardTwoDescriptionData = getContent[0].fields;
				cardTwoDescriptionText = cardTwoDescriptionData.card2Description? cardTwoDescriptionData.card2Description[appLanguage] : '';
			}
			setCardTwoDescription(cardTwoDescriptionText);
	
	
	
		// GET CARD THREE title
			let cardThreeTitleText = ''
				if (getContent?.length > 0) {
					const cardThreeTitleData = getContent[0].fields;
						cardThreeTitleText = cardThreeTitleData.card3Title? cardThreeTitleData.card3Title[appLanguage] : '';
					}
					setCardThreeTitle(cardThreeTitleText);
	
	
	
		// GET CARD THREE description
		let cardThreeDescriptionText = ''
			if (getContent?.length > 0) {
				const cardThreeDescriptionData = getContent[0].fields;
				cardThreeDescriptionText = cardThreeDescriptionData.card3Description? cardThreeDescriptionData.card3Description[appLanguage] : '';
			}
			setCardThreeDescription(cardThreeDescriptionText);
	
	
	
		// GET CARD FOUR title
			let cardFourTitleText = ''
				if (getContent?.length > 0) {
					const cardFourTitleData = getContent[0].fields;
					cardFourTitleText = cardFourTitleData.card4Title? cardFourTitleData.card4Title[appLanguage] : '';
				}
				setCardFourTitle(cardFourTitleText);
	
	
	
		// GET CARD FOUR description
			let cardFourDescriptionText = ''
			if (getContent?.length > 0) {
				const cardFourDescriptionData = getContent[0].fields;
				cardFourDescriptionText = cardFourDescriptionData.card4Description? cardFourDescriptionData.card4Description[appLanguage] : '';
			}
			setCardFourDescription(cardFourDescriptionText);
	
	
	
		// GET CARD FIVE title
		let cardFiveTitleText = ''
				if (getContent?.length > 0) {
					const cardFiveTitleData = getContent[0].fields;
					cardFiveTitleText = cardFiveTitleData.card5Title? cardFiveTitleData.card5Title[appLanguage] : '';
				}
				setCardFiveTitle(cardFiveTitleText);
	
	
	
		// GET CARD FIVE description
			let cardFiveDescriptionText = ''
			if (getContent?.length > 0) {
				const cardFiveDescriptionData = getContent[0].fields;
				cardFiveDescriptionText = cardFiveDescriptionData.card5Description? cardFiveDescriptionData.card5Description[appLanguage] : '';
			}
			setCardFiveDescription(cardFiveDescriptionText);
	
	
	
		// GET CARD SIX title
			let cardSixTitleText = ''
			if (getContent?.length > 0) {
				const cardSixTitleData = getContent[0].fields;
					cardSixTitleText = cardSixTitleData.card6Title? cardSixTitleData.card6Title[appLanguage] : '';
				}
				setCardSixTitle(cardSixTitleText);
	
	
	
		// GET CARD SIX description
			let cardSixDescriptionText = ''
			if (getContent?.length > 0) {
				const cardSixDescriptionData = getContent[0].fields;
				cardSixDescriptionText = cardSixDescriptionData.card6Description? cardSixDescriptionData.card6Description[appLanguage] : '';
			}
			setCardSixDescription(cardSixDescriptionText);
	
		

		// ASSETS
		const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);


		// GET MAIN PAGE image
    const getImages = assets.filter(
      obj => obj.sys.id === '3GjwgkFQG0b3Zw9puB5Jp4'
    );

    let imageOne = '';
    if (getImages?.length > 0 && getImages[0]?.fields?.file?.[appLanguage]?.url) {
			imageOne = 'https:' + getImages[0].fields.file[appLanguage].url;
		}
    setPageImageOne(imageOne)



		// GET CARD logo ONE
		const getCardOneLogo = assets.filter(
      obj => obj.sys.id === '3GOyxfhjO7jRhLVF7T93Dm'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[appLanguage]?.url) {
			logoOne = 'https:' + getCardOneLogo[0].fields.file[appLanguage].url;
		}
    setCardOneLogo(logoOne)



		// GET CARD logo TWO 
		const getCardTwoLogo = assets.filter(
      obj => obj.sys.id === '2vuMo27OHQue2kVtgMOeR9'
    );

    let logoTwo = '';
    if (getCardTwoLogo?.length > 0 && getCardTwoLogo[0]?.fields?.file?.[appLanguage]?.url) {
			logoTwo = 'https:' + getCardTwoLogo[0].fields.file[appLanguage].url;
		}
    setCardTwoLogo(logoTwo)


		
		// GET CARD logo THREE
		const getCardThreeLogo = assets.filter(
			obj => obj.sys.id === 'uruuqOboKpVN7T5mhHq2J'
		);
	
		let logoThree = '';
		if (getCardThreeLogo?.length > 0 && getCardThreeLogo[0]?.fields?.file?.[appLanguage]?.url) {
			logoThree = 'https:' + getCardThreeLogo[0].fields.file[appLanguage].url;
		}
		setCardThreeLogo(logoThree)
	
	
	
		// GET CARD logo FOUR
		const getCardFourLogo = assets.filter(
			obj => obj.sys.id === '78Vlbve5Xx9w7wupL0ESj0'
		);
	
		let logoFour = '';
		if (getCardFourLogo?.length > 0 && getCardFourLogo[0]?.fields?.file?.[appLanguage]?.url) {
			logoFour = 'https:' + getCardFourLogo[0].fields.file[appLanguage].url;
		}
		setCardFourLogo(logoFour)



		// GET CARD logo FIVE
		const getCardFiveLogo = assets.filter(
			obj => obj.sys.id === '7yMpNrgUuiaqgec71tgGBS'
		);

		let logoFive = '';
		if (getCardFiveLogo?.length > 0 && getCardFiveLogo[0]?.fields?.file?.[appLanguage]?.url) {
			logoFive = 'https:' + getCardFiveLogo[0].fields.file[appLanguage].url;
		}
		setCardFiveLogo(logoFive)
	
	
	
		// GET CARD logo SIX
		const getCardSixLogo = assets.filter(
			obj => obj.sys.id === 'XGMS3SBZv8J7RAJRuWTui'
		);
	
		let logoSix = '';
		if (getCardSixLogo?.length > 0 && getCardSixLogo[0]?.fields?.file?.[appLanguage]?.url) {
			logoSix = 'https:' + getCardSixLogo[0].fields.file[appLanguage].url;
		}
		setCardSixLogo(logoSix)


		// GET BANNERCARD IMAGE
		const getBannerCardImage = assets.filter(
			obj => obj.sys.id === '3SG90PfaaDeeL0L0f5qErK'
		);
	
		let bannerCardImage = '';
		if (getBannerCardImage?.length > 0 && getBannerCardImage[0]?.fields?.file?.[appLanguage]?.url) {
			bannerCardImage = 'https:' + getBannerCardImage[0].fields.file[appLanguage].url;
		}
		setBannerCardImage(bannerCardImage)
	


		// GET ARROW ICON
		const getArrowIcon = assets.filter(
      obj => obj.sys.id === 'h150pgUHcW5LIJwnFodWZ'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[appLanguage]?.url) {
			arrowIcon = 'https:' + getArrowIcon[0].fields.file[appLanguage].url;
		}
    setArrowIconOne(arrowIcon);



		// GET ARROW ICON TWO 
		const getArrowIconTwo = assets.filter(
      obj => obj.sys.id === 'h150pgUHcW5LIJwnFodWZ'
    );

    let arrowIconTwo = '';
    if (getArrowIconTwo?.length > 0 && getArrowIconTwo[0]?.fields?.file?.[appLanguage]?.url) {
			arrowIconTwo = 'https:' + getArrowIconTwo[0].fields.file[appLanguage].url;
		}
    setArrowIconTwo(arrowIconTwo);

			// GET HEADER IMAGE 
			const getHeaderImage = assets.filter(
				obj => obj.sys.id === '134BNLoX94enSRb1DZ6cRj'
			  );
		  
			  let headerImage = '';
			  if (getHeaderImage?.length > 0 && getHeaderImage[0]?.fields?.file?.[appLanguage]?.url) {
					  headerImage = 'https:' + getHeaderImage[0].fields.file[appLanguage].url;
				  }
			  setHeaderImage(headerImage);


    // this hides the Crisp Chat 
    /* $crisp.push(["do", "chat:hide"]) */

	},[appLanguage])




	const language = props.appLanguage;
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://community.reprocare.com/';
  } else if (language === 'es-US') {
    linkOne = 'https://community.reprocare.com/';
  }



	return(
		<>

<div className={styles.libraryAbortionMenuContainer} style={{ backgroundColor: 'white' }}>

	<TopNavBarNew backgroundColor="#4350E6" breadcrumbColor="#fff" />
	<div className={styles.headerContainer}>
				<HeaderCardNew
				  title={headerTitle}
				  image={headerImage}
				  backgroundColor="#4350E6"
				  titleColor="#fff"
				  borderRadius="0px"
				/>
</div>
		<div className={styles.extraSpace}></div>
		<div className='emotionalSupportContainer' style={{ backgroundColor: 'white', textDecoration: 'none' }}>


				<div className={styles.cardsContainer} style={{ backgroundColor: 'white', textDecoration: 'none' }}>
					
				<a href='/resources-legalassistance'>
						<BasicCard
							image={cardOneLogo}
							content={cardOneDescription}
							title={cardOneTitle}
							arrow={arrowIconTwo}
						/>
				</a>


			<a href='/resources-pillsandclinics'>
					<BasicCard
							image={cardTwoLogo}
							title={cardTwoTitle}
							content={cardTwoDescription}
							arrow={arrowIconTwo}
						/>
			</a>

			<a href='/resources-medicaladvice'>
					<BasicCard
							image={cardThreeLogo}
							title={cardThreeTitle}
							content={cardThreeDescription}
							arrow={arrowIconTwo}
						/>
			</a>

			<a href='/resources-financialassistance'>
					<BasicCard
							image={cardFourLogo}
							title={cardFourTitle}
							content={cardFourDescription}
							arrow={arrowIconTwo}
						/>
			</a>

			<a href='/resources-emotionalsupport'>
					<BasicCard
							image={cardFiveLogo}
							title={cardFiveTitle}
							content={cardFiveDescription}
							arrow={arrowIconTwo}
						/>
			</a>

			<a href='/resources-immigrants'>
					<BasicCard
							image={cardSixLogo}
							title={cardSixTitle}
							content={cardSixDescription}
							arrow={arrowIconTwo}
						/>
			</a>
			<div className={styles.moreMargin}>
			<div className='financial-help-subtitle'>{extraText}</div>
			</div>
			<BannerCard 
        title={bannerCardTitle} 
        description={bannerCardDescription}
        image={bannerCardImage}
        gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
        link="/chat-main"
        imageWidth="81px"
        imageHeight="80px"
        imagePosition="top-right"
        imageOffsetY=""
        imageOffsetX=""
        titleMaxWidth="256px"
        descriptionMaxWidth="286px"
        cardWidth="349px"
        cardHeight="88px"
        />

			</div>
			<div className='end-of-page'></div>
			{/* <BottomNavBar appLanguage={props.appLanguage} /> */}
		</div>
		<div className={styles.bottomSpace}></div>
		<BottomNavBarNew appLanguage={appLanguage}/>
		</div>
		</>
	)
}

export default ResourcesHome