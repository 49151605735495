import React, { useEffect, useState } from "react";
import styles from "./OnboardingOverUnder12Weeks.module.css";
import { processAssets } from "../../utils/contentProcessor";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function OnboardingUnder12Weeks({ appLanguage }) {
  const [pageState, setPageState] = useState({
    stepNumber: "",
    title: "",
    youHave: "",
    weeks: "",
    weeksPregnant: 0,
    paragraph: "",
    ctaCopy: "",
    icon: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Under 12 Weeks";

    const weeks = localStorage.getItem("pregnancyWeeks");
    setPageState((prevState) => ({ ...prevState, weeksPregnant: weeks }));

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets")) || [];

    const entry = entries.find((e) => e.sys.id === "QPge65tsz8EXHc3ZpN6ab");
    const processedAssets = processAssets(assets, appLanguage, {
      icon: "4Cia3s1hEHRIOwjv7JkYLE",
    });

    if (entry) {
      const fields = entry.fields;
      setPageState((prevState) => ({
        ...prevState,
        stepNumber: fields.stepNumber?.[appLanguage] || "",
        title: fields.title?.[appLanguage] || "",
        youHave: fields.youHave?.[appLanguage] || "",
        weeks: fields.weeks?.[appLanguage] || "",
        paragraph: fields.paragraph?.[appLanguage] || "",
        ctaCopy: fields.ctaCopy1?.[appLanguage] || "",
        icon: processedAssets?.icon || "",
      }));
    }
  }, [appLanguage]);

  return (
                <>
                    <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    <div className={styles.pageContainer}>
      {/* Top Section */}
      <div className={styles.topSection}>
        <h1 className={styles.title}>{pageState.title}</h1>
        <p className={styles.stepNumber}>{pageState.stepNumber}</p>
      </div>
      <div className={styles.whiteCard}>
        {pageState.icon && (
          <img src={pageState.icon} alt="Icon" className={styles.icon2} />
        )}
        <div className={styles.youHave}>
          {pageState.youHave} {pageState.weeksPregnant} {pageState.weeks}
        </div>
        <div className={styles.paragraph}>{pageState.paragraph}</div>
        <button
          className={styles.button2}
          onClick={() => (window.location.href = "/eligibility-question-one")}
        >
          {pageState.ctaCopy}
        </button>
      </div>
      <div className={styles.extraSpaceTop}></div>
<BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}