import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import FeedbackCard from '../../Components/FeedbackCard';
import BannerCardWithLinkEsVE from "../../Components/BannerCardWithLinkEsVE";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from '../AbortionWPills/PillAbortion.module.css';

export default function IUD({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    pageTitle: '',
    extraText: '',
    extraText2: '',
    extraText3: '',

    // Individual Large Cards
    largeCard1: {},
    largeCard2: {},
    largeCard3: {},
    largeCard4: {},
    largeCard5: {},
    largeCard6: {},

    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',
    smallCardTitle2: '',
    smallCardDescription2: '',

    // Banner Card
    bannerCardDescription1: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
    image2: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'IUD';
  
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));
  
    const abortionEntryId = '3LhTHyeUMxgNLI5PrExVBa'; 
    const largeCardEntryId = '21w4VWiKUsRRvuyc0NiDin';
  
    const processedEntries = processEntries(entries, appLanguage, {
      abortoConPastillas: {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          pageTitle: 'pageTitle',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          smallCardTitle2: 'smallCardTitle2',
          smallCardDescription2: 'smallCardDescription2',
          bannerCardDescription1: 'bannerCardDescription1',
        },
      },
      largeCardCollection: {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
          largeCardDescription4: 'largeCardDescription4',
          largeCardTitle5: 'largeCardTitle5',
          largeCardDescription5: 'largeCardDescription5',
          largeCardTitle6: 'largeCardTitle6',
          largeCardDescription6: 'largeCardDescription6',
          largeCardTitle7: 'largeCardTitle7',
        },
      },
    });
  
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '4PRStO660MxXni9z6sY8j8',
      arrowIconWhite: '5t0SZSeDrtBlIZ3o0Apjcu',
      arrowIconDark: 'h150pgUHcW5LIJwnFodWZ',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: '1bEIx9rLLpcIialMzn9ReQ',
      image2: '37tMKRcpZgj4LgRlz3qLgn',
    });

    // If you still want this check, keep it *inside* the effect:
    if (!processedAssets?.image2) {
      console.warn(`Asset 'image2' not found for language: ${appLanguage}`);
    }
  
    // Set state for the various sections of the page
    setPageState({
      // General Content
      headerTitle: processedEntries.abortoConPastillas?.headerTitle || '',
      pageTitle: processedEntries.abortoConPastillas?.pageTitle || '',
      extraText: processedEntries.abortoConPastillas?.extraText || '',
      extraText2: processedEntries.abortoConPastillas?.extraText2 || '',
      extraText3: processedEntries.abortoConPastillas?.extraText3 || '',
  
      // Banner Card
      bannerCardDescription1: processedEntries.abortoConPastillas?.bannerCardDescription1 || '',
  
      // Small Cards
      smallCardTitle1: processedEntries.abortoConPastillas?.smallCardTitle1 || '',
      smallCardDescription1: processedEntries.abortoConPastillas?.smallCardDescription1 || '',
      smallCardTitle2: processedEntries.abortoConPastillas?.smallCardTitle2 || '',
      smallCardDescription2: processedEntries.abortoConPastillas?.smallCardDescription2 || '',
  
      // Large Cards
      largeCard1: {
        ovalText: processedEntries.abortoConPastillas?.pageTitle || '',
        blueTitle: processedEntries.largeCardCollection?.largeCardTitle7 || '',
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle1 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription1 || '',
        images: [{ url: processedAssets.image1 || '' }],
      },
      largeCard2: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle2 || '',
      },
      largeCard3: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle3 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription3 || '',
      },
      largeCard4: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle4 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription4 || '',
      },
      largeCard5: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle5 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription5 || '',
      },
      largeCard6: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle6 || '',
        images: [{ url: processedAssets.image2 || '' }],
      },
  
      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
    });
  }, [appLanguage]);

  return (
    <div className={styles.libraryAbortionMenuContainer}>
      <TopNavBarNew backgroundColor="#E3F4FF" breadcrumbColor="#333" />
      
      <div className={styles.headerContainerContraception}>
        <HeaderCardNew
          title={pageState.headerTitle}
          image={pageState.headerImage}
          backgroundColor="#E3F4FF"
          borderRadius="0px"
        />
      </div>
      <div className={styles.bottomSpace}></div>

      <div className={styles.homePageContainer}>
        <FlexibleCard
          blueTitle={pageState.largeCard1.blueTitle}
          blackTitle={pageState.largeCard1.blackTitle}
          description={pageState.largeCard1.description}
          images={pageState.largeCard1.images}
          imagePosition="below-blue-title"
          imageWidth="142px"
          showOval
          ovalText={pageState.largeCard1.ovalText}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard2.blackTitle}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard3.blackTitle}
          description={pageState.largeCard3.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard4.blackTitle}
          description={pageState.largeCard4.description}
          width="351px"
          borderRadius="10px"
        />

        {/* Condom Link Card */}
        <BannerCardWithLinkEsVE
          linkPath="/contra-barrier-condom"
          descriptionEn="There is no protection against STIs with this method. Remember to use a condom to protect yourself."
          descriptionEs="No hay protección contra las ITS con este método. Recuerda usar un condón para protegerte."
        />

        <FlexibleCard
          blackTitle={pageState.largeCard5.blackTitle}
          description={pageState.largeCard5.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard6.blackTitle}
          images={pageState.largeCard6.images}
          imagePosition="below-black-title"
          width="351px"
          borderRadius="10px"
        />

        {/* Extra Text Section */}
        <div className={styles.extraTitle}>{pageState.extraText}</div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText2} appLanguage={appLanguage} />

        <div className={styles.leftAlignWrapper}>
          <div className={styles.extraTitletwo}>{pageState.extraText3}</div>

          {/* Render Small Cards */}
          {pageState.smallCardTitle1 && (
            <div className={styles.carouselContainer}>
              <CarouselSmallCard
                title={pageState.smallCardTitle1}
                extraText={pageState.smallCardDescription1}
                icon={pageState.arrowIconDark}
                backgroundColor="#E3F4FF"
                borderColor="#89D0FE"
                link="/contra-emergency-long-copperiud"
              />
              <CarouselSmallCard
                title={pageState.smallCardTitle2}
                extraText={pageState.smallCardDescription2}
                icon={pageState.arrowIconDark}
                backgroundColor="#E3F4FF"
                borderColor="#89D0FE"
                link="/contra-long-implant"
              />
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage} />
    </div>
  );
}