import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Onboarding3New.module.css"; // Reusing the same .module.css
import questionmarkIcon from "../../Images/questionmark.svg";
import PopUpEligibility from "../../Components/PopUpEligibility";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";

export default function Onboarding5New({ appLanguage }) {
  const [pageState, setPageState] = useState({
    stepNumber: "",
    title: "",
    title2: "",
    options: [],
    buttonText: "",
  });
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({
    icon: "",
    title: "",
    paragraph: "",
    buttonText: "OK",
  });

  const localStorageKey = "userSelectedOptions";
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Eligibility Quiz";

    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const abortionFlowEntry = entries?.find(
      (entry) => entry.sys.id === "1m4NJ9IH2hVzFfCw5UlLKT" // Onboarding5 ID
    );

    const fields = abortionFlowEntry?.fields || {};

    setPageState({
      stepNumber: fields.stepNumber?.[appLanguage] || "",
      title: fields.title?.[appLanguage] || "",
      title2: fields.title2?.[appLanguage] || "",
      options: [
        fields.optionText1?.[appLanguage] || "",
        fields.optionText2?.[appLanguage] || "",
        fields.optionText3?.[appLanguage] || "",
        fields.optionText4?.[appLanguage] || "",
      ],
      buttonText: fields.buttonText?.[appLanguage] || "",
    });

    setSelectedChoices([]);
  }, [appLanguage]);

  const handleChoiceClick = (index) => {
    const lastOptionIndex = pageState.options.length - 1;

    if (index === lastOptionIndex) {
      setSelectedChoices([index]);
    } else {
      if (selectedChoices.includes(lastOptionIndex)) {
        setSelectedChoices([index]);
      } else {
        setSelectedChoices((prev) =>
          prev.includes(index)
            ? prev.filter((choice) => choice !== index)
            : [...prev, index]
        );
      }
    }
  };

  const handleContinue = () => {
    const savedData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const currentPageData = selectedChoices.map((choice) => ({
      page: "Onboarding5New",
      option: `option${choice + 1}`,
      optionText: pageState.options[choice],
      isLastOption: choice === pageState.options.length - 1,
    }));

    const updatedData = [
      ...savedData.filter((data) => data.page !== "Onboarding5New"),
      ...currentPageData,
    ];

    localStorage.setItem(localStorageKey, JSON.stringify(updatedData));

    // Final check: if each page has exactly 1 item and it's "lastOption", success
    const allPages = ["Onboarding3New", "Onboarding4New", "Onboarding5New"];
    const selectedOptions = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const noConditionsSelected = allPages.every((page) => {
      const pageSelections = selectedOptions.filter((option) => option.page === page);
      return (
        pageSelections.length === 1 &&
        pageSelections[0].isLastOption === true
      );
    });

    if (noConditionsSelected) {
      console.log("Success: Navigating to eligibility-positive-result");
      history.push("/eligibility-positive-result");
    } else {
      console.log("Failed: Navigating to eligibility-negative-result");
      history.push("/eligibility-negative-result");
    }
  };

  const handleIconClick = (entryId) => {
    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const popupEntry = entries.find((entry) => entry.sys.id === entryId);

    if (popupEntry) {
      const fields = popupEntry.fields;
      setPopupContent({
        icon: fields.icon?.[appLanguage] || "",
        title: fields.title?.[appLanguage] || "",
        paragraph: fields.paragraph?.[appLanguage] || "",
        buttonText: "OK",
      });
      setShowPopup(true);
    }
  };

  return (
    <>
    <TopNavBarNew backgroundColor="#E12541" breadcrumbColor="#fff" />
    <div className={styles.pageContainer}>

      <div className={styles.topSection}>
        <h1 className={styles.title}>{pageState.title}</h1>
        <p className={styles.stepNumber}>{pageState.stepNumber}</p>
      </div>
      <div className={styles.whiteCard}>
        <h2 className={styles.title2}>{pageState.title2}</h2>
        {pageState.options.map((option, index) => (
          <div key={index} className={styles.optionRow}>
            <div
              className={`${styles.choice} ${
                selectedChoices.includes(index) ? styles.choiceSelected : ""
              }`}
              onClick={() => handleChoiceClick(index)}
            >
              <span>{option}</span>
            </div>
            {(index === 0 || index === 1 || index === 2) && (
              <img
                src={questionmarkIcon}
                alt="info"
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    index === 0
                      ? "7cdYJaYkm1scjLzgl2aPK4" // First question mark entry ID
                      : index === 1
                      ? "6jVC1k3oxD0vGoijYVpjE0" // Second question mark entry ID
                      : "2WRSLhnqZBb2SksQgzucpr" // Third question mark entry ID
                  );
                }}
              />
            )}
          </div>
        ))}
        <button
          className={`${styles.continueButton} ${
            selectedChoices.length > 0 ? styles.continueButtonActive : ""
          }`}
          onClick={selectedChoices.length > 0 ? handleContinue : null}
        >
          {pageState.buttonText}
        </button>
      </div>

      {/* Popup */}
      <PopUpEligibility
        show={showPopup}
        icon={popupContent.icon}
        title={popupContent.title}
        paragraph={popupContent.paragraph}
        buttonText={popupContent.buttonText}
        onClose={() => setShowPopup(false)}
      />
            <div className={styles.bottomSpace}></div>
            <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
    </>
  );
}