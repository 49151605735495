import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function BasicCard(props) {
  const { title, content, image, arrow } = props;

  const cardStyle = {
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '12px',
    width: '100%',
    maxWidth: '360px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    gap: '10px', // Ensures internal spacing
    marginBottom: '12px', // ✅ Adds spacing between cards
  };

  const imageStyle = {
    width: 'auto', // Smaller image
    height: '25px',
    flexShrink: 0,
    position: 'relative',
    top: '-30px',  // Moves image slightly upwards
  };

  const contentContainerStyle = {
    flexGrow: 1, 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  };

  const titleStyle = {
    color: '#4350E6',
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
  };

  const contentStyle = {
    color: '#524F4F',
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '19px',
    marginTop: '4px',
    textAlign: 'left',
    textDecoration: 'none',
  };

  const arrowStyle = {
    width: '20px',
    height: '20px',
    flexShrink: 0,
    position: 'relative',
    top: '-30px', 
  };

  return (
    <Card style={cardStyle}>
      <img src={image} alt="Icon" style={imageStyle} />
      <div style={contentContainerStyle}>
      <Typography variant="h6" style={titleStyle}>
  <span style={{ textDecoration: 'none' }}>{title}</span>
</Typography>
<Typography variant="body2" style={contentStyle}>
  <span style={{ textDecoration: 'none' }}>{content}</span>
</Typography>
      </div>
      <img src={arrow} alt="Arrow" style={arrowStyle} />
    </Card>
  );
}

export default BasicCard;