import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../utils/contentProcessor';
import styles from './FeedbackCard.module.css';

export default function FeedbackCard({ title, appLanguage }) {
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry ID for the Feedback Card Content
    const abortionEntryId = '4Dmy3lcPoFAsjTkHrK3bMd';

    // Process entries and assets
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          smileyDescriptionHappy: 'smileyDescriptionHappy',
          smileyDescriptionNeutral: 'smileyDescriptionNeutral',
          smileyDescriptionSad: 'smileyDescriptionSad',
        }
      },
    });

    const processedAssets = processAssets(assets, appLanguage, {
      smileyIconHappy: "6zfJzBuo2ZX8OOo9Yy3DR4",
      smileyIconNeutral: "XMBUllUtQdyYMf98WkSAY",
      smileyIconSad: "13CmxYjsuSPjNtXHqQcGoY",
      smileyIconHappyRed: "6WHgogMX4FlBPgWGNKGlL1",
      smileyIconNeutralRed: "7BVY27vwwjRXJyfmfeh6C5",
      smileyIconSadRed: "6dz9KewFsMWN5hZJS5S4ab",
    });

    // Define hardcoded fallback translations
    const translations = {
      en: {
        happy: "Very useful",
        neutral: "Normal",
        sad: "I didn't like",
      },
      "es-US": {
        happy: "Muy útil",
        neutral: "Normal",
        sad: "No me gustó",
      }
    };
    
    const selectedLang = translations[appLanguage] || translations["es-US"]; // Default to Spanish
    
    setFeedbackOptions([
      {
        label: selectedLang.happy,
        iconBlue: processedAssets?.smileyIconHappy || '',
        iconRed: processedAssets?.smileyIconHappyRed || '',
      },
      {
        label: selectedLang.neutral,
        iconBlue: processedAssets?.smileyIconNeutral || '',
        iconRed: processedAssets?.smileyIconNeutralRed || '',
      },
      {
        label: selectedLang.sad,
        iconBlue: processedAssets?.smileyIconSad || '',
        iconRed: processedAssets?.smileyIconSadRed || '',
      },
    ]);
  }, [appLanguage]);

  const handleFeedbackClick = (optionLabel) => {
    if (selectedOption) {
      return;
    }

    setSelectedOption(optionLabel);

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'feedback_click', {
        event_category: 'Feedback',
        event_label: optionLabel,
        page_path: window.location.pathname,
      });
    } else {
      console.warn('Google Analytics is not available.');
    }
  };

  return (
    <div className={styles.feedbackCardContainer}>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.iconsContainer}>
        {feedbackOptions.map((option, index) => (
          <button
            key={index}
            className={`${styles.iconCard} ${
              selectedOption === option.label ? styles.selected : ''
            }`}
            onClick={() => handleFeedbackClick(option.label)}
            aria-label={`Feedback option: ${option.label}`}
            disabled={selectedOption !== null}
          >
            <img
              src={
                selectedOption === option.label
                  ? option.iconRed 
                  : option.iconBlue
              }
              alt={option.label}
              className={styles.iconImage}
            />
            <div className={styles.iconLabel}>
              {option.label}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}