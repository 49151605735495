import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from "./LanguageChange.module.css";

function LanguageChange() {
  const initialLang = localStorage.getItem("language") || null; // No selection on load
  const [selectedLanguage, setSelectedLanguage] = useState(initialLang);
  const [shortText1, setShortText1] = useState("Español"); // Default to Spanish
  const [shortText2, setShortText2] = useState("English"); // Default to English
  const [appLanguage, setAppLanguage] = useState(initialLang || "en"); // Ensures appLanguage is always set

  useEffect(() => {
    ReactGA.send("pageview", "/language");
    document.title = selectedLanguage === "es-US" ? "Cambiar idioma" : "Change Language";

    const entriesJSON = localStorage.getItem("ayaAppEntries");
    if (!entriesJSON) return;

    const entries = JSON.parse(entriesJSON);
    const getContent = entries.find(
      (obj) => obj.sys.contentType.sys.id === "simplistic" && obj.sys.id === "5UP9IidLYxE6A3HacJ9w1B"
    );

    if (getContent) {
      setShortText1(getContent.fields.shortText1?.["es-US"] || "Español");
      setShortText2(getContent.fields.shortText2?.["en"] || "English");
    }
  }, [selectedLanguage]);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    setAppLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  const handleConfirm = () => {
    if (selectedLanguage) {
      alert(selectedLanguage === "en" ? "Language preference saved!" : "¡Preferencia de idioma guardada!");
      window.location.reload();
    }
  };

  return (
    <div>
      <TopNavBarNew backgroundColor="#fff" breadcrumbColor="#333" />
      <div className={styles.languageChangeContent}>
        <h1 className={styles.languagePageTitle}>
          {selectedLanguage === "es-US" ? "Cambiar idioma" : "Change Language"}
        </h1>

        <p className={styles.languageIntroText}>
          {selectedLanguage === "es-US"
            ? "Por favor, seleccione su idioma preferido a continuación."
            : "Please select your preferred language below."}
        </p>

        <div className={styles.languageOptionsContainer}>
          <label className={`${styles.languageSelection} ${selectedLanguage === "es-US" ? styles.selected : ""}`}>
            <span className={styles.languageText}>
              {selectedLanguage === "es-US" ? "Español" : "Spanish"}
            </span>
            <input
              type="radio"
              name="language"
              value="es-US"
              checked={selectedLanguage === "es-US"}
              onChange={() => handleLanguageChange("es-US")}
            />
            <span className={styles.checkmark}></span>
          </label>

          <label className={`${styles.languageSelection} ${selectedLanguage === "en" ? styles.selected : ""}`}>
            <span className={styles.languageText}>
              {selectedLanguage === "es-US" ? "Inglés" : "English"}
            </span>
            <input
              type="radio"
              name="language"
              value="en"
              checked={selectedLanguage === "en"}
              onChange={() => handleLanguageChange("en")}
            />
            <span className={styles.checkmark}></span>
          </label>
        </div>

        <button
          className={styles.confirmButton}
          onClick={handleConfirm}
          disabled={!selectedLanguage}
        >
          {selectedLanguage === "es-US" ? "Confirmar" : "Confirm"}
        </button>
      </div>

      {/* Pass appLanguage safely to BottomNavBarNew */}
      <BottomNavBarNew appLanguage={appLanguage} />
    </div>
  );
}

export default LanguageChange;