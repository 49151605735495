import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import BasicAccordionRounded from '../../Components/BasicAccordionRounded';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './MythsAndRealities.module.css';

export default function MythsAndRealities({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    subTitle1: '',
    subTitle2: '',


    // Assets
    image1: '',

    // Accordion Data
    accordions: [],
    accordions2: [],
  });

  const [expanded, setExpanded] = useState(false); // State to manage which accordion is expanded

  // TO DO: check if this function is needed
/*   const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }; */

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Myths and Realities';

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const largeCardEntryId = 'gRWWDTjS5H0pC4079yYg4';
    const largeCardEntryId2 = 'GQgJ2Np2HJ0uVZ0xn7ERs';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          subTitle1: 'subTitle1',
          subTitle2: 'subTitle2',
          narrowCardTitle1: 'narrowCardTitle1',
          narrowCardDescription1: 'narrowCardDescription1',
          narrowCardTitle2: 'narrowCardTitle2',
          narrowCardDescription2: 'narrowCardDescription2',
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
          largeCardDescription4: 'largeCardDescription4',
          largeCardTitle5: 'largeCardTitle5',
          largeCardDescription5: 'largeCardDescription5',
          largeCardTitle6: 'largeCardTitle6',
          largeCardDescription6: 'largeCardDescription6',
          largeCardTitle7: 'largeCardTitle7',
          largeCardDescription7: 'largeCardDescription7',
          largeCardTitle8: 'largeCardTitle8',
          largeCardDescription8: 'largeCardDescription8',
          largeCardTitle9: 'largeCardTitle9',
          largeCardDescription9: 'largeCardDescription9',
          largeCardTitle10: 'largeCardTitle10',
          largeCardDescription10: 'largeCardDescription10',
        },
      },
    });
        // Process the entries from Contentful for all content types
        const processedEntries2 = processEntries(entries, appLanguage, {
          'largeCardCollection': {
            entryId: largeCardEntryId2,
            fieldsMapping: {
              headerTitle: 'headerTitle',
              subTitle1: 'subTitle1',
              subTitle2: 'subTitle2',
              narrowCardTitle1: 'narrowCardTitle1',
              narrowCardDescription1: 'narrowCardDescription1',
              narrowCardTitle2: 'narrowCardTitle2',
              narrowCardDescription2: 'narrowCardDescription2',
              largeCardTitle1: 'largeCardTitle1',
              largeCardDescription1: 'largeCardDescription1',
              largeCardTitle2: 'largeCardTitle2',
              largeCardDescription2: 'largeCardDescription2',
              largeCardTitle3: 'largeCardTitle3',
              largeCardDescription3: 'largeCardDescription3',
              largeCardTitle4: 'largeCardTitle4',
              largeCardDescription4: 'largeCardDescription4',
              largeCardTitle5: 'largeCardTitle5',
              largeCardDescription5: 'largeCardDescription5',
              largeCardTitle6: 'largeCardTitle6',
              largeCardDescription6: 'largeCardDescription6',
              largeCardTitle7: 'largeCardTitle7',
              largeCardDescription7: 'largeCardDescription7',
              largeCardTitle8: 'largeCardTitle8',
              largeCardDescription8: 'largeCardDescription8',
              largeCardTitle9: 'largeCardTitle9',
              largeCardDescription9: 'largeCardDescription9',
              largeCardTitle10: 'largeCardTitle10',
              largeCardDescription10: 'largeCardDescription10',
              largeCardTitle11: 'largeCardTitle11',
              largeCardDescription11: 'largeCardDescription11', 
              largeCardTitle12: 'largeCardTitle12',
              largeCardDescription12: 'largeCardDescription12',
              largeCardTitle13: 'largeCardTitle13',
              largeCardDescription13: 'largeCardDescription13',
            },
          },
        });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      image1: '44wsWgh3dcJZmeSVDcRbN7',
    });

    // Create an array of accordion data
    const accordions = Array.from({ length: 10 }, (_, index) => ({
      title: processedEntries['largeCardCollection']?.[`largeCardTitle${index + 1}`] || '',
      description: processedEntries['largeCardCollection']?.[`largeCardDescription${index + 1}`] || '',
    }));

        // Create an array of accordion data
        const accordions2 = Array.from({ length: 13 }, (_, index) => ({
          title: processedEntries2['largeCardCollection']?.[`largeCardTitle${index + 1}`] || '',
          description: processedEntries2['largeCardCollection']?.[`largeCardDescription${index + 1}`] || '',
        }));

    // Set state for the various sections of the page
    setPageState({
      // General Content
      headerTitle: processedEntries['largeCardCollection']?.headerTitle || '',
      subTitle1: processedEntries['largeCardCollection']?.subTitle1 || '',
      subTitle2: processedEntries['largeCardCollection']?.subTitle2 || '',


      // Accordions
      accordions,
      accordions2,

      // Assets
      image1: processedAssets?.image1 || '',
    });
  }, [appLanguage]);

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.libraryAbortionMenuContainer}>
                      <TopNavBarNew backgroundColor="#4350E6" breadcrumbColor="#fff" />
        {/* Header Section */}
        <HeaderCardNew
          title={pageState.headerTitle}
          image={pageState.image1}
          backgroundColor="#4350E6"
          titleColor="#fff"
          borderRadius="0px"
        />
        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.subTitle2}</div>
        </div>
        {/* Accordions */}
        <div className={styles.accordionWrapper}>
          {pageState.accordions.map((accordion, index) => (
            <BasicAccordionRounded
              key={index}
              heading={accordion.title}
              content={accordion.description}
              expanded={expanded === index} // Manage expanded state for each accordion
              onChange={(event, isExpanded) => setExpanded(isExpanded ? index : false)}
              expandIcon={styles.expandIcon}
            />
          ))}
        </div>
        <div className={styles.leftAlignWrapper}>
        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.subTitle1}</div>
        </div>

        <div className={styles.accordionWrapper}>
          {pageState.accordions2.map((accordion, index) => (
            <BasicAccordionRounded
            expandIcon={<ExpandMoreIcon sx={{ fontSize: 30 }} />}
              key={index}
              heading={accordion.title}
              content={accordion.description}
              expanded={expanded === index} // Manage expanded state for each accordion
              onChange={(event, isExpanded) => setExpanded(isExpanded ? index : false)}
            />
          ))}
        </div>
      </div>
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
