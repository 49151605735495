export const processEntries = (entries, appLanguage, contentTypeConfig) => {
  // 1) Safeguard against empty entries
  if (!entries || !Array.isArray(entries) || entries.length === 0) {
    console.warn(
      "processEntries: No entries found (possibly incognito or no data in localStorage). Returning empty object."
    );
    return {};
  }

  let processedEntries = {};

  entries.forEach((entry) => {
    const contentTypeID = entry.sys.contentType.sys.id;
    const config = contentTypeConfig[contentTypeID];
    if (!config) return; // Not a content type we care about

    const entryId = config.entryId;
    if (entryId && entry.sys.id !== entryId) {
      // If a specific entryId is required but doesn't match, skip
      return;
    }

    const fieldsMapping = config.fieldsMapping;

    // If we haven't created a container for this content type, do so
    if (!processedEntries[contentTypeID]) {
      // If there's a single entryId, store an object; otherwise store an array
      processedEntries[contentTypeID] = entryId ? {} : [];
    }

    // 2) Actually map the fields
    let processedEntry = {};
    Object.keys(fieldsMapping).forEach((field) => {
      const contentfulFieldName = fieldsMapping[field];
      // If the field doesn't exist, default to empty string
      if (!entry.fields[contentfulFieldName]) {
        processedEntry[field] = "";
      } else {
        // If the field does exist, pick the correct locale or fallback
        processedEntry[field] =
          entry.fields[contentfulFieldName][appLanguage] ||
          entry.fields[contentfulFieldName]["en"] || // fallback to 'en'
          "";
      }
    });

    // 3) Store the mapped entry in our processed result
    if (entryId) {
      processedEntries[contentTypeID] = processedEntry;
    } else {
      processedEntries[contentTypeID].push(processedEntry);
    }
  });

  return processedEntries;
};

export const processAssets = (assets, appLanguage, assetsMapping) => {
  // 1) Safeguard against empty assets
  if (!assets || !Array.isArray(assets) || assets.length === 0) {
    console.warn(
      "processAssets: No assets found (possibly incognito or no data). Returning empty object."
    );
    return {};
  }

  let processedAssets = {};

  const extractUrl = (asset) => {
    if (!asset || !asset.fields || !asset.fields.file) return "";
    const fileField = asset.fields.file;

    // 2) Attempt to use the user's chosen language, fallback to 'en', then fallback to first object
    const localizedFile =
      fileField[appLanguage] ||
      fileField["en"] ||
      Object.values(fileField)[0];

    return localizedFile?.url ? `https:${localizedFile.url}` : "";
  };

  // 3) For each mapped key, find the correct asset by ID, then extract the localized URL
  Object.keys(assetsMapping).forEach((key) => {
    const asset = assets.find((a) => a.sys.id === assetsMapping[key]);
    const url = extractUrl(asset);
    processedAssets[key] = url;
  });

  return processedAssets;
};