import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { processEntries, processAssets } from "../../utils/contentProcessor";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw"; // ✅ Enables inline HTML
import rehypeSanitize from "rehype-sanitize";
import styles from "./IntroPages.module.css";


const LOCALES = {
  en: "en",
  esUS: "es-US",
  esVE: "es-VE",
};

export default function IntroPage({ appLanguage }) {
  const [pageState, setPageState] = useState({
    titleLong: "",
    image: "",
    button: "",
  });

  const history = useHistory();

  useEffect(() => {
    const storedLanguage = mapLocale(localStorage.getItem("ayaAppLanguage") || appLanguage);
    console.log("Detected language from localStorage or props:", storedLanguage);
    localStorage.setItem("ayaAppLanguage", storedLanguage);

    loadPageContent(storedLanguage);
  }, [appLanguage]);

  const mapLocale = (language) => {
    console.log("Mapping locale for language:", language);
    switch (language) {
      case "en":
        return LOCALES.en;
      case "es-US":
        return LOCALES.esUS;
      case "es-VE":
      default:
        return LOCALES.esVE;
    }
  };

  const loadPageContent = (locale) => {
    const entries = JSON.parse(localStorage.getItem("ayaAppEntries"));
    const assets = JSON.parse(localStorage.getItem("ayaAppAssets"));

    if (!entries || !assets) {
      console.warn("Entries or assets are missing from localStorage. Using default fallback content.");
      setPageState({
        titleLong: "Welcome to the Aya App!",
        button: "Next",
        image: "",
      });
      return;
    }

    const entryId = "328eMvsrpibXT1OUpzm6iN";
    const assetsMapping = { image: "FTnBlKSPlE3VjUjdKyw74" };

    const processedEntries = processEntries(entries, locale, {
      introPage: {
        entryId,
        fieldsMapping: {
          titleLong: "titleLong",
          button: "button",
        },
      },
    });

    const processedAssets = processAssets(assets, locale, assetsMapping);

    setPageState({
      titleLong: processedEntries.introPage?.titleLong || "Default Title",
      button: processedEntries.introPage?.button || "Next",
      image: processedAssets?.image || "",
    });
  };

  const goToNextPage = () => {
    history.push("/onboarding-benefits");
  };

  return (
    <div className={styles.container} id="scroll">
      <div className={styles.centerContent}>
        {/* ✅ Use react-markdown with rehypeRaw for inline HTML */}
        <ReactMarkdown 
          className={styles.title}
          children={pageState.titleLong}
          rehypePlugins={[
            rehypeRaw,
            [rehypeSanitize, { attributes: { "*": ["style"] } }], // Allow all inline styles
          ]}
          allowDangerousHtml={true}
        />

        {pageState.image && (
          <div className={styles.imageContainer}>
            <img src={pageState.image} alt="Intro" className={styles.image} />
          </div>
        )}
        
        <button onClick={goToNextPage} className={styles.whiteButton}>
          {pageState.button}
        </button>
      </div>
    </div>
  );
}