import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import CallToActionCard from '../../Components/CallToActionCard';
import FeedbackCard from '../../Components/FeedbackCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './PillAbortion.module.css';

export default function PillAbortionBeforeOne({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    extraText: '',
    extraText2: '',
    extraText3: '',
    narrowCardTitle1: '',
    narrowCardTitle2: '',
    
    // Individual Large Cards
    largeCard1: {},
    largeCard2: {},
    largeCard3: {},
    largeCard4: {},
    largeCard5: {},
    largeCard6: {},
    largeCardTitle7: '',

    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',
    smallCardTitle2: '',
    smallCardDescription2: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
  });

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = "Pill Abortion Before One";
  
    // ✅ Store localStorage values in constants inside useEffect
    const storedEntries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const storedAssets = JSON.parse(localStorage.getItem("ayaAppAssets")) || [];
  
    // Define entry IDs
    const abortionEntryId = "4Dmy3lcPoFAsjTkHrK3bMd";
    const largeCardEntryId = "EuqlDM97hYXNTP5WSbIDN";
  
    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(storedEntries, appLanguage, { 
      abortoConPastillas: {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: "headerTitle",
          extraText: "extraText",
          extraText2: "extraText2",
          extraText3: "extraText3",
          narrowCardTitle1: "narrowCardTitle1",
          narrowCardTitle2: "narrowCardTitle2",
          smallCardTitle1: "smallCardTitle1",
          smallCardDescription1: "smallCardDescription1",
          smallCardTitle2: "smallCardTitle2",
          smallCardDescription2: "smallCardDescription2",
        },
      },
      largeCardCollection: {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: "largeCardTitle1",
          largeCardDescription1: "largeCardDescription1",
          largeCardTitle2: "largeCardTitle2",
          largeCardDescription2: "largeCardDescription2",
          largeCardTitle3: "largeCardTitle3",
          largeCardDescription3: "largeCardDescription3",
          largeCardTitle4: "largeCardTitle4",
          largeCardDescription4: "largeCardDescription4",
          largeCardTitle5: "largeCardTitle5",
          largeCardDescription5: "largeCardDescription5",
          largeCardTitle6: "largeCardTitle6",
          largeCardDescription6: "largeCardDescription6",
          largeCardTitle7: "largeCardTitle7",
        },
      },
    });
  
    // Process the assets from Contentful
    const processedAssets = processAssets(storedAssets, appLanguage, {
      headerImage: "01OKOkJKnkhbdVZXksKyej",
      arrowIconWhite: "5t0SZSeDrtBlIZ3o0Apjcu",
      arrowIconDark: "35s9SZBtTGy9KGs0lc41lt",
      heartIcon: "740H3V1s40GxiaZiqj79MY",
      image1: "3gsxD7FGMYkia0b3oFsh53",
      image2: "5lSOU908i8ZJNnI7bTyqU6",
      image3: "1zhQIRV6YYP7GDKUvxcBJW",
      image4: "73zXRFffROfuLw3F5ClHXA",
    });
  
    // Set state for the various sections of the page
    setPageState({
      headerTitle: processedEntries["abortoConPastillas"]?.headerTitle || "",
      extraText: processedEntries["abortoConPastillas"]?.extraText || "",
      extraText2: processedEntries["abortoConPastillas"]?.extraText2 || "",
      extraText3: processedEntries["abortoConPastillas"]?.extraText3 || "",
      narrowCardTitle1: processedEntries["abortoConPastillas"]?.narrowCardTitle1 || "",
      narrowCardTitle2: processedEntries["abortoConPastillas"]?.narrowCardTitle2 || "",
      smallCardTitle1: processedEntries["abortoConPastillas"]?.smallCardTitle1 || "",
      smallCardDescription1: processedEntries["abortoConPastillas"]?.smallCardDescription1 || "",
      smallCardTitle2: processedEntries["abortoConPastillas"]?.smallCardTitle2 || "",
      smallCardDescription2: processedEntries["abortoConPastillas"]?.smallCardDescription2 || "",
      largeCard1: {
        ovalText: processedEntries.largeCardCollection?.largeCardTitle7 || "",
        blueTitle: processedEntries.largeCardCollection?.largeCardTitle1 || "",
        description: processedEntries.largeCardCollection?.largeCardDescription1 || "",
        images: [{ url: processedAssets.image1 || "" }],
      },
      largeCard2: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle2 || "",
        description: processedEntries.largeCardCollection?.largeCardDescription2 || "",
      },
      largeCard3: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle3 || "",
        description: processedEntries.largeCardCollection?.largeCardDescription3 || "",
      },
      largeCard4: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle4 || "",
        description: processedEntries.largeCardCollection?.largeCardDescription4 || "",
      },
      largeCard5: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle5 || "",
        description: processedEntries.largeCardCollection?.largeCardDescription5 || "",
        images: [{ url: processedAssets.image3 || "" }],
      },
      largeCard6: {
        blackTitle: processedEntries.largeCardCollection?.largeCardTitle6 || "",
        description: processedEntries.largeCardCollection?.largeCardDescription6 || "",
        images: [{ url: processedAssets.image4 || "" }],
      },
      headerImage: processedAssets?.headerImage || "",
      arrowIconWhite: processedAssets?.arrowIconWhite || "",
      arrowIconDark: processedAssets?.arrowIconDark || "",
      heartIcon: processedAssets?.heartIcon || "",
      image1: processedAssets?.image1 || "",
      image2: processedAssets?.image2 || "",
      image3: processedAssets?.image3 || "",
      image4: processedAssets?.image4 || "",
    });
  }, [appLanguage]);
  
  return (
    
      <div className={styles.libraryAbortionMenuContainer}>
      <TopNavBarNew backgroundColor="#FDE8D8" breadcrumbColor="#333" />
        {/* Header Section */}
        <div className={styles.headerContainer2}>
          <HeaderCardNew
            title={pageState.headerTitle}
            image={pageState.headerImage}
            backgroundColor="#FDE8D8"
            borderRadius="0px"
          />
          <div className={styles.bottomSpaceHeader}></div>
       
        </div>
        <div className={styles.homePageContainer}>
        {/* Render Individual Large Cards */}
        <FlexibleCard   
          blueTitle={pageState.largeCard1.blueTitle}
          description={pageState.largeCard1.description}
          images={pageState.largeCard1.images}
          imagePosition="below-description"
          imageHeight="77px"
          showOval={true}
          ovalText={pageState.largeCard1.ovalText}
          width="351px"
          borderRadius="10px"
        />

        {/* Render Individual Large Cards */}
        <FlexibleCard
          blackTitle={pageState.largeCard2.blackTitle}
          description={pageState.largeCard2.description}
          width="351px"
          borderRadius="10px"
        />

{/*         <FlexibleCard
          blackTitle={pageState.largeCard3.blackTitle}
          description={pageState.largeCard3.description}
          width="351px"
          borderRadius="10px"
        /> */}

        <FlexibleCard
          blackTitle={pageState.largeCard4.blackTitle}
          description={pageState.largeCard4.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard5.blackTitle}
          description={pageState.largeCard5.description}
          images={pageState.largeCard5.images}
          imagePosition="below-description"
          imageHeight="90px"
          width="351px"
          borderRadius="10px"
        />

        {/* Call To Action Card 1 */}
        <CallToActionCard
          title={pageState.narrowCardTitle1}
          icon={pageState.arrowIconWhite}
          showIcon={true}
          isLink={true}
          buttonLink="/abor-during-usemifeandmiso"
          useCardGradient={true}
          titleColor="#fff"
          cardGradient="linear-gradient(90deg, #E12541 0%, #F85833 100%)"
          width="356px"
          height="52px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard6.blackTitle}
          description={pageState.largeCard6.description}
          images={pageState.largeCard6.images}
          imagePosition="below-description"
          imageHeight="130px"
          width="351px"
          borderRadius="10px"
        />

        {/* Call To Action Card 2 */}
        <CallToActionCard
          title={pageState.narrowCardTitle2}
          icon={pageState.arrowIconWhite}
          showIcon={true}
          isLink={true}
          buttonLink="/abor-during-usemiso"
          useCardGradient={true}
          titleColor="#fff"
          cardGradient="linear-gradient(90deg, #E12541 0%, #F85833 100%)"
          width="356px"
          height="52px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />

        {/* Extra Text Section */}
        <div className={styles.extraTitle}>{pageState.extraText}</div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText3} appLanguage={appLanguage} />


        <div className={styles.leftAlignWrapper}>

        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.extraText2}</div>

        </div>

        <div className={styles.leftAlignWrapper}>
                {/* Render Small Cards Section */}
                {pageState.smallCardTitle1 && (
          <div className={styles.carouselContainer}>
            <CarouselSmallCard
              title={pageState.smallCardTitle1}
              extraText={pageState.smallCardDescription1}
              icon={pageState.arrowIconDark}
              link="/abor-before-whattoexpect"
            />
            <CarouselSmallCard
              title={pageState.smallCardTitle2}
              extraText={pageState.smallCardDescription2}
              icon={pageState.arrowIconDark}
              link="/abor-before-howtopreparemyself"
            />
          </div>
        )}
        </div>

      
      </div>
      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
