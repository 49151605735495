import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './BannerCardWithLink.module.css';


export default function BannerCardWithLinkEsVE({
  title,
  linkPath, // Internal link path (same for both languages)
  descriptionEn, // English description
  descriptionEs, // Spanish description
  backgroundColor,
  gradient,
  cardWidth = '349px',
  cardHeight = '88px',
}) {
  const [appLanguage, setAppLanguage] = useState('en'); // Default language


  useEffect(() => {
    const storedLanguage = localStorage.getItem('appLanguage') || 'en';
    setAppLanguage(storedLanguage);
  }, []);


  // ✅ Choose the correct language for the description
  const description = appLanguage === 'es-US' ? descriptionEs : descriptionEn;


  return (
    <div
      className={styles.bannerCardContainer}
      style={{
        background: gradient || backgroundColor || "linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)",
        height: cardHeight,
        width: cardWidth,
      }}
    >
      <div className={styles.bannerTextContainer}>
        <div className={styles.bannerTitle}>{title}</div>


        <div className={styles.bannerText}>
          {/* ✅ Ensure the link wraps only the word "condón" */}
          {description.split(' ').map((word, index) =>
            word.toLowerCase().includes('condón') || word.toLowerCase().includes('condom') ? (
              <Link key={index} to={linkPath} className={styles.link}>
                {word}
              </Link>
            ) : (
              <span key={index}> {word} </span>
            )
          )}
        </div>
      </div>
    </div>
  );
}
