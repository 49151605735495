import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import CustomButton from '../../Components/CustomButton';
import styles from './IntroPageSurvey.module.css';

export default function IntroPageSurvey({ appLanguage }) {
  const history = useHistory();

  const [pageState, setPageState] = useState({
    title: '',
    radiobutton1: '',
    radiobutton2: '',
    radiobutton3: '',
    radiobutton4: '',
    radiobutton5: '',
    radiobutton6: '',
    radiobutton7: '',
    radiobutton8: '',
    radioButton9: '',
    button: '',
    bottomText: '',
    image: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Intro Page Survey';

    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    const assetsMapping = {
      image: "6QS3V4gBxx7vzf4qftDcbh", 
    };

    const entryId = "2LeKc6qALm1oZJFnguzC4l";

    const processedEntries = processEntries(entries, appLanguage, {
      'introPage6': {
        entryId: entryId,
        fieldsMapping: {
          title: 'title',
          radiobutton1: 'radiobutton1',
          radiobutton2: 'radiobutton2',
          radiobutton3: 'radiobutton3',
          radiobutton4: 'radiobutton4',
          radiobutton5: 'radiobutton5',
          radiobutton6: 'radiobutton6',
          radiobutton7: 'radiobutton7',
          radiobutton8: 'radiobutton8',
          radioButton9: 'radioButton9',
          button: 'button',
          bottomText: 'bottomText',
        }
      },
    });

    console.log("Fetched RadioButton1:", processedEntries['introPage6'].radiobutton1); // Debugging line

    const processedAssets = processAssets(assets, appLanguage, assetsMapping);

    setPageState({
      title: processedEntries['introPage6'].title || 'Default Title',
      radiobutton1: processedEntries['introPage6'].radiobutton1 || 'Option 1',
      radiobutton2: processedEntries['introPage6'].radiobutton2 || 'Option 2',
      radiobutton3: processedEntries['introPage6'].radiobutton3 || 'Option 3',
      radiobutton4: processedEntries['introPage6'].radiobutton4 || 'Option 4',
      radiobutton5: processedEntries['introPage6'].radiobutton5 || 'Option 5',
      radiobutton6: processedEntries['introPage6'].radiobutton6 || 'Option 6',
      radiobutton7: processedEntries['introPage6'].radiobutton7 || 'Option 7',
      radiobutton8: processedEntries['introPage6'].radiobutton8 || 'Option 8',
      radioButton9: processedEntries['introPage6'].radioButton9 || 'Option 9',
      button: processedEntries['introPage6'].button || 'Continue',
      bottomText: processedEntries['introPage6'].bottomText || '',
      image: processedAssets.image || '',
    });
  }, [appLanguage]);

  const goToNextPage = () => {
    localStorage.setItem('hasSeenIntro', 'true');
    history.push('/home');  // Replace with your actual next page route
  };

  return (
    <div className={`${styles.flexColumn} ${styles.growFullHeight} ${styles.pagesInnerDiv}`} id="scroll">
{/*       <img src={pageState.image} alt="Survey" className={styles.image} />
 */}      <div className={styles.centerContent}>
          <img src={pageState.image} alt="Survey" className={styles.image} />

        <div className={styles.title} dangerouslySetInnerHTML={{ __html: pageState.title }} />
        <div className={styles.radioGroup}>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton1} />
            {pageState.radiobutton1}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton2} />
            {pageState.radiobutton2}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton3} />
            {pageState.radiobutton3}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton4} />
            {pageState.radiobutton4}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton5} />
            {pageState.radiobutton5}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton6} />
            {pageState.radiobutton6}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton7} />
            {pageState.radiobutton7}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radiobutton8} />
            {pageState.radiobutton8}
          </label>
          <label className={styles.radioLabel}>
            <input type="radio" name="survey" value={pageState.radioButton9} />
            {pageState.radioButton9}
          </label>
        </div>
        <CustomButton 
          text={pageState.button} 
          onClick={goToNextPage} 
          className={styles.blueButton}
        />
        <p onClick={goToNextPage} className={styles.bottomText}>{pageState.bottomText}</p>
      </div>
    </div>
  );
}
