import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TopNavbarNew from '../../Components/TopNavBarNew';
import { processAssets } from "../../utils/contentProcessor"; // Only used for the image
import styles from './IntroPagePrivacy.module.css';

export class IntroPage5Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: true,
      checkboxChecked: false,
      headerImage: '', // Fetched from Contentful
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'Onboarding Privacy';

    // Fetch assets (header image) from Contentful
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets')) || [];
    const assetsMapping = {
      headerImage: "7DymXnaqTwGXnr88K962AC", 
    };

    const processedAssets = processAssets(assets, this.props.appLanguage, assetsMapping);

    this.setState({
      headerImage: processedAssets.headerImage || '',
    });
  }

  handleClose() {
    if (this.state.checkboxChecked) {
      localStorage.setItem('hasVisitedIntroAndTerms', 'true');
      this.setState({ showAlert: false });
      this.props.history.push('/onboarding-survey');
    }
  }

  handleCheckboxChange(e) {
    this.setState({ checkboxChecked: e.target.checked });
  }

  render() {
    const { showAlert, headerImage, checkboxChecked } = this.state;
    const { appLanguage } = this.props;

    if (!showAlert) {
      return null;
    }

    // 🔹 Define bilingual text
    const isSpanish = appLanguage === 'es-US';
    const title = isSpanish
      ? "Tu privacidad es muy importante para nosotros"
      : "Your privacy is very important to us";

    const description1 = isSpanish
      ? "La aplicación puede utilizarse de forma anónima. No recopilamos información personal en la aplicación, a menos que tu compartas información personal voluntariamente con el equipo de apoyo por el chat de Aya."
      : "The app may be used anonymously. We do not collect personal information in the app, unless you share voluntary personal information with the Aya Care Chat Support Team.";

    const description2 = isSpanish
      ? "Podríamos trabajar con empresas de análisis de terceros, como Google Analytics, para mejorar la experiencia del usuario."
      : "We may work with third-party analytic companies, including Google Analytics, to improve the user experience.";

    const checkboxText = isSpanish
      ? <>
          Acepto la{' '}
          <Link to="/onboarding-privacy-policy-page" className={styles.link}>
            Política de Privacidad
          </Link>{' '}
          y los{' '}
          <Link to="/onboarding-terms-of-use-page" className={styles.link}>
            Términos y Condiciones de Uso
          </Link>.
        </>
      : <>
          I accept the{' '}
          <Link to="/onboarding-privacy-policy-page" className={styles.link}>
            Privacy Policy
          </Link>{' '}
          and the{' '}
          <Link to="/onboarding-terms-of-use-page" className={styles.link}>
            Terms and Conditions of Use
          </Link>.
        </>;

    const buttonText = isSpanish ? "Continuar" : "Continue";

    return (
      <>
        <TopNavbarNew />
        <div className={styles.pageContainer}>
          <div className={styles.modalContainer}>
            {/* Header Image from Contentful */}
            {headerImage ? (
              <img
                src={headerImage}
                alt="Header"
                className={styles.headerImage}
              />
            ) : (
              <p>Header image not found.</p>
            )}

            {/* Bilingual Title */}
            <h1 className={styles.headerTitle}>{title}</h1>
            <div className={styles.extraSpace}></div>

            {/* Bilingual Descriptions */}
            <p className={styles.description}>{description1}</p>
            <p className={styles.description}>{description2}</p>
            <div className={styles.extraSpace}></div>

            {/* Checkbox + Label */}
            <div className={styles.checkboxRow}>
              <Form.Check
                type="checkbox"
                id="closeCheckbox"
                checked={checkboxChecked}
                onChange={this.handleCheckboxChange}
                className={styles.customCheckbox}
              />
              <label htmlFor="closeCheckbox" className={styles.checkboxLabel}>
                {checkboxText}
              </label>
            </div>
            <div className={styles.extraSpace}></div>

            {/* Continue Button */}
            <button
              onClick={this.handleClose}
              className={
                checkboxChecked ? styles.continueButton : styles.continueButtonDisabled
              }
              disabled={!checkboxChecked}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </>
    );
  }
}