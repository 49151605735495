import React from 'react';
import ReactGA from 'react-ga4';
import ContraTestAnswersWithOptions from './ContraTestAnswersWithOptions';

export class ContraTestQ7 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      contentObj: { options: [] },
      infoPopups: {},
    };
  }

  componentDidMount() {
    ReactGA.send("/contra-test-questionseven");
    window.scrollTo(0, 0);
    document.title = "Contraception Test Q7";

    const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);

    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
    const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '2EtNSZ7t5FKSgB50TwDpuX');

    let pageEntries = [];
    let pageContentObj = { options: [] };
    let infoPopupsObj = {};

    if (getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields);

      if (pageEntries[0].pageTitle) {
        pageContentObj.pageTitle = pageEntries[0].pageTitle[this.props.appLanguage];
      }
      if (pageEntries[0].stepNumber) {
        pageContentObj.stepNumber = pageEntries[0].stepNumber[this.props.appLanguage];
      }
      if (pageEntries[0].title) {
        pageContentObj.question = pageEntries[0].title[this.props.appLanguage];
      }
      if (pageEntries[0].subtitle) {
        pageContentObj.subTitle = pageEntries[0].subtitle[this.props.appLanguage];
      }
      if (pageEntries[0].choice1) {
        pageContentObj.options.push({ option: pageEntries[0].choice1[this.props.appLanguage], stateName: 'option1' });
      }
      if (pageEntries[0].choice2) {
        pageContentObj.options.push({ option: pageEntries[0].choice2[this.props.appLanguage], stateName: 'option2' });
      }
      if (pageEntries[0].choice3) {
        pageContentObj.options.push({ option: pageEntries[0].choice3[this.props.appLanguage], stateName: 'option3' });
      }
      if (pageEntries[0].ifNoneApplyCopy) {
        pageContentObj.options.push({ option: pageEntries[0].ifNoneApplyCopy[this.props.appLanguage], stateName: 'noneApply' });
      }
      if (pageEntries[0].ctaCopy1) {
        pageContentObj.btn1Text = pageEntries[0].ctaCopy1[this.props.appLanguage];
      }
    }

    const getPopUpContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'popUp');
    const getPopUp1Entries = getPopUpContentEntries.filter(obj => obj.sys.id === '2ub8bIuozmpWEGWqtfguSU');
    if (getPopUp1Entries.length > 0) {
      infoPopupsObj['option2'] = {
        title: getPopUp1Entries[0].fields.title[this.props.appLanguage],
        paragraph: getPopUp1Entries[0].fields.paragraph[this.props.appLanguage],
      };
    }

    const getPopUp2Entries = getPopUpContentEntries.filter(obj => obj.sys.id === '6kKuWGttuzUtrA6pmJ91Kl');
    if (getPopUp2Entries.length > 0) {
      infoPopupsObj['option3'] = {
        title: getPopUp2Entries[0].fields.title[this.props.appLanguage],
        paragraph: getPopUp2Entries[0].fields.paragraph[this.props.appLanguage],
      };
    }

    this.setState({
      contentObj: pageContentObj,
      infoPopups: infoPopupsObj,
    });
  }

  continue = (trueCount) => {
    window.localStorage.setItem('contraTestQ7Answer', trueCount === 0 ? 'no' : 'yes');
    this.props.history.push('/contra-test-questioneight');
  };

  render() {
    const { contentObj, infoPopups } = this.state;
    
    return (
      <div className='flexColumn flexGrow1'>
        <ContraTestAnswersWithOptions
          appLanguage={this.props.appLanguage}
          history={this.props.history}
          content={contentObj}
          infoPopups={infoPopups}
          continue={this.continue}
        />
      </div>
    );
  }
}