import React from "react";
import { useHistory, useLocation } from "react-router-dom"; 
import styles from "./TopNavbarNew.module.css";

// Importing local SVG icon
import TopNavBarArrow from "../Images/TopNavBarBackArrow.svg";

export default function TopNavbarNew({ backgroundColor, breadcrumbColor, titleColor, appLanguage }) {
  const history = useHistory();
  const location = useLocation();

  // 🔹 Breadcrumbs for each language
  const breadcrumbs = {
    "es-US": {
      "/abor-before-whatpillsdoineed": "Inicio > Aborto con pastillas > Qué pastillas necesito",
      "/abor-before-whattoexpect": "Inicio > Aborto con pastillas > Qué esperar",
      "/abor-before-howtopreparemyself": "Inicio > Aborto con pastillas > Cómo prepararme",
      "/abor-during-usemifeandmiso": "Inicio > Aborto con pastillas > Uso de mifepristona...",
      "/abor-during-usemiso": "Inicio > Aborto con pastillas > Uso de misoprostol",
      "/abor-during-warningsigns": "Inicio > Aborto con pastillas > Signos de alarma",
      "/abor-after-test": "Inicio > Aborto con pastillas > Comprueba si abortas...",
      "/abor-aftertest-positive": "Inicio > Aborto con pastillas > Comprueba si aborta...",
      "/abor-aftertest-negative": "Inicio > Aborto con pastillas > Comprueba si aborta...",
      "/abor-after-managingemotions": "Inicio > Aborto con pastillas > Gestionando las emo...",
      "/eligibility-start": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-calendar-lmp": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-tip": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-calendar-edd": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-under-12": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-over-12": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-question-one": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-question-two": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-question-three": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-positive-result": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/eligibility-negative-result": "Inicio > Aborto con pastillas > Quiz de elegibilidad",
      "/contra-short-birthcontrolpills": "Inicio > Anticonceptivos > Pastillas anticonceptivas...",
      "/contra-long-iud": "Inicio > Anticonceptivos > Dispositivo intrauterino hor...",
      "/contra-long-implant": "Inicio > Anticonceptivos > Implante subdérmico",
      "/contra-short-injection": "Inicio > Anticonceptivos > Inyección anticonceptiva",
      "/contra-emergency-emergencypill": "Inicio > Anticonceptivos > Emergency contraceptive...",
      "/contra-short-ring": "Inicio > Anticonceptivos > Anillo vaginal",
      "/contra-short-patch": "Inicio > Anticonceptivos > Parche anticonceptivo",
      "/contra-emergency-long-copperiud": "Inicio > Anticonceptivos > Dispositivo intrauterino no...",
      "/contra-barrier-condom": "Inicio > Anticonceptivos > Condones o preservativos",
      "/contra-permanent-tuballigation": "Inicio > Anticonceptivos > Ligadura de trompas",
      "/contra-permanent-vasectomy": "Inicio > Anticonceptivos > Vasectomía",
      "/contra-natural-fertility": "Inicio > Anticonceptivos > Métodos basados en el...",
      "/contra-natural-coitus": "Inicio > Anticonceptivos > Coito interrumpido",
      "/contra-barrier-diaphragm": "Inicio > Anticonceptivos > Diafragma",
      "/contra-barrier-cervicalcap": "Inicio > Anticonceptivos > Capuchón cervical",
      "/contra-test-questionone": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questiontwo": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questionthree": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questionfour": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questionfive": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questionsix": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questionseven": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questioneight": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-questionnine": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/contra-test-result": "Inicio > Anticonceptivos > Quiz de anticoncepción",
      "/faqs-library": "Inicio",
      "/resources-library": "Inicio > Recursos",
      "/resources-legalassistance": "Inicio > Recursos",
      "/resources-pillsandclinics": "Inicio > Recursos",
      "/resources-medicaladvice": "Inicio > Recursos",
      "/resources-financialassistance": "Inicio > Recursos",
      "/resources-emotionalsupport": "Inicio > Recursos",
      "/resources-immigrants": "Inicio > Recursos",
      "/contra-library": "Inicio > Anticonceptivos",
      "/abor-library": "Inicio > Aborto con pastillas",
      "/myths-and-realities": "Inicio > Mitos y verdades",
      "/real-stories-library": "Inicio > Historias reales",
      "/real-story-one": "Inicio > Historias reales",
      "/real-story-two": "Inicio > Historias reales",
      "/real-story-three": "Inicio > Historias reales",
      "/real-story-four": "Inicio > Historias reales",
    },
"en": {
    "/abor-before-whatpillsdoineed": "Home > Pills Abortion > What pills do I need",
    "/abor-before-whattoexpect": "Home > Pills Abortion > What to expect",
    "/abor-before-howtopreparemyself": "Home > Pills Abortion > How to prepare",
    "/abor-during-usemifeandmiso": "Home > Pills Abortion > Using mifepristone...",
    "/abor-during-usemiso": "Home > Pills Abortion > Using misoprostol",
    "/abor-during-warningsigns": "Home > Pills Abortion > Warning signs",
    "/abor-after-test": "Home > Pills Abortion > Confirm abortion",
    "/abor-aftertest-positive": "Home > Pills Abortion > Confirm abortion",
    "/abor-aftertest-negative": "Home > Pills Abortion > Confirm abortion",
    "/abor-after-managingemotions": "Home > Pills Abortion > Managing emotions",
    "/eligibility-start": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-calendar-lmp": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-tip": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-calendar-edd": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-under-12": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-over-12": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-question-one": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-question-two": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-question-three": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-positive-result": "Home > Pills Abortion > Eligibility Quiz",
    "/eligibility-negative-result": "Home > Pills Abortion > Eligibility Quiz",
    "/contra-short-birthcontrolpills": "Home > Contraceptives > Birth control pills",
    "/contra-long-iud": "Home > Contraceptives > Hormonal IUD",
    "/contra-long-implant": "Home > Contraceptives > Subdermal implant",
    "/contra-short-injection": "Home > Contraceptives > Birth control injection",
    "/contra-emergency-emergencypill": "Home > Contraceptives > Emergency pill",
    "/contra-short-ring": "Home > Contraceptives > Vaginal ring",
    "/contra-short-patch": "Home > Contraceptives > Contraceptive patch",
    "/contra-emergency-long-copperiud": "Home > Contraceptives > Non-hormonal IUD",
    "/contra-barrier-condom": "Home > Contraceptives > Condoms",
    "/contra-permanent-tuballigation": "Home > Contraceptives > Tubal ligation",
    "/contra-permanent-vasectomy": "Home > Contraceptives > Vasectomy",
    "/contra-natural-fertility": "Home > Contraceptives > Fertility awareness",
    "/contra-natural-coitus": "Home > Contraceptives > Withdrawal method",
    "/contra-barrier-diaphragm": "Home > Contraceptives > Diaphragm",
    "/contra-barrier-cervicalcap": "Home > Contraceptives > Cervical cap",
    "/contra-test-questionone": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questiontwo": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questionthree": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questionfour": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questionfive": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questionsix": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questionseven": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questioneight": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-questionnine": "Home > Contraceptives > Contraception Quiz",
    "/contra-test-result": "Home > Contraceptives > Contraception Quiz",
    "/faqs-library": "Home",
    "/resources-library": "Home > Resources",
    "/resources-legalassistance": "Home > Resources",
    "/resources-pillsandclinics": "Home > Resources",
    "/resources-medicaladvice": "Home > Resources",
    "/resources-financialassistance": "Home > Resources",
    "/resources-emotionalsupport": "Home > Resources",
    "/resources-immigrants": "Home > Resources",
    "/contra-library": "Home > Contraceptives",
    "/abor-library": "Home > Pills Abortion",
    "/myths-and-realities": "Home > Myths and Facts",
    "/real-stories-library": "Home > Real Stories",
    "/real-story-one": "Home > Real Stories",
    "/real-story-two": "Home > Real Stories",
    "/real-story-three": "Home > Real Stories",
    "/real-story-four": "Home > Real Stories",
    },
  };

  // 🔹 Titles for each language
  const titles = {
    "es-US": {
      "/more": "Más",
      "/more-termsofuse": "Términos de uso",
      "/more-aboutvitala": "Sobre Vitala",
      "/more-privacypolicy": "Política de privacidad",
      "/more-set-pin": "Configuración del PIN",
      "/more-set-pin-success": "Configuración del PIN",
      "/tracker-onboarding-welcome": "Seguimiento",
      "/tracker-onboarding-tips": "Seguimiento",
    },
    "en": {
      "/more": "More",
      "/more-termsofuse": "Terms of Use",
      "/more-aboutvitala": "About Vitala",
      "/more-privacypolicy": "Privacy Policy",
      "/more-set-pin": "PIN Setup",
      "/more-set-pin-success": "PIN Setup",
      "/tracker-onboarding-welcome": "Tracking",
      "/tracker-onboarding-tips": "Tracking",
    },
  };

  // 🛠 Select correct language or fallback to English
  const userLanguage = appLanguage || "en"; 
  const currentPath = location.pathname;

  const isBreadcrumbPage = breadcrumbs[userLanguage]?.hasOwnProperty(currentPath);
  const isTitlePage = titles[userLanguage]?.hasOwnProperty(currentPath);

  return (
    <div className={styles.topNavbar} style={{ backgroundColor }}>
      {/* Back Button */}
      <button className={styles.backButton} onClick={() => history.goBack()}>
        <img src={TopNavBarArrow} alt="Back" className={styles.backIcon} />
      </button>
  
      {/* Title or Breadcrumbs */}
      {isTitlePage ? (
        <h1 className={styles.navbarTitle} style={{ color: titleColor }}>
          {titles[userLanguage][currentPath]}
        </h1>
      ) : isBreadcrumbPage ? (
        <div className={styles.breadcrumbs} style={{ color: breadcrumbColor }}>
          {breadcrumbs[userLanguage][currentPath]}
        </div>
      ) : (
        <h1 className={styles.navbarTitle} style={{ color: titleColor }}>
          {" "} {/* Optional: Add a fallback title */}
        </h1>
      )}
    </div>
  );
}