import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import BannerCard from '../../Components/BannerCard';
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './LibraryAbortionMenu.module.css';
import TopNavBarNew from "../../Components/TopNavBarNew";

export default function LibraryAbortionMenu({ appLanguage }) {
  const [pageState, setPageState] = useState({
    title: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    extraText: '',
    extraText2: '',
    extraText3: '',
    extraText4: '',
    extraText5: '',
    card1Title: '',
    card1Description: '',
    card2Title: '',
    card2Description: '',
    smallCard1Title: '',
    smallCard2Title: '',
    smallCard3Title: '',
    smallCard4Title: '',
    smallCard5Title: '',
    smallCard6Title: '',
    smallCard7Title: '',
    smallCard8Title: '',
    smallCard9Title: '',
    smallCard10Title: '',
    carouselOneCards: [],
    carouselTwoCards: [],
    carouselThreeCards: [],
    headerImage: '',
    card2Image: '',
    arrowIconDark: '',
    arrowIconWhite: '',
  });
  
  let entries = null;
  let assets = null;
  
  try {
    entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    assets = JSON.parse(localStorage.getItem('ayaAppAssets'));
  } catch (err) {
    console.warn("localStorage read error:", err);
  }

  useEffect(() => {
    if (!entries || !assets) return;

    const processedEntries = processEntries(entries, appLanguage, {
      'introPage7': {
        entryId: "3oBQfcxfr3rBNhYbssPro",
        fieldsMapping: {
          title: 'title',
          title2: 'title2',
          title3: 'title3',
          title4: 'title4',
          title5: 'title5',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          extraText4: 'extraText4',
          extraText5: 'extraText5',
          card1Title: 'card1Title',
          card1Description: 'card1Description',
          card2Title: 'card2Title',
          card2Description: 'card2Description',
          smallCard1Title: 'smallCard1Title',
          smallCard2Title: 'smallCard2Title',
          smallCard3Title: 'smallCard3Title',
          smallCard4Title: 'smallCard4Title',
          smallCard5Title: 'smallCard5Title',
          smallCard6Title: 'smallCard6Title',
          smallCard7Title: 'smallCard7Title',
          smallCard8Title: 'smallCard8Title',
          smallCard9Title: 'smallCard9Title',
          smallCard10Title: 'smallCard10Title',
        }
      },
    });

    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: "01OKOkJKnkhbdVZXksKyej",
      card2Image: "6MDel9UZPwTHEOX92BCVU0",
      arrowIconDark: "35s9SZBtTGy9KGs0lc41lt",
      arrowIconWhite: "5t0SZSeDrtBlIZ3o0Apjcu",
    });

    setPageState(prev => ({
      ...prev,
      title: processedEntries['introPage7']?.title || '',
      title2: processedEntries['introPage7']?.title2 || '',
      title3: processedEntries['introPage7']?.title3 || '',
      title4: processedEntries['introPage7']?.title4 || '',
      title5: processedEntries['introPage7']?.title5 || '',
      extraText: processedEntries['introPage7']?.extraText || '',
      extraText2: processedEntries['introPage7']?.extraText2 || '',
      extraText3: processedEntries['introPage7']?.extraText3 || '',
      extraText4: processedEntries['introPage7']?.extraText4 || '',
      extraText5: processedEntries['introPage7']?.extraText5 || '',
      card1Title: processedEntries['introPage7']?.card1Title || '',
      card2Title: processedEntries['introPage7']?.card2Title || '',
      card1Description: processedEntries['introPage7']?.card1Description || '',
      card2Description: processedEntries['introPage7']?.card2Description || '',
      smallCard1Title: processedEntries['introPage7']?.smallCard1Title || '',
      smallCard2Title: processedEntries['introPage7']?.smallCard2Title || '',
      smallCard3Title: processedEntries['introPage7']?.smallCard3Title || '',
      smallCard4Title: processedEntries['introPage7']?.smallCard4Title || '',
      smallCard5Title: processedEntries['introPage7']?.smallCard5Title || '',
      smallCard6Title: processedEntries['introPage7']?.smallCard6Title || '',
      smallCard7Title: processedEntries['introPage7']?.smallCard7Title || '',
      smallCard8Title: processedEntries['introPage7']?.smallCard8Title || '',
      smallCard9Title: processedEntries['introPage7']?.smallCard9Title || '',
      smallCard10Title: processedEntries['introPage7']?.smallCard10Title || '',
      smallCardLinks: [
        "/abor-before-whatpillsdoineed",
        "/abor-before-whattoexpect",
        "/abor-before-howtopreparemyself",
        "/abor-during-usemifeandmiso",
        "/abor-during-usemiso",
        "/abor-during-warningsigns",
        "/abor-after-test",
        "/abor-after-managingemotions",
        "/contra-library",
      ],
      headerImage: processedAssets?.headerImage || '',
      card2Image: processedAssets?.card2Image || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
    
      carouselOneCards: [
        {
          title: processedEntries['introPage7']?.smallCard1Title || '',
          extraText: processedEntries['introPage7']?.extraText3 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-before-whatpillsdoineed",
        },
        {
          title: processedEntries['introPage7']?.smallCard2Title || '',
          extraText: processedEntries['introPage7']?.extraText3 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-before-whattoexpect",
        },
        {
          title: processedEntries['introPage7']?.smallCard3Title || '',
          extraText: processedEntries['introPage7']?.extraText2 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-before-howtopreparemyself",
        },
      ],
      carouselTwoCards: [
        {
          title: processedEntries['introPage7']?.smallCard4Title || '',
          extraText: processedEntries['introPage7']?.extraText4 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-during-usemifeandmiso",
        },
        {
          title: processedEntries['introPage7']?.smallCard5Title || '',
          extraText: processedEntries['introPage7']?.extraText4 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-during-usemiso",
        },
        {
          title: processedEntries['introPage7']?.smallCard6Title || '',
          extraText: processedEntries['introPage7']?.extraText3 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-during-warningsigns",
        },
      ],
      carouselThreeCards: [
        {
          title: processedEntries['introPage7']?.smallCard8Title || '',
          extraText: processedEntries['introPage7']?.extraText2 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/tracker-onboarding-welcome",
        },
        {
          title: processedEntries['introPage7']?.smallCard9Title || '',
          extraText: processedEntries['introPage7']?.extraText2 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-after-test",
        },
        {
          title: processedEntries['introPage7']?.smallCard10Title || '',
          extraText: processedEntries['introPage7']?.extraText5 || '',
          icon: processedAssets?.arrowIconDark,
          link: "/abor-after-managingemotions",
        },
      ],
    }));
  }, [entries, assets, appLanguage]);

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.libraryAbortionMenuContainer}>
        <TopNavBarNew backgroundColor="#FDE8D8" breadcrumbColor="#333" />
          {/* Header Section */}
          <div className={styles.headerContainer}>
          <HeaderCardNew
              title={pageState.title}
              image={pageState.headerImage}
              backgroundColor="#FDE8D8" 
              borderRadius="0px" 
            />
          </div>

        <div className={styles.contentWrapper}>
        <BannerCard 
            title={pageState.card1Title} 
            description={pageState.card1Description}
            image={pageState.arrowIconWhite}
            gradient="linear-gradient(102deg, #E12541 42.2%, #F85833 82.58%)"
            link="/eligibility-start"
            imageWidth="26px"
            imageHeight="26px"
            imagePosition="right"
            /* imagePlacement="top" */
            titleMaxWidth="256px"
            /* descriptionMaxWidth="286px" */
            cardWidth="350px"
            cardHeight="95px"
            /* titlePaddingTop="10px" */
            /* descriptionPaddingBottom="10px" */
            titleFontSize="14px"
            titleFontWeight="700"
            titleColor="#FFFFFF"
            titleLineHeight="15px"
            /* descriptionFontSize="13px"
            descriptionFontWeight="400" */
            descriptionColor="#FFFFFF"
          />

          {/* First Carousel Section */}
          <div className={styles.exploraContainer}>
            <div className={styles.sectionTitleExplora}>{pageState.title2}</div>
            <div className={styles.scrollInfo}>{pageState.extraText}</div>
          </div>
          <div className={styles.carouselContainer}>
                  {pageState.carouselOneCards.map((card, index) => (
                    <CarouselSmallCard
                      key={index}
                      title={card.title}
                      extraText={card.extraText}
                      icon={card.icon}
                      link={card.link}
                      spacingBetweenTitleAndExtraText="20px"
                    />
                  ))}
                </div>

                  {/* Second Carousel Section */}
                  <div className={styles.exploraContainer}>
                    <div className={styles.sectionTitleExplora}>{pageState.title3}</div>
                    <div className={styles.scrollInfo}>{pageState.extraText}</div>
                  </div>
                  <div className={styles.carouselContainer}>
                          {pageState.carouselTwoCards.map((card, index) => (
                            <CarouselSmallCard
                              key={index}
                              title={card.title}
                              extraText={card.extraText}
                              icon={card.icon}
                              link={card.link}
                              spacingBetweenTitleAndExtraText="20px"
                            />
                          ))}
                        </div>
                  {/*       <div className={styles.bannerCardWrapper}>
                        <BannerCard 
                            title={pageState.card2Title} 
                            description={pageState.card2Description}
                            image={pageState.card2Image}
                            gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
                            link="/tracker-onboarding-welcome"
                            imageWidth="120px"
                            imageHeight="113.582px"
                            imagePosition="right"
                            titleMaxWidth="300px"
                            descriptionMaxWidth="300px"
                            isWideBanner={true}

                            titleFontSize="14px"
                            titleFontWeight="700"
                            titleColor="#FFFFFF"
                            titleLineHeight="16px"
                            titleWidth="212px"
                            titleHeight="41px"

                            descriptionFontSize="12.5px"
                            descriptionFontWeight="400"
                            descriptionColor="#FFFFFF"
                            descriptionLineHeight="13px"
                            descriptionWidth="256px"
                            descriptionHeight="27px"
                          />
                          </div> */}

                        {/* Third Carousel Section */}
                        <div className={styles.exploraContainer}>
                          <div className={styles.sectionTitleExplora}>{pageState.title4}</div>
                          <div className={styles.scrollInfo}>{pageState.extraText}</div>
                        </div>
                        <div className={styles.carouselContainer}>
                                {pageState.carouselThreeCards.map((card, index) => (
                                  <CarouselSmallCard
                                    key={index}
                                    title={card.title}
                                    extraText={card.extraText}
                                    icon={card.icon}
                                    link={card.link}
                                    spacingBetweenTitleAndExtraText="20px"
                                  />
                                ))}
                              </div>
          </div>
      </div>

                        <div className={styles.bottomSpace}></div>
                        <BottomNavBarNew appLanguage={appLanguage}/>
  </div>
);
}
