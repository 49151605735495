import React from 'react';
import { Link } from "react-router-dom";
import styles from './SmallIconCard.module.css';

export default function SmallIconCard({ title, icon, link }) {
  return (
    <Link to={link} className={styles.cardLink}>
      <div className={styles.smallCardContainer}>
        <img src={icon} alt={title} className={styles.cardIcon} />
        {/* <div className={styles.titleSection}> */}
        <div className={styles.cardTitle}>{title}</div>
        {/* </div> */}
      </div>
    </Link>
  );
}