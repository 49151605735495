import React from 'react';
import * as ContentfulMarkdown from 'react-markdown';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import styles from './FlexibleCard.module.css';

export default function FlexibleCard({
  title = '',
  blueTitle = '',
  blackTitle = '',
  blackTitleBottom = false,
  description = '',
  description2 = '',
  images = [],
  showHeartIcon = false,
  showOval = false,
  ovalText = '',
  width = '100%',
  borderRadius = '8px',
  showAudioIcon = false,
  audioIcon = '',
  onAudioClick = () => {},

  // ✅ Image Positioning Prop
  imagePosition = 'below-blue-title',

  // ✅ Image Sizing Props
  imageWidth = '100%',
  imageHeight = 'auto',

  // ✅ Blue Title Customization
  blueTitleWidth = 'auto',

  // ✅ Black Title Customization
  blackTitleFontSize = '15px',
  blackTitleFontWeight = '700',
  blackTitleFontFamily = 'Inter, sans-serif',
}) {
  return (
    <div
      className={styles.cardContainer}
      style={{
        width,
        borderRadius,
      }}
    >
      {/* ✅ Audio Icon */}
      {showAudioIcon && audioIcon && (
        <div className={styles.audioIconContainer} onClick={onAudioClick}>
          <img src={audioIcon} alt="Audio Icon" className={styles.audioIcon} />
        </div>
      )}

      {/* ✅ Oval */}
      {showOval && <div className={styles.oval}>{ovalText}</div>}

      {/* ✅ Heart Icon */}
      {showHeartIcon && (
        <div className={styles.heartIcon}>
          <img src="/path-to-heart-icon.svg" alt="Heart Icon" />
        </div>
      )}

      {/* ✅ Blue Title */}
      {blueTitle && (
        <div className={styles.blueTitle} style={{ width: blueTitleWidth }}>
          {blueTitle}
        </div>
      )}

      {/* ✅ Image Below Blue Title */}
        {images.length > 0 && imagePosition === 'below-blue-title' && (
          <div className={styles.imageContainer}>
            <img
              src={images[0].url}
              alt={images[0].alt || 'Card Image'}
              className={styles.cardImage}
              style={{ width: imageWidth, height: imageHeight }}
            />
          </div>
        )}

      {/* ✅ Image BELOW Blue Title but ABOVE Black Title */}
      {images.length > 0 && imagePosition === 'below-blue-title-above-black-title' && (
        <div className={styles.imageContainer}>
          <img
            src={images[0].url}
            alt={images[0].alt || 'Card Image'}
            className={styles.cardImage}
            style={{ width: imageWidth, height: imageHeight }}
          />
        </div>
      )}

      {/* ✅ Black Title (Above Description) */}
      {blackTitle && !blackTitleBottom && (
        <div
          className={styles.blackTitle}
          style={{
            fontSize: blackTitleFontSize,
            fontWeight: blackTitleFontWeight,
            fontFamily: blackTitleFontFamily,
          }}
        >
          {blackTitle}
        </div>
      )}

      {/* ✅ Image Below Black Title (Fixing the Issue) */}
      {images.length > 0 && imagePosition === 'below-black-title' && (
        <div className={styles.imageContainer}>
          <img
            src={images[0].url}
            alt={images[0].alt || 'Card Image'}
            className={styles.cardImage}
            style={{ width: imageWidth, height: imageHeight }}
          />
        </div>
      )}

      {/* ✅ First Description */}
      {description && typeof description === 'object' ? (
        documentToReactComponents(description)
      ) : (
        <ContentfulMarkdown
          className={styles.description}
          children={description}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          allowDangerousHtml={true}
        />
      )}

      {/* ✅ Image Below First Description */}
      {images.length > 0 && imagePosition === 'below-first-description' && description && (
        <div className={styles.imageContainer}>
          <img
            src={images[0].url}
            alt={images[0].alt || 'Card Image'}
            className={styles.cardImage}
            style={{
              width: imageWidth || 'auto',
              height: imageHeight || 'auto',
              maxWidth: '100%',
            }}
          />
        </div>
      )}

      {/* ✅ Black Title Below Description */}
      {blackTitle && blackTitleBottom && (
        <div
          className={styles.blackTitleBottom}
          style={{
            fontSize: blackTitleFontSize,
            fontWeight: blackTitleFontWeight,
            fontFamily: blackTitleFontFamily,
          }}
        >
          {blackTitle}
        </div>
      )}

      {/* ✅ Second Description */}
      {description2 && typeof description2 === 'object' ? (
        documentToReactComponents(description2)
      ) : (
        <ContentfulMarkdown
          className={styles.description}
          children={description2}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          allowDangerousHtml={true}
        />
      )}

      {/* ✅ Image Below Description */}
      {images.length > 0 && imagePosition === 'below-description' && description && (
        <div className={styles.imageContainer}>
          <img
            src={images[0].url}
            alt={images[0].alt || 'Card Image'}
            className={styles.cardImage}
            style={{ width: imageWidth, height: imageHeight }}
          />
        </div>
      )}

      {/* ✅ Image at the Very Bottom */}
      {images.length > 0 && imagePosition === 'bottom' && (
        <div className={styles.imageContainer}>
          <img
            src={images[0].url}
            alt={images[0].alt || 'Card Image'}
            className={styles.cardImage}
            style={{ width: imageWidth, height: imageHeight }}
          />
        </div>
      )}
    </div>
  );
}