import React from "react";
import { Link } from "react-router-dom";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import ReactGA from "react-ga4";
import TopNavBarNew from "../../Components/TopNavBarNew";
import PopUpNew from "../../Components/PopUpNew"; // New pop-up component
import * as ContentfulMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw"; 
import styles from "./PINSetUp2.module.css";

export class PINSetUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      heading1: "",
      paragraph: "",
      btn1Text: "",
      btn2Text: "",
      paragraph2: "",
      popUpTitle: "",
      popUpDescription: "",
      popUpButton: "",
      pinInputOne: "",
      pinInputTwo: "",
      pinInputThree: "",
      pinInputFour: "",
      pinError: false,
      pinErrorMessage: "",
      pinSuccess: false,
      showPopup: false, // Controls popup visibility
      appLanguage: this.props.appLanguage || localStorage.getItem("language") || "en", // Ensure appLanguage is always available
    };

    this.pinInputOneRef = React.createRef();
    this.pinInputTwoRef = React.createRef();
    this.pinInputThreeRef = React.createRef();
    this.pinInputFourRef = React.createRef();
  }

  async componentDidMount() {
    ReactGA.send("pageview", "/more-set-pin");
    window.scrollTo(0, 0);
    document.title = "PIN Setup";

    console.log("Before setting appLanguage, localStorage value:", localStorage.getItem("language"));
    console.log("Before setting appLanguage, this.props.appLanguage:", this.props.appLanguage);

    let appLanguage = localStorage.getItem("language") || "en"; // Ignore props
    
    console.log("After setting appLanguage:", appLanguage); // 🔹 Debug here

    this.setState({ appLanguage });

    // Check Contentful data handling
    const getEntries = JSON.parse(localStorage.getItem("ayaAppEntries")) || [];
    const getPinSetupEntries = getEntries.filter(
        (obj) => obj.sys.id === "419RExV4jMzg6Rrf9ysJhO"
    );

    let pinSetupEntries = getPinSetupEntries.length > 0 ? getPinSetupEntries[0].fields : {};

    const getText = (field) => pinSetupEntries[field]?.[appLanguage] || pinSetupEntries[field]?.["en"] || "";

    this.setState({
        heading1: getText("headline"),
        paragraph: getText("paragraph"),
        btn1Text: getText("buttonText1"),
        btn2Text: getText("buttonText2"),
        paragraph2: getText("text"),
        popUpTitle: getText("popUpTitle"),
        popUpDescription: getText("popUpDescription"),
        popUpButton: getText("popUpButton"),
        appLanguage,
    });

    console.log("State appLanguage after setting:", this.state.appLanguage);
}

  // Function to handle PIN submission
  handlePinSubmit = () => {
    const { pinInputOne, pinInputTwo, pinInputThree, pinInputFour } = this.state;
    const userPin = `${pinInputOne}${pinInputTwo}${pinInputThree}${pinInputFour}`;
  
    // If userPin is invalid (e.g. "0000"), show popup
    if (userPin === "0000") {
      this.setState({ showPopup: true });
    } else {
      // ✨ Store the pin in localStorage just like the old code
      localStorage.setItem("userPin", userPin);
  
      // If you also used sessionStorage before, set that too
      sessionStorage.setItem("hasAccess", true);
  
      // Then navigate to success page
      this.props.history.push("/more-set-pin-success");
    }
  };

  // Function to clear PIN input fields when popup closes
  closePopup = () => {
    this.setState({
      showPopup: false,
      pinInputOne: "",
      pinInputTwo: "",
      pinInputThree: "",
      pinInputFour: "",
    });
  };

  // Function to handle PIN input changes & auto-move to next input
  handlePinChange = (e, nextInputRef) => {
    const { name, value } = e.target;

    if (value.length <= 1) {
      this.setState({ [name]: value }, () => {
        if (value && nextInputRef) {
          nextInputRef.current.focus(); // Move focus to next input field
        }
      });
    }
  };

  render() {
    return (
      <div className={styles.container}>
        {/* New Navbar */}
        <TopNavBarNew pageTitle="" />

        <div className={styles.extraSpace}></div>

        {/* Upper Card with Text */}
        <div className={styles.upperCard}>
          <h2 className={styles.cardTitle}>{this.state.heading1}</h2>

          {/* ✅ Contentful Markdown for `paragraph` */}
          {this.state.paragraph && (
            <ContentfulMarkdown
              className={styles.cardDescription}
              children={this.state.paragraph}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              allowDangerousHtml={true}
            />
          )}
        </div>

        <div className={styles.extraSpace}></div>

        {/* PIN Entry Card */}
        <div className={styles.pinCard}>
          <h3 className={styles.pinTitle}>
            {this.state.appLanguage === "es-US"
              ? "Por favor, elige un PIN diferente a 0000:"
              : "Please choose a PIN different from 0000:"}
          </h3>

          {/* PIN Input Fields */}
          <div className={styles.pinInputs}>
            <input
              type="password"
              className={styles.pinInput}
              maxLength="1"
              name="pinInputOne"
              value={this.state.pinInputOne}
              ref={this.pinInputOneRef}
              onChange={(e) => this.handlePinChange(e, this.pinInputTwoRef)}
            />
            <input
              type="password"
              className={styles.pinInput}
              maxLength="1"
              name="pinInputTwo"
              value={this.state.pinInputTwo}
              ref={this.pinInputTwoRef}
              onChange={(e) => this.handlePinChange(e, this.pinInputThreeRef)}
            />
            <input
              type="password"
              className={styles.pinInput}
              maxLength="1"
              name="pinInputThree"
              value={this.state.pinInputThree}
              ref={this.pinInputThreeRef}
              onChange={(e) => this.handlePinChange(e, this.pinInputFourRef)}
            />
            <input
              type="password"
              className={styles.pinInput}
              maxLength="1"
              name="pinInputFour"
              value={this.state.pinInputFour}
              ref={this.pinInputFourRef}
              onChange={(e) => this.handlePinChange(e, null)}
            />
          </div>

          {/* Error Message */}
          {this.state.pinError && <p className={styles.errorText}>{this.state.pinErrorMessage}</p>}

          {/* Blue Button (Handles PIN Submission) */}
          <button onClick={this.handlePinSubmit} className={styles.blueButton}>
            {this.state.btn1Text || (this.state.appLanguage === "es-US" ? "Establecer PIN" : "Set PIN")}
          </button>

          {/* 🔗 Lower Text Link */}
          <Link to="/home" className={styles.textLink}>
            {this.state.btn2Text || (this.state.appLanguage === "es-US" ? "Continuar explorando la app sin PIN" : "Continue exploring without a PIN")}
          </Link>
        </div>

        {/* Popup Component for Invalid PIN */}
        <PopUpNew
          show={this.state.showPopup}
          title={this.state.popUpTitle || (this.state.appLanguage === "es-US" ? "Elige un PIN diferente" : "Choose a different PIN")}
          description={this.state.popUpDescription || (this.state.appLanguage === "es-US" ? "No es posible elegir 0000 como PIN, por favor selecciona otra combinación de números." : "It is not possible to choose 0000 as a PIN, please select another combination of numbers.")}
          button1Text={this.state.popUpButton || (this.state.appLanguage === "es-US" ? "Regresar" : "Go back")}
          onButton1Click={this.closePopup}
        />

        <div className={styles.extraSpace}></div>
        <div className={styles.extraSpace}></div>

        {/* 🔽 Bottom Nav Bar with appLanguage */}
        <BottomNavBarNew appLanguage={this.state.appLanguage} />
      </div>
    );
  }
}

export default PINSetUp;