import React from 'react';
import * as ContentfulMarkdown from 'react-markdown'; 
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw'; 
import styles from './BannerCard.module.css';

export default function BannerCard({
  title,
  image,
  description,
  link,
  backgroundColor,
  gradient,
  imageWidth = '81px',
  imageHeight = '80px',
  imagePosition = 'left',  
  imageOffsetX = '0px',
  imageOffsetY = '0px',
  titleMaxWidth = '200px',
  descriptionMaxWidth = '200px',
  cardWidth = '100%', // ✅ Responsive width
  cardHeight = '88px',
  titlePaddingTop = '0px',
  titlePaddingBottom = '2px',
  descriptionPaddingTop = '0px',
  descriptionPaddingBottom = '0px',
  titleWidth = 'auto',
  titleHeight = 'auto',
  descriptionWidth = 'auto',
  descriptionHeight = 'auto',
  titleFontSize = '16px',
  titleFontWeight = 'bold',
  titleColor = '#FFFFFF',
  titleLineHeight = '1.2',
  descriptionFontSize = '14px',
  descriptionFontWeight = 'normal',
  descriptionColor = '#FFFFFF',
  descriptionLineHeight = '1.2',
  textPadding = '12px', 
}) {
  console.log("BannerCard Description:", description); // Debugging output

  return (
<div
  className={styles.bannerCardContainer} // ✅ Now CSS handles width
  style={{
    background: gradient || backgroundColor || 'linear-gradient(90deg, #FFDBD0 0%, #FEE3E0 100%)',
    height: cardHeight,
    display: 'flex',
    flexDirection: imagePosition === 'right' ? 'row-reverse' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '12px',
  }}
  onClick={() => link && (window.location.href = link)}
>
      <img
        src={image}
        alt={title}
        className={styles.bannerImage}
        style={{
          width: imageWidth,
          height: imageHeight,
          marginLeft: imagePosition === 'right' ? '20px' : '0px',
          marginRight: imagePosition === 'left' ? '20px' : '0px',
          flexShrink: 0,
        }}
      />
      <div
        className={styles.bannerTextContainer}
        style={{
          textAlign: 'left',
          padding: textPadding,
          paddingLeft: '10px',
          flex: '1',
          maxWidth: `calc(${cardWidth} - ${imageWidth} - 40px)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          className={styles.bannerTitle}
          style={{
            fontSize: titleFontSize,
            fontWeight: titleFontWeight,
            color: titleColor,
            lineHeight: titleLineHeight,
            paddingTop: titlePaddingTop,
            paddingBottom: titlePaddingBottom,
            width: titleWidth, 
            height: titleHeight, 
            overflow: 'hidden',
            whiteSpace: 'normal',
          }}
        >
          {title}
        </div>

        {/* ✅ Markdown-Enabled Description with Fixed Link Rendering */}
        <div
          className={styles.bannerText}
          style={{
            fontSize: descriptionFontSize,
            fontWeight: descriptionFontWeight,
            color: descriptionColor,
            lineHeight: descriptionLineHeight,
            maxWidth: descriptionMaxWidth,
            paddingTop: descriptionPaddingTop,
            paddingBottom: descriptionPaddingBottom,
            width: descriptionWidth, 
            height: descriptionHeight, 
            overflow: 'hidden', 
            whiteSpace: 'normal',
          }}
        >
          {description && typeof description === 'object' ? (
            documentToReactComponents(description) // ✅ Handles Rich Text
          ) : (
            <ContentfulMarkdown
              className={styles.bannerText}
              remarkPlugins={[remarkGfm]} // ✅ Enables Markdown features
              rehypePlugins={[rehypeRaw]} // ✅ Allows raw HTML
              allowDangerousHtml={true} // ✅ Allows links inside Markdown
              components={{
                a: ({ href, children }) => (
                  <a 
                    href={href} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ 
                      color: '#FFFFFF', 
                      textDecoration: 'underline', 
                      fontWeight: 'bold' 
                    }}
                  >
                    {children}
                  </a>
                ),
                p: ({ children }) => <p style={{ margin: 0 }}>{children}</p> // ✅ Ensures proper paragraph spacing
              }}
            >
              {description}
            </ContentfulMarkdown>
          )}
        </div>
      </div>
    </div>
  );
}