import React, { useState, useEffect } from 'react';
import { processEntries, processAssets } from '../../utils/contentProcessor';
import HeaderCardNew from '../../Components/HeaderCardNew';
import FlexibleCard from '../../Components/FlexibleCard';
import CarouselSmallCard from '../../Components/CarouselSmallCard';
import CallToActionCard from '../../Components/CallToActionCard';
import FeedbackCard from '../../Components/FeedbackCard';
import BannerCard from '../../Components/BannerCard';
import TopNavBarNew from "../../Components/TopNavBarNew";
import BottomNavBarNew from "../../Components/BottomNavBarNew";
import styles from './PillAbortion.module.css';

export default function PillAbortionDuringTwo({ appLanguage }) {
  const [pageState, setPageState] = useState({
    // General Content
    headerTitle: '',
    extraText: '',
    extraText2: '',
    extraText3: '',
    narrowCardTitle1: '',
    narrowCardTitle2: '',
    narrowCardTitle3: '',
    narrowCardTitle4: '',

    // Individual Large Cards
    largeCard1: {},
    largeCard2: {},
    largeCard3: {},
    largeCard4: {},
    largeCard5: {},
    largeCard6: {},
    largeCard7: {},
    largeCard8: {},
    largeCard9: {},
    largeCard10: {},
    largeCard11: {},
    largeCardTitle12: '',

    // Small Cards Section
    smallCardTitle1: '',
    smallCardDescription1: '',
    smallCardTitle2: '',
    smallCardDescription2: '',
    smallCardTitle3: '',
    smallCardDescription3: '',


    // Banner Card
    bannerCardDescription1: '',
    bannerCardTitle1: '',

    // Assets
    headerImage: '',
    arrowIconWhite: '',
    arrowIconDark: '',
    heartIcon: '',
    image1: '',
    image2:'',
    image3:'',
    audioSrc: '',
    audioTitle: 'Uso de misoprostol',
  });

  const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);

  useEffect(() => {
    // Scroll to top of the page and set the document title
    window.scrollTo(0, 0);
    document.title = 'Pill Abortion During Two';

    // Retrieve entries and assets from localStorage
    const entries = JSON.parse(localStorage.getItem('ayaAppEntries'));
    const assets = JSON.parse(localStorage.getItem('ayaAppAssets'));

    // Define entry IDs
    const abortionEntryId = '4nUPUSX6tKqLhoOqIESkUu';
    const largeCardEntryId = '5OoH8dva7UGfizynH4mSJj';

    // Process the entries from Contentful for all content types
    const processedEntries = processEntries(entries, appLanguage, {
      'abortoConPastillas': {
        entryId: abortionEntryId,
        fieldsMapping: {
          headerTitle: 'headerTitle',
          extraText: 'extraText',
          extraText2: 'extraText2',
          extraText3: 'extraText3',
          narrowCardTitle1: 'narrowCardTitle1',
          narrowCardTitle2: 'narrowCardTitle2',
          narrowCardTitle3: 'narrowCardTitle3',
          narrowCardTitle4: 'narrowCardTitle4',
          smallCardTitle1: 'smallCardTitle1',
          smallCardDescription1: 'smallCardDescription1',
          smallCardTitle2: 'smallCardTitle2',
          smallCardDescription2: 'smallCardDescription2',
          smallCardTitle3: 'smallCardTitle3',
          smallCardDescription3: 'smallCardDescription3',
          bannerCardDescription1: 'bannerCardDescription1',
          bannerCardTitle1: 'bannerCardTitle1',
          audioTitle: 'audioTitle',
        },
      },
      'largeCardCollection': {
        entryId: largeCardEntryId,
        fieldsMapping: {
          largeCardTitle1: 'largeCardTitle1',
          largeCardDescription1: 'largeCardDescription1',
          largeCardTitle2: 'largeCardTitle2',
          largeCardDescription2: 'largeCardDescription2',
          largeCardTitle3: 'largeCardTitle3',
          largeCardDescription3: 'largeCardDescription3',
          largeCardTitle4: 'largeCardTitle4',
          largeCardDescription4: 'largeCardDescription4',
          largeCardTitle5: 'largeCardTitle5',
          largeCardDescription5: 'largeCardDescription5',
          largeCardTitle6: 'largeCardTitle6',
          largeCardDescription6: 'largeCardDescription6',
          largeCardTitle7: 'largeCardTitle7',
          largeCardDescription7: 'largeCardDescription7',
          largeCardTitle8: 'largeCardTitle8',
          largeCardDescription8: 'largeCardDescription8',
          largeCardTitle9: 'largeCardTitle9',
          largeCardDescription9: 'largeCardDescription9',
          largeCardTitle10: 'largeCardTitle10',
          largeCardDescription10: 'largeCardDescription10',
          largeCardTitle11: 'largeCardTitle11',
          largeCardDescription11: 'largeCardDescription11',
          largeCardTitle12: 'largeCardTitle12',
        },
      },
    });

    // Process the assets from Contentful
    const processedAssets = processAssets(assets, appLanguage, {
      headerImage: '01OKOkJKnkhbdVZXksKyej',
      arrowIconWhite: '5t0SZSeDrtBlIZ3o0Apjcu',
      arrowIconDark: '35s9SZBtTGy9KGs0lc41lt',
      heartIcon: '740H3V1s40GxiaZiqj79MY',
      image1: '2eOBY4dpOn2ovcu6DNBzig',
      image2: '7fEfl55fvFFoRoJ86rPTbO',
      image3: 'y3Rd7KceR170l23wC9ZX9',
      audioSrc: '4ivAKcCdz9l4HgF8UkwSiM',
    });

    // Set state for the various sections of the page
    setPageState({
      // General Content for the page
      headerTitle: processedEntries['abortoConPastillas']?.headerTitle || '',
      extraText: processedEntries['abortoConPastillas']?.extraText || '',
      extraText2: processedEntries['abortoConPastillas']?.extraText2 || '',
      extraText3: processedEntries['abortoConPastillas']?.extraText3 || '',
      narrowCardTitle1: processedEntries['abortoConPastillas']?.narrowCardTitle1 || '',
      narrowCardTitle2: processedEntries['abortoConPastillas']?.narrowCardTitle2 || '',
      narrowCardTitle3: processedEntries['abortoConPastillas']?.narrowCardTitle3 || '',
      narrowCardTitle4: processedEntries['abortoConPastillas']?.narrowCardTitle4 || '',
      bannerCardDescription1: processedEntries['abortoConPastillas']?.bannerCardDescription1 || '',
      bannerCardTitle1: processedEntries['abortoConPastillas']?.bannerCardTitle1 || '',

      // Small Cards Section
      smallCardTitle1: processedEntries['abortoConPastillas']?.smallCardTitle1 || '',
      smallCardDescription1: processedEntries['abortoConPastillas']?.smallCardDescription1 || '',
      smallCardTitle2: processedEntries['abortoConPastillas']?.smallCardTitle2 || '',
      smallCardDescription2: processedEntries['abortoConPastillas']?.smallCardDescription2 || '',
      smallCardTitle3: processedEntries['abortoConPastillas']?.smallCardTitle3 || '',
      smallCardDescription3: processedEntries['abortoConPastillas']?.smallCardDescription3 || '',

      // Individual Large Cards
      largeCard1: {
        ovalText: processedEntries.largeCardCollection?.largeCardTitle12 || '',
        blueTitle: processedEntries.largeCardCollection?.largeCardTitle1 || '',
        description: processedEntries.largeCardCollection?.largeCardDescription1 || '',
        images: [{ url: processedAssets.image1 || '' }],
      },
      largeCard2: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle2 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription2 || '',
      },
      largeCard3: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle3 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription3 || '',
        description2: processedEntries['largeCardCollection']?.largeCardDescription4 || '',
        images: [
          {
            url: processedAssets['image1'] || '',
          },
        ],
      },

      largeCard4: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle4|| '',
        description: processedEntries['largeCardCollection']?.largeCardDescription5 || '',
      },

      largeCard5: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle5 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription6 || '',
      },
      largeCard6: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle6 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription7 || '',

      },
      largeCard7: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle7 || '',
        
        images: [
            {
              url: processedAssets['image2'] || '',
            },
          ],
      },
      largeCard8: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle8 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription8 || '',
      },
      largeCard9: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle9 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription9 || '',
      },
      largeCard10: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle10 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription10 || '',
      },
      largeCard11: {
        blackTitle: processedEntries['largeCardCollection']?.largeCardTitle11 || '',
        description: processedEntries['largeCardCollection']?.largeCardDescription11 || '',
      },

      // Assets
      headerImage: processedAssets?.headerImage || '',
      arrowIconWhite: processedAssets?.arrowIconWhite || '',
      arrowIconDark: processedAssets?.arrowIconDark || '',
      heartIcon: processedAssets?.heartIcon || '',
      image1: processedAssets?.image1 || '',
      image2: processedAssets?.image2 || '',
      image3: processedAssets?.image3 || '',
      audioSrc: processedAssets?.audioSrc || '',
    });
  }, [appLanguage]);

  return (
    <div className={styles.libraryAbortionMenuContainer}>
    <TopNavBarNew backgroundColor="#FDE8D8" breadcrumbColor="#333" />
      {/* Header Section */}
      <div className={styles.headerContainer2}>
        <HeaderCardNew
          title={pageState.headerTitle}
          image={pageState.headerImage}
          backgroundColor="#FDE8D8"
          borderRadius="0px"
        />
        <div className={styles.bottomSpaceHeader}></div>
     
      </div>
      <div className={styles.homePageContainer}>
        {/* Render Individual Large Cards */}
        <FlexibleCard
          blueTitle={pageState.largeCard1.blueTitle}
          blueTitleWidth="213px"
          description={pageState.largeCard1.description}
          showOval={true}
          ovalText={pageState.largeCard1.ovalText}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard2.blackTitle}
          description={pageState.largeCard2.description}
          width="351px"
          borderRadius="10px"
        />

        <CallToActionCard
          title={pageState.narrowCardTitle1}
          icon={pageState.arrowIconWhite}
          showIcon={false}
          isLink={true}
          buttonLink=""
          useCardGradient={true}
          titleColor="#fff"
          cardColor='#4350E6'
          cardGradient = 'linear-gradient(90deg, #4350E6 0%, #4350E6 100%)'
          width="357px"
          height="41px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard3.blackTitle}
          description={pageState.largeCard3.description}
          description2={pageState.largeCard3.description2}
          images={pageState.largeCard3.images}
          imageWidth="228px"
          imagePosition="below-first-description"
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard4.blackTitle}
          description={pageState.largeCard4.description}
          width="351px"
          borderRadius="10px"
        />

        <CallToActionCard
          title={pageState.narrowCardTitle2}
          icon={pageState.arrowIconWhite}
          showIcon={false}
          isLink={true}
          buttonLink="#"
          useCardGradient={true}
          titleColor="#fff"
          cardColor='#4350E6'
          cardGradient = 'linear-gradient(90deg, #4350E6 0%, #4350E6 100%)'
          width="357px"
          height="41px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard5.blackTitle}
          description={pageState.largeCard5.description}
          width="351px"
          borderRadius="10px"
        />

        <CallToActionCard
          title={pageState.narrowCardTitle3}
          icon={pageState.arrowIconWhite}
          showIcon={false}
          isLink={true}
          buttonLink="#"
          useCardGradient={true}
          titleColor="#fff"
          cardColor='#4350E6'
          cardGradient = 'linear-gradient(90deg, #4350E6 0%, #4350E6 100%)'
          width="357px"
          height="41px"
          iconPosition="right" 
          borderRadius="10px"
          // Custom Font Settings
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard6.blackTitle}
          description={pageState.largeCard6.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard7.blackTitle}
          description={pageState.largeCard7.description}
          images={pageState.largeCard7.images}
          imagePosition="below-black-title"
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard8.blackTitle}
          description={pageState.largeCard8.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard9.blackTitle}
          description={pageState.largeCard9.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard10.blackTitle}
          description={pageState.largeCard10.description}
          width="351px"
          borderRadius="10px"
        />

        <FlexibleCard
          blackTitle={pageState.largeCard11.blackTitle}
          description={pageState.largeCard11.description}
          width="351px"
          borderRadius="10px"
        />

        {/* Call To Action Card - Tracker*/}
{/*         <CallToActionCard
          title={pageState.narrowCardTitle4}
          icon={pageState.arrowIconWhite}
          showIcon={true}
          isLink={true}
          buttonLink="/tracker-onboarding-welcome"
          useCardGradient={true}
          titleColor="#fff"
          cardGradient="linear-gradient(90deg, #E12541 0%, #F85833 100%)"
          width="356px"
          height="52px"
          iconPosition="right" 
          borderRadius="10px"
          titleFont="Inter"
          titleFontSize="13px"
          titleFontWeight="400"
        /> */}
        <BannerCard 
        title={pageState.bannerCardTitle1} 
        description={pageState.bannerCardDescription1}
        image={pageState.image3}
        gradient="linear-gradient(99deg, #4450E5 22.9%, #616CEE 77.43%)"
        link="/chat-main"
        imageWidth="81px"
        imageHeight="80px"
        imagePosition="top-right"
        imageOffsetY=""
        imageOffsetX=""
        titleMaxWidth="256px"
        descriptionMaxWidth="286px"
        cardWidth="349px"
        cardHeight="88px"
        />

        {/* Extra Text Section */}
        <div className={styles.extraTitle}>{pageState.extraText}</div>

        {/* Feedback Card Section */}
        <FeedbackCard title={pageState.extraText2} appLanguage={appLanguage} />

        <div className={styles.leftAlignWrapper}>

        {/* Extra Text Section */}
        <div className={styles.extraTitletwo}>{pageState.extraText3}</div>

                {/* Render Small Cards Section */}
                {pageState.smallCardTitle1 && (
          <div className={styles.carouselContainer}>
            <CarouselSmallCard
              title={pageState.smallCardTitle1}
              extraText={pageState.smallCardDescription1}
              icon={pageState.arrowIconDark}
              link="/abor-during-usemifeandmiso"
            />
            <CarouselSmallCard
              title={pageState.smallCardTitle2}
              extraText={pageState.smallCardDescription2}
              icon={pageState.arrowIconDark}
              link="/abor-during-warningsigns"
            />
{/*             <CarouselSmallCard
              title={pageState.smallCardTitle3}
              extraText={pageState.smallCardDescription3}
              icon={pageState.arrowIconDark}
              link="/abor-after-managingemotions"
            /> */}
          </div>
        )}

      </div>
      </div>

      <div className={styles.bottomSpace}></div>
      <BottomNavBarNew appLanguage={appLanguage}/>
    </div>
  );
}
