import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";
import styles from './BottomNavBarNew.module.css';

const BottomNavBarNew = ({ appLanguage }) => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [content, setContent] = useState({
    titles: ['', '', '', ''],
    images: Array(8).fill('')
  });

  // ✅ Updated imageEntryIds to match new structure
  const imageEntryIds = useMemo(() => ({
    homeIcon: '5Qz7Ap8ZAwXWSYuwrKEqWk',
    chatIcon: '31VJnRwaLiZzIkfzybPzwR',
    selfcareIcon: '6W0LjBwEizuOg8m7ZvCmwo',
    moreIcon: '4w6vwX0J7dqqNVIUnwwnbz',
    activeHomeIcon: '3R3ctZcAFiJm9v8SEOxMwl',
    activeChatIcon: '2gBdo7rvzXUteYvan0bkJI',
    activeSelfcareIcon: '54DfXSyzn31dSWUkgkpBfq',
    activeMoreIcon: '4pbAwUVrCqlXskf1KX40Ox'
  }), []);

  // ✅ Ensure proper order: Home → Chat → Self-Care → More
  const navItems = useMemo(() => [
    { path: '/home', titleIndex: 0, imageIndex: 0, activeImageIndex: 4 },
    { path: '/chat-main', titleIndex: 1, imageIndex: 1, activeImageIndex: 5 },
    { path: '/self-care-library', titleIndex: 2, imageIndex: 2, activeImageIndex: 6 },
    { path: '/more', titleIndex: 3, imageIndex: 3, activeImageIndex: 7 }
  ], []);

  useEffect(() => {
    console.log("Current appLanguage:", appLanguage);
    const fetchContent = () => {
      const entries = JSON.parse(localStorage.getItem('ayaAppEntries')) || [];
      const assets = JSON.parse(localStorage.getItem('ayaAppAssets')) || [];

      // ✅ Get content titles from entries
      const contentEntry = entries.find(entry => entry.sys.id === '2xOqG24Uihw9SwxPZX9ilg');

      if (!contentEntry || !contentEntry.fields) {
        console.warn("No valid content entry found for BottomNavBar titles.");
      }

      const titles = appLanguage === 'es-US'
      ? ['Inicio', 'Chat', 'Autocuidado', 'Más'] // Spanish titles
      : ['Home', 'Chat', 'Self-Care', 'Settings'];   // English titles

      console.log("Fetched Titles:", titles);

      // ✅ Fetch images from assets by entry ID
      const getAssetUrl = (entryId) => {
        const asset = assets.find(a => a.sys.id === entryId);
        if (!asset || !asset.fields || !asset.fields.file) {
          console.warn(`Asset with entryId "${entryId}" not found or missing file data.`);
          return ''; // Avoid crash by returning empty string
        }

        const fileData = asset.fields.file[appLanguage] || asset.fields.file['es-US'];
        return fileData ? `https:${fileData.url}` : '';
      };

      const images = Object.keys(imageEntryIds).map(key => getAssetUrl(imageEntryIds[key]));

      setContent({ titles, images });
    };

    fetchContent();
  }, [appLanguage, imageEntryIds]);

  useEffect(() => {
    // ✅ Automatically detect active page based on the current route
    const currentIndex = navItems.findIndex(item => location.pathname.startsWith(item.path));
    if (currentIndex !== -1) {
      setActiveIndex(currentIndex);
    }
  }, [location.pathname, navItems]);

  return (
    <div className={styles.navContainer}>
      <div className={styles.topBorder}>
        {navItems.map((_, index) => (
          <div 
            key={index}
            className={`${styles.borderSegment} ${activeIndex === index ? styles.activeBorder : ''}`}
            style={{ left: `${index * 25}%`, width: '25%' }} // ✅ Adjusted width after removing tracker
          />
        ))}
      </div>
      
      <nav className={styles.navBar}>
        {navItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={styles.navItem}
            onClick={() => setActiveIndex(index)}
          >
            <div className={styles.iconContainer}>
              <img
                src={content.images[activeIndex === index ? item.activeImageIndex : item.imageIndex]}
                alt={content.titles[item.titleIndex]}
              />
            </div>
            <span className={`${styles.title} ${activeIndex === index ? styles.activeTitle : ''}`}>
              {appLanguage === "es-US"
                ? ["Inicio", "Chat", "Autocuidado", "Más"][item.titleIndex]
                : ["Home", "Chat", "Self-Care", "Settings"][item.titleIndex]}
            </span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default BottomNavBarNew;